import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import HomeLayout from "./HomeLayout";
import "font-awesome/css/font-awesome.min.css";
import i18n from "i18next";

const RouteLayout = ({ component: Component, ...rest }) => {
  const url = new URL(window.location).pathname;
  const _lang = url.split("/")[1] ;

  useEffect(() => {
    i18n.changeLanguage(_lang);
  }, [_lang]);

  return (
    <Route
      {...rest}
      render={matchProps => (
        <HomeLayout>
          <Component {...matchProps} />
        </HomeLayout>
      )}
    />
  );
};

export default RouteLayout;
