import React from "react";
import { create } from "axios";
import i18next from "i18next";
import "./const.scss";


const Logo = require("../assets/img/spinner.png");
const url = new URL(window.location).pathname;

const AxiosObj = create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { lang: url.split("/")[1] }
});

// i18next.on("languageChanged", function (lng) {
//   AxiosObj.defaults.headers["lang"] = lng;
// });

const AxiosInstance = {
  get: apiUrl => {

    const urlParams = new URLSearchParams(window.location.search);
    const preview = urlParams.get('preview') == null ? '' : '?preview=true';

    return AxiosObj.get(apiUrl + preview);
  },
  post: (apiUrl, data) => {
    return AxiosObj.post(apiUrl, data);
  }
};

export default AxiosInstance;



const AltAxiosObj = create({
  baseURL: "http://45.32.255.109:1239",
  headers: {
    lang: i18next.language

  }
});

export const AltAxiosInstance = {
  get: apiUrl => {
    // TODO: use memorize
    return AltAxiosObj.get(apiUrl);
  },
  post: (apiUrl, data, headers) => {
    return AltAxiosObj.post(apiUrl, data, headers);
  }
};

/* API assets url */

export const IMG_URL = process.env.REACT_APP_IMG_URL;

/* Spinner for page loading */
export const Loader = () => {
  return (
    <div className="loading1">
      <img src={Logo} className="rotate" alt="GLC Paints Logo" />
    </div>
  );
};
