import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AxiosInstance, { IMG_URL, Loader } from "../../../const/const";
import i18n from "i18next";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";
import "./Trends.scss";
import $ from "jquery";
import "react-tabs/style/react-tabs.css";


$(document).ready(function() {
  $(document).on("contextmenu", ".dynamic-img", function(e) {
    return false;
  });
});
class Trends extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoaded: false,
      years: [],
      videoYears: [],
      videoYearsObj:{},
      pageInfo: {},
      selectedIndex: 0,
      selectedYearIndex: 0,
      titleStyle: "title-center",
      tabStyle: "adujst-title-center"
    };
  }
  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const result = [];
    tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));

    return result;
  };

  componentDidMount() {
    this._isMounted = true;
    const thisVar = this;
    AxiosInstance.get("api/page/trends").then(function(response) {
      const overview = {
        name: response.data.title,
        excerpt: response.data.subtitle,
        index: 0
      };
      const videoYears = response.data.years;
      for (var i = 0; i < videoYears.length; i++) {
        videoYears[i].year_trends_video += "?rel=0";
      }
    const initialValue = {};
    let videoYearsObj=   videoYears.reduce((obj, item) => {
        return {
          ...obj,
          [item['year']]: item,
        };
      }, initialValue);
      
      if (thisVar._isMounted) {
        thisVar.setState({
          isLoaded: true,
          overview,
          pageInfo: overview,
          videoYears,
          videoYearsObj
        });
      }
    });

    AxiosInstance.get("api/trends").then(function(response) {
      const { data } = response;
      for (var i = 0; i < data.length; i++) {
        data[i].video_url += "?rel=0";
      }
      const yearsStr = [...new Set(data.map(item => item.trend_year))].sort();
      const years = {};
      yearsStr.map(y => {
        return (years[y] = data.filter(i => i.trend_year === y));
      });
      if (thisVar._isMounted) {
        thisVar.setState({
          years
        });
      }

      Object.keys(years).map((yearKey, index) => {
        return years[yearKey].map((tab, index) => {
          return AxiosInstance.get(`api/trends/${tab.slug}`).then(function(
            trendResponse
          ) {
            const { years } = thisVar.state;
         trendResponse.data.video_url += "?rel=0";
            years[yearKey][index] = {
              ...years[yearKey][index],
              ...trendResponse.data,
              index: index + 1
            };
            if (thisVar._isMounted) {
              thisVar.setState(
                {
                  years
                },
                () => {
                  document.getElementById("main-head").scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                  });
                }
              );
            }
          });
        });
      });

      const slug = thisVar.props.match.params.slug;

      // year or slug
      const allYears = Object.keys(years);
      let yearIndex = allYears.indexOf(slug);
      if (yearIndex > -1) {
      } else {
        let index = 0;
        Object.keys(years).map(yearKey => {
          const year = years[yearKey];
          const item = year.filter(i => i.slug === slug);
          if (item.length > 0) {
            yearIndex = allYears.indexOf(item[0].trend_year);
            index = year.indexOf(item[0]) + 1;
          }
        });
        if (thisVar._isMounted) {
          thisVar.setState({
            selectedIndex: index
          });
        }
      }
      if (thisVar._isMounted) {
        thisVar.setState({
          selectedYearIndex: yearIndex
        });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  openYear = (year, selectedYearIndex) => {
    const trendYear = year;
    let style = "";
    let tabStyle = "";
    if (trendYear === "2019" || "2020") {
      style = "title-center";
      tabStyle = "adujst-title-center";
    } else {
      style = "title-left";
      tabStyle = "adujst-title-left";
    }
    this.setState({
      pageInfo: this.state.overview,
      selectedYearIndex,
      selectedIndex: 0,
      titleStyle: style,
      tabStyle
    });
    const lang = i18n.language;
    this.props.history.push(`/${lang}/colors/trends/${year}`);
  };
  goToColor = path => {
    const lang = i18n.language;
    this.props.history.push("/" + lang + path);
  };
  openTab = tab => {
    const trendSlug = tab.name;
    let style = "";
    let tabStyle = "";
    if (trendSlug === "TRENDS" || trendSlug === "إتجاهات الألوان") {
      style = "title-center";
      tabStyle = "adujst-title-center";
    } else {
      style = "title-left";
      tabStyle = "adujst-title-left";
    }
    this.setState({
      pageInfo: tab,
      selectedIndex: tab.index,
      titleStyle: style,
      tabStyle
    });
    const lang = i18n.language;
    this.props.history.push(`/${lang}/colors/trends/${tab.slug}`);
  };

  render() {
    const {
      isLoaded,
      pageInfo,
      selectedYearIndex,
      selectedIndex,
      titleStyle,
      tabStyle
    } = this.state;
    const { t } = this.props;
    let style = {};
    let footer = {};

    if (pageInfo && pageInfo.banner) {
      style = {
        backgroundImage: "url(" + IMG_URL + pageInfo.banner + ")"
      };
      footer = {
        display: "none"
      };
    }
    if (!isLoaded) {
      return <Loader />;
    } else {
      const { years,videoYearsObj } = this.state;

      return (
        <div>
          <Helmet>
            <title>GLC Paints | Trends</title>
            <meta
              name="description"
              content="GLC paints brings you a palette of 24 colors that will shape the year 2020 and provide you with the guidelines of the trends to keep track of"
            />
          </Helmet>
          <section>
            <section id="trends">
              <div style={style} className="dynamic-img big-title">
                <div className={titleStyle}>
                  <h1>{pageInfo.name}</h1>
                  <p>{pageInfo.excerpt}</p>
                </div>
              </div>
            </section>
            <section className="pb-40">
              <div className={`${tabStyle} tabss tabs-container space`}>
                <div className="custom2-tabs">
                  <TabsComponent
                    onSelect={() => {}}
                    selectedIndex={selectedYearIndex}
                  >
                    <TabList>
                      {Object.keys(years).map((yearKey, index) => {
                        return (
                          <Tab
                            onClick={() => this.openYear(yearKey, index)}
                            key={"year-" + index}
                          >
                            {yearKey}
                          </Tab>
                        );
                      })}
                    </TabList>
                    {Object.keys(years).map((yearKey, index) => {
                      const yearTab = years[yearKey];
                      return (
                        <TabPanel key={"year-content-" + index}>
                          <div className="custom-tabs">
                            <TabsComponent
                              onSelect={() => {}}
                              selectedIndex={selectedIndex}
                            >
                              <TabList>
                                <Tab
                                  onClick={() =>
                                    this.openTab({
                                      slug: yearKey,
                                      ...this.state.overview
                                    })
                                  }
                                >
                                  {t("OVERVIEW")}
                                </Tab>

                                {yearTab.map((item, index) => {
                                  return (
                                    <Tab
                                      onClick={() => this.openTab(item)}
                                      key={index}
                                    >
                                      {item.name}
                                    </Tab>
                                  );
                                })}
                              </TabList>
                              <TabPanel>
                                <div className="">
                                  <div className="row small-space mobile-pt">
                                    {yearTab.map((item, trends) => (
                                      <div
                                        className={
                                          "col-md-" + 12 / yearTab.length
                                        }
                                        onClick={() => this.openTab(item)}
                                        key={trends}
                                      >
                                        <div title={item.name}>
                                          <label
                                            htmlFor={"tab" + trends}
                                            role="button"
                                          >
                                            <div className="dynamic-img scale-trends">
                                              <img
                                                src={
                                                  IMG_URL + item.featured_image
                                                }
                                                className="trends-bg "
                                                alt={item.slug}
                                              />
                                              <span className="insp-overlay-bg"></span>
                                              <div className="scale-content full-width">
                                                <h2>{item.name}</h2>
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                    <div className="frame-yt row">
                                      <div
                                        className={
                                          "scale-amb-item col-md-" +
                                          (12 - (12 / yearTab.length) * 2)
                                        }
                                      >
                                        <div
                                          className="scale-content content"
                                          style={{ padding: "0" }}
                                        >
                                          <p>
                                            {videoYearsObj[yearKey].year_trends_text}
                                          </p>
                                          <p>
                                            {this.state.pageInfo.footer_text}
                                          </p>
                                        </div>
                                      </div>

                                      <div
                                        className={
                                          "scale-amb-item col-md-" +
                                          (12 / yearTab.length) * 2
                                        }
                                      >
                                        <div className="frame-embed video-container-full">
                                          <iframe
                                            src={videoYearsObj[yearKey].year_trends_video.includes("https://www.youtube.com/watch?v=")?
                                            videoYearsObj[yearKey].year_trends_video.replace(
                                              "https://www.youtube.com/watch?v=",
                                              "https://www.youtube.com/embed/",
                                            ): videoYearsObj[yearKey].year_trends_video.replace(
                                              "https://youtu.be/",
                                              "https://www.youtube.com/embed/",
                                            )}
                    
                                            height="400px"
                                            frameBorder="0"
                                            allow="autoplay; encrypted-media"
                                            allowFullScreen
                                            title="video"
                                          />
                                        </div>
                                      </div>
                                    </div> 
                                </div>
                                <div className="row">
                                  <div className="trends-content">
                                    <Row className="full-width">
                                      <Col
                                        md={{ size: 6, offset: 3 }}
                                        style={footer}
                                      ></Col>
                                    </Row>
                                  </div>
                                </div>
                              </TabPanel>
                              {yearTab.map((item, sec) => (
                                <TabPanel key={sec}>
                                  <div className="color-trend-content">
                                    <div className="boxx">
                                      <div className="content">
                                        <div className="scale-content1">
                                          <p>
                                            {this.extractParagraphTexts(
                                              item.text1
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="dynamic-img scale-amb-item-img">
                                        <img
                                          src={IMG_URL + item.imag1}
                                          className="bg blog-thumb"
                                          alt={item.slug}
                                        />
                                        <span className="overlay-amb-item-bg"></span>
                                      </div>
                                    </div>
                                    <div className="boxx">
                                      <div className="boxxxx scale-amb-item-img">
                                        <div>
                                          <img
                                            src={IMG_URL + item.image2}
                                            className="dynamic-img bg amb-four"
                                            alt={item.slug}
                                          />
                                        </div>
                                        <div>
                                          <img
                                            src={IMG_URL + item.image3}
                                            className="dynamic-img bg amb-four"
                                            alt={item.slug}
                                          />
                                        </div>
                                        <div>
                                          <img
                                            src={IMG_URL + item.image4}
                                            className="dynamic-img bg amb-four"
                                            alt={item.slug}
                                          />
                                        </div>
                                        <div>
                                          <img
                                            src={IMG_URL + item.image5}
                                            className="dynamic-img bg amb-four"
                                            alt={item.slug}
                                          />
                                        </div>
                                      </div>
                                      <div className="scale-amb-item">
                                        <div className="content">
                                          <div className="scale-content2">
                                            <p>
                                              {this.extractParagraphTexts(
                                                item.text2
                                              )}
                                            </p>
                                            {/* Api connection and map func. */}
                                            <Row className="color-cells pt-pb-100 full-width">
                                              {(() => {
                                                if (item.colors)
                                                  return item.colors.map(
                                                    (color, i) => {
                                                      return (
                                                        <Col
                                                          key={i}
                                                          sm="4"
                                                          onClick={e =>
                                                            this.goToColor(
                                                              `/colors/${color.family.slug}/${color.color_code}`
                                                            )
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              color.hex_code
                                                          }}
                                                          className="color-cell"
                                                        >
                                                          <div
                                                            className="trend-color-name"
                                                            style={{
                                                              color:
                                                                color.hex_code
                                                            }}
                                                          >
                                                            {color.name}
                                                          </div>
                                                          <div
                                                            className="trend-color-code"
                                                            style={{
                                                              color:
                                                                color.hex_code
                                                            }}
                                                          >
                                                            {color.color_code}
                                                          </div>
                                                        </Col>
                                                      );
                                                    }
                                                  );
                                              })()}
                                            </Row>
                                            <div
                                              className={
                                                "Brochure " +
                                                (item.pdf_file &&
                                                item.pdf_file.includes(".pdf")
                                                  ? ""
                                                  : "hide1") +
                                                "brochure-mob-off"
                                              }
                                            >
                                              <h6>
                                                {item.name} - {t("Brochure")}
                                              </h6>
                                              {item.brochure_view_url !=
                                              null ? (
                                                <a
                                                  className="pdfdownload"
                                                  href={item.brochure_view_url}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <img
                                                    src={require("../../../assets/img/homepage/icons/pdf-view.svg")}
                                                    alt={t("View PDF Brochure")}
                                                  />
                                                  {t("VIEW")}
                                                </a>
                                              ) : (
                                                ""
                                              )}
                                              {item.pdf_file != null ? (
                                                <a
                                                  className="pdfdownload"
                                                  href={IMG_URL + item.pdf_file}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  download
                                                >
                                                  <img
                                                    src={require("../../../assets/img/homepage/icons/pdf-download.svg")}
                                                    alt={t(
                                                      "Download PDF Brochure"
                                                    )}
                                                  />
                                                  {t("DOWNLOAD")}
                                                </a>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="boxx frame-yt">
                                      <div className="scale-amb-item">
                                        <div className="scale-content3">
                                          <p>{item.text3}</p>
                                          <p>
                                            {this.extractParagraphTexts(
                                              item.description
                                            )}
                                          </p>
                                          <div
                                            className={
                                              "Brochure " +
                                              (item.pdf_file &&
                                              item.pdf_file.includes(".pdf")
                                                ? ""
                                                : "hide1") +
                                              "brochure-mob-on"
                                            }
                                          >
                                            <h6>
                                              {item.name} - {t("Brochure")}
                                            </h6>
                                            {item.brochure_view_url != null ? (
                                              <a
                                                className="pdfdownload"
                                                href={item.brochure_view_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <img
                                                  src={require("../../../assets/img/homepage/icons/pdf-view.svg")}
                                                  alt={t("View PDF Brochure")}
                                                />
                                                {t("VIEW")}
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                            {item.pdf_file != null ? (
                                              <a
                                                className="pdfdownload"
                                                href={IMG_URL + item.pdf_file}
                                                target="_blank"
                                                download
                                                rel="noopener noreferrer"
                                              >
                                                <img
                                                  src={require("../../../assets/img/homepage/icons/pdf-download.svg")}
                                                  alt={t(
                                                    "Download PDF Brochure"
                                                  )}
                                                />
                                                {t("DOWNLOAD")}
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="scale-amb-item">
                                        <div className="frame-embed video-container-full">
                                          <iframe
                                            src={item.video_url.replace(
                                              "https://www.youtube.com/watch?v=",
                                              "https://www.youtube.com/embed/"
                                            )}
                                            height="400px"
                                            frameBorder="0"
                                            allow="autoplay; encrypted-media"
                                            allowFullScreen
                                            title="video"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="trends-content">
                                      <Row className="full-width">
                                        <Col></Col>
                                      </Row>
                                    </div>
                                  </div>
                                </TabPanel>
                              ))}
                            </TabsComponent>
                          </div>
                        </TabPanel>
                      );
                    })}
                  </TabsComponent>
                </div>
              </div>
            </section>
          </section>
        </div>
      );
    }
  }
}

export default withTranslation("common")(Trends);
