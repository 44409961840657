import React from "react";
import i18n from "i18next";
import Link from "../link/link";
import { withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import "./Footer.scss";


const Footer = (props) => {

  const { t } = props;

  return (
    <footer className="footer">
      <div className="footer-position">
        <Row>
          <Col className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 stay-in-touch">
            <h5 className="headin5_amrc col_white_amrc pt2 ">
              {t("STAY IN TOUCH")}
            </h5>{" "}
            <ul className="footer_ul_amrc ">
              <li>
                <i className="icon-phone"></i>
                <a href="tel:00202-16730" className="pleft-1">
                  (00202) 16730
                </a>
              </li>
              <li>
                <i className="icon-phone"></i>
                <a href="tel:00202-46101456" className="pleft-1">
                  (00202) 46101456
                </a>
              </li>
              <li>
                <i className="icon-envelope"></i>
                <a href="mailto:info@glcpaints.com" className="pleft-1">
                  info@glcpaints.com
                </a>
              </li>
              <li className="address">
                <i className="icon-map"></i>
                <p className="pleft-2">
                  {t("54 St, Block 13027")} <br /> {t("First Industrial Zone")}{" "}
                  <br /> {t("El Obour - Egypt")}
                </p>
              </li>
            </ul>
          </Col>

          <Col className="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <h5 className="headin5_amrc col_white_amrc pt2">{t("LEGAL")}</h5>
            <ul className="footer_ul_amrc">
              {/*   <li>
                <Link to="/privacy-policy"> {t("Privacy Policy")}</Link>
              </li> */}
              <li>
                <Link to="/terms-of-use">{t("Terms Of Use")}</Link>
              </li>
            </ul>
          </Col>

          <Col className="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <h5 className="headin5_amrc col_white_amrc pt2">{t("HOME")}</h5>
            <ul className="footer_ul_amrc pages-list">
              <li>
                <Link to="/ambiance">{t("Decorative Finishes")}</Link>
              </li>
              <li>
                <Link to="/colors">{t("Colors")}</Link>
              </li>
              <li>
                <Link to="/inspiration">{t("Inspiration")}</Link>
              </li>
              <li>
                <Link to="/products/list">{t("Products")}</Link>
              </li>
              <li>
                <Link to="/stores">{t("Stores")}</Link>
              </li>
              <li>
                <Link to="/news">{t("News")}</Link>
              </li>
              <li>
                <Link to="/projects/list">{t("Projects")}</Link>
              </li>
              <li>
                <Link to="/products/paint-calculator">{t("Paint Calculator")}</Link>
              </li>
            </ul>
          </Col>

          <Col className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
            <h5 className="headin5_amrc col_white_amrc pt2">{t("ABOUT US")}</h5>
            <ul className="footer_ul_amrc pages-list">
              {i18n.language === "ar" ? (
                <div>
                  <li>
                    <HashLink smooth to="/ar/about-us/#history">
                      {" "}
                      {t("Our History")}{" "}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/ar/about-us/#values">
                      {" "}
                      {t("Our Values")}{" "}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/ar/about-us/#mission">
                      {" "}
                      {t("Our Mission")}{" "}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/ar/about-us/#careers">
                      {" "}
                      {t("Careers")}{" "}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/ar/about-us/#become-distributor">
                      {" "}
                      {t("Become a Distributor")}{" "}
                    </HashLink>
                  </li>
                </div>
              ) : (
                <div>
                  <li>
                    <HashLink smooth to="/en/about-us/#history">
                      {" "}
                      {t("Our History")}{" "}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/en/about-us/#values">
                      {" "}
                      {t("Our Values")}{" "}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/en/about-us/#mission">
                      {" "}
                      {t("Our Mission")}{" "}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/en/about-us/#careers">
                      {" "}
                      {t("Careers")}{" "}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/en/about-us/#become-distributor">
                      {" "}
                      {t("Become a Distributor")}{" "}
                    </HashLink>
                  </li>
                </div>
              )}
            </ul>
          </Col>

          <Col className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <h5 className="headin5_amrc col_white_amrc pt2 ">
              {t("FOLLOW US ON SOCIAL MEDIA")}
            </h5>
            <ul className="footer_ul2_amrc">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/GLCpaints/"
                >
                  <i className="fa fa-facebook-square fleft padding-right"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/glc.paints/?hl=en"
                >
                  <i className="fa fa-instagram fleft padding-right"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/german-lebanese-company-for-paints-glc/"
                >
                  <i className="fa fa-linkedin fleft padding-right"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCopjw5jTvg4U2qBFRiyeumA"
                >
                  <i className="fa fa-youtube-square fleft padding-right"></i>
                </a>
              </li><li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://pin.it/3txcMnt"
                >
                  <i className="fa fa-pinterest-square fleft padding-right"></i>
                </a>
              </li><li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.tiktok.com/@glc.paints"
                >
                  <i className="tiktok fleft padding-right">
                    <svg  xmlns="http://www.w3.org/2000/svg" width="18" height="21"
                         fill="currentColor" className="bi bi-tiktok" viewBox="0 0 16 16">
                      <path
                          d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"
                          fill="white"></path>
                    </svg>
                  </i>
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: 4, offset: 8 }}>
            <p className="copyright">
              &copy; {new Date().getFullYear()} الألمانية اللبنانية للصناعة  - German Lebanese For Industry
            </p>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default withRouter(withTranslation("common")(Footer));
