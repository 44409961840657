import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import AxiosInstance, { IMG_URL } from "../../const/const";
import Link from "../../components/common/link/link";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import "./Search.scss";
import { Helmet } from "react-helmet";

const apiKey = "1";

class Search extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const { query } = queryString.parse(props.location.search);
    this.state = {
      searchQuery: query || "",
      searchResult: [],
      selectedCategory: { title: "", data: [] },
      isLoaded: false,
      isUpdated: false
    };
  }

  componentDidUpdate(prevProps) {
    const thisVar = this;
    thisVar._isMounted = true;
    const oldQuery = queryString.parse(prevProps.location.search).query;
    const newQuery = queryString.parse(this.props.location.search).query;
    if (oldQuery !== newQuery) {
      this.setState({ searchQuery: newQuery });
      this.search(null, false, newQuery);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    const that = this;
    this.search();
    that.setState({ isUpdated: true });
  }

  openColor = path => {
    const lang = i18n.language;
    this.props.history.push("/" + lang + path);
  };

  searchTextChanged = e => {
    const keyword = e.target.value;
    this.setState({ searchQuery: keyword });
  };
  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const result = [];
    tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));

    return result;
  };
  search = (e, updateURL, query) => {
    if (e) e.preventDefault();
    let searchQuery = null;
    if (query) {
      searchQuery = query;
    } else {
      searchQuery = this.state.searchQuery;
    }

    if (!searchQuery) return;
    if (updateURL) {
      const lang = i18n.language;
      this.props.history.push("/" + lang + "/search?query=" + searchQuery);
    }
    const that = this;
    AxiosInstance.get("api/search?keyword=" + searchQuery).then(function(
      response
    ) {
      const category = Object.keys(response.data).filter(
        key => response.data[key].length > 0
      );
      let selectedCategory = that.state.selectedCategory;
      if (category.length > 0)
        selectedCategory = {
          title: category[0],
          data: response.data[category[0]]
        };
      else selectedCategory = { title: "", data: [] };
      that.setState({
        searchResult: response.data,
        isLoaded: true,
        selectedCategory
      });
    });
  };

  selectCategory = e => {
    const name = e.target.attributes.name.value;
    this.setState({
      selectedCategory: { title: name, data: this.state.searchResult[name] }
    });
  };

  openDirection = store => {
    window.open(
      "https://www.google.com/maps/dir/?api=" +
        apiKey +
        "&destination=" +
        store.lat +
        "," +
        store.lng,
      "_blank"
    );
  };

  // finish / color / done
  renderAmbiencesResult = result => {
    const { t } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <div className="image">
              <img
                src={IMG_URL + result.image1}
                alt={`Ambience ${result.slug}`}
              />
            </div>
          </div>
          <div className="col-md-9">
            <div className="result-title">
              <Link to={"/ambiance/" + result.slug}>{t(result.title)}</Link>
            </div>
            {this.extractParagraphTexts(result.excerpt)}
          </div>
        </div>
      </div>
    );
  };

  // y / done
  renderColorsResult = result => {
    const { t } = this.props;
    return (
      <div className="color-result">
        <div className="result-title">{t(result.title)}</div>
        <div
          className="row clickable"
          onClick={e =>
            this.openColor(
              "/colors/" + result.family.slug + "/" + result.color_code
            )
          }
        >
          <div className="col-4 col-md-3">
            <div className="image">
              <img
                src={IMG_URL + result.brush_image}
                alt={`${result.family_slug}  ${result.name}`}
              />
            </div>
          </div>
          <div className="col-8 col-md-9">
            <div className="color-name">{result.name}</div>
            <div className="color-code">{result.color_code}</div>
          </div>
        </div>
      </div>
    );
  };

  //took / color / done
  renderInspirationsResult = result => {
    const { t } = this.props;
    return (
      <div className="inspiration-result">
        <div className="row clickable">
          <div className="col-md-3">
            <div className="image">
              <img
                src={IMG_URL + result.image1_option1}
                alt={`${result.section} ${result.subsection}`}
              />
            </div>
          </div>
          <div className="col-md-9">
            <div className="result-title">
              <Link to={"/inspiration/" + result.section.toLowerCase()}>
                {t(result.section)} - {t(result.subsection)}
              </Link>
            </div>
            {this.extractParagraphTexts(result.description)}
          </div>
        </div>
      </div>
    );
  };

  // paint / done
  renderProductsResult = result => {
    const { t } = this.props;
    return (
      <div className="products-result">
        <div className="row clickable">
          <div className="col-md-3">
            <div className="image">
              <img
                src={IMG_URL + result.image}
                alt={`${result.family_slug} ${result.slug}`}
              />
            </div>
          </div>

          <div className="col-md-9">
            <div className="result-title">
              <Link
                to={
                  "/products/" +
                  "detail/" +
                  result.slug
                }
              >
                {" "}
                {result.title}{" "}
              </Link>
            </div>
            {this.extractParagraphTexts(result.summary)}
            <div className="color-name">{result.summary}</div>
            <Row className="products-icons">
              <Col className={result.brush ? "" : "hide-image"}>
                {" "}
                <span>
                  <img
                    src={require("../../assets/img/homepage/icons/brush.svg")}
                    alt={t("Brush")}
                  />
                </span>
                <br />
                {t("Brush")}{" "}
              </Col>
              <Col className={result.roller ? "" : "hide-image"}>
                <span>
                  <img
                    src={require("../../assets/img/homepage/icons/roller.svg")}
                    alt={t("Roller")}
                  />
                </span>{" "}
                <br />
                {t("Roller")}
              </Col>
              <Col className={result.spray_gun ? "" : "hide-image"}>
                {" "}
                <span>
                  <img
                    src={require("../../assets/img/homepage/icons/spray_gun.svg")}
                    alt={t("Spraying Gun")}
                  />
                </span>{" "}
                <br />
                {t("Spraying Gun")}{" "}
              </Col>
              <Col className={result.airless_spray ? "" : "hide-image"}>
                {" "}
                <span>
                  <img
                    src={require("../../assets/img/homepage/icons/airless_spray.svg")}
                    alt={t("Airless Spray")}
                  />
                </span>
                <br />
                {t("Airless Spray")}{" "}
              </Col>
              <Col className={result.putty_knife ? "" : "hide-image"}>
                {" "}
                <span>
                  <img
                    src={require("../../assets/img/homepage/icons/putty_knife.svg")}
                    alt={t("Putty Knife")}
                  />
                </span>
                <br />
                {t("Putty Knife")}{" "}
              </Col>
              <Col className={result.trowel ? "" : "hide-image"}>
                {" "}
                <span>
                  <img
                    src={require("../../assets/img/homepage/icons/trowel.svg")}
                    alt={t("Trowel")}
                  />
                </span>
                <br />
                {t("Trowel")}{" "}
              </Col>
              <Col className={result.plastic_trowel ? "" : "hide-image"}>
                {" "}
                <span>
                  <img
                    src={require("../../assets/img/homepage/icons/plastic_trowel_1.svg")}
                    alt={t("Plastic Trowel")}
                  />
                </span>
                <br />
                {t("Plastic Trowel")}{" "}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  // Lara Sweet / done
  renderStoresResult = result => {
    const { t } = this.props;
    return (
      <div className="store-result">
        <div className="store-title">{t(result.name)}</div>
        <div className="store-details">
          <Row>
            <Col md={6}>
              <div className="phone">
                <i className="fa fa-phone"></i> {result.phone}
              </div>
              <div className="email">
                <i className="fa fa-envelope"></i> {result.email}
              </div>
            </Col>
            <Col md={6}>
              <div className="address">
                <i className="fa fa-map-marker"></i> {result.address}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="direction-button-container">
                <button onClick={() => this.openDirection(result)}>
                  {t("GET DIRECTION")}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  // boraq / done
  renderTrendsResult = result => {
    const { t } = this.props;
    return (
      <div>
        <div className="row clickable">
          <div className="col-md-3">
            <div className="image">
              <img src={IMG_URL + result.imag1} alt={`Trends ${result.slug}`} />
            </div>
          </div>
          <div className="col-md-9">
            <div className="result-title">
              <Link to={"/colors/trends/" + result.slug}>{t(result.name)}</Link>
            </div>
            {this.extractParagraphTexts(result.text1)}
          </div>
        </div>
      </div>
    );
  };

  // red / color
  renderNewsResult = result => {
    const { t } = this.props;
    return (
      <div className="news-result">
        <div className="row ">
          <div className="col-md-3">
            <div className="image">
              <img src={IMG_URL + result.image} alt={result.slug} />
            </div>
          </div>
          <div className="col-md-9">
            <div className="result-title">{t(result.title)}</div>
            {this.extractParagraphTexts(result.excerpt)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const {
      searchQuery,
      searchResult,
      isLoaded,
      selectedCategory
    } = this.state;

    return (
      <div className="products-intro">
        <Helmet>
          <title>GLC Paints | Search</title>
          <meta
            name="description"
            content="Search for colors, stores,products,trends of GLC paints"
          />
        </Helmet>
        <div className="search anti-big-title">
          <section className="main-bg">
            <div className="big-title">
              <div className="title-center">
                <h1>{t("Search")}</h1>
                <p>{t("Let us help you")}</p>
              </div>
            </div>
          </section>

          <section className="main-bg space">
            <div className="blog-tabs">
              <div className="content">
                <div className="blog-one pb-60">
                  <Container fluid>
                    <Row>
                      <Col md={12}>
                        <div className="search-content">
                          <form onSubmit={e => this.search(e, true)}>
                            <div className="search-input">
                              <i className="fa fa-search padding-right"></i>
                              <input
                                value={searchQuery}
                                onChange={this.searchTextChanged}
                                placeholder={t("Type here")}
                                type="text"
                              />
                            </div>
                          </form>
                        </div>
                      </Col>
                    </Row>

                    {(() => {
                      if (isLoaded) {
                        return (
                          <Row>
                            <Col xs={12} md={4}>
                              <div className="category-container">
                                <div className="row">
                                  <div className="col-md-12 cat-title">
                                    {t("Results By Category")}
                                  </div>
                                </div>
                                <div
                                  name="ambiences"
                                  className={
                                    "row cat-container " +
                                    (selectedCategory.title === "ambiences"
                                      ? "cat-selected"
                                      : "")
                                  }
                                  onClick={this.selectCategory}
                                >
                                  <div
                                    name="ambiences"
                                    className="col-10 col-md-10 cat-name"
                                  >
                                    {t("Ambiences")}
                                  </div>
                                  <div
                                    name="ambiences"
                                    className="col-2 col-md-2 result-count"
                                  >
                                    ({searchResult.ambiences.length})
                                  </div>
                                </div>
                                <div
                                  name="colors"
                                  className={
                                    "row cat-container " +
                                    (selectedCategory.title === "colors"
                                      ? "cat-selected"
                                      : "")
                                  }
                                  onClick={this.selectCategory}
                                >
                                  <div
                                    name="colors"
                                    className="col-10 col-md-10 cat-name"
                                  >
                                    {t("Colors")}
                                  </div>
                                  <div
                                    name="colors"
                                    className="col-2 col-md-2 result-count"
                                  >
                                    ({searchResult.colors.length})
                                  </div>
                                </div>
                                <div
                                  name="inspirations"
                                  className={
                                    "row cat-container " +
                                    (selectedCategory.title === "inspirations"
                                      ? "cat-selected"
                                      : "")
                                  }
                                  onClick={this.selectCategory}
                                >
                                  <div
                                    name="inspirations"
                                    className="col-10 col-md-10 cat-name"
                                  >
                                    {t("Inspiration")}
                                  </div>
                                  <div
                                    name="inspirations"
                                    className="col-2 col-md-2 result-count"
                                  >
                                    ({searchResult.inspirations.length})
                                  </div>
                                </div>
                                <div
                                  name="news"
                                  className={
                                    "row cat-container " +
                                    (selectedCategory.title === "news"
                                      ? "cat-selected"
                                      : "")
                                  }
                                  onClick={this.selectCategory}
                                >
                                  <div
                                    name="news"
                                    className="col-10 col-md-10 cat-name"
                                  >
                                    {t("News")}
                                  </div>
                                  <div
                                    name="news"
                                    className="col-2 col-md-2 result-count"
                                  >
                                    ({searchResult.news.length})
                                  </div>
                                </div>
                                <div
                                  name="products"
                                  className={
                                    "row cat-container " +
                                    (selectedCategory.title === "products"
                                      ? "cat-selected"
                                      : "")
                                  }
                                  onClick={this.selectCategory}
                                >
                                  <div
                                    name="products"
                                    className="col-10 col-md-10 cat-name"
                                  >
                                    {t("Products")}
                                  </div>
                                  <div
                                    name="products"
                                    className="col-2 col-md-2 result-count"
                                  >
                                    ({searchResult.products.length})
                                  </div>
                                </div>
                                <div
                                  name="stores"
                                  className={
                                    "row cat-container " +
                                    (selectedCategory.title === "stores"
                                      ? "cat-selected"
                                      : "")
                                  }
                                  onClick={this.selectCategory}
                                >
                                  <div
                                    name="stores"
                                    className="col-10 col-md-10 cat-name"
                                  >
                                    {t("Stores")}
                                  </div>
                                  <div
                                    name="stores"
                                    className="col-2 col-md-2 result-count"
                                  >
                                    ({searchResult.stores.length})
                                  </div>
                                </div>
                                <div
                                  name="trends"
                                  className={
                                    "row cat-container " +
                                    (selectedCategory.title === "trends"
                                      ? "cat-selected"
                                      : "")
                                  }
                                  onClick={this.selectCategory}
                                >
                                  <div
                                    name="trends"
                                    className="col-10 col-md-10 cat-name"
                                  >
                                    {t("Trends")}
                                  </div>
                                  <div
                                    name="trends"
                                    className="col-2 col-md-2 result-count"
                                  >
                                    ({searchResult.trends.length})
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} md={8}>
                              <div className="result-container">
                                <div className="result-category-title">
                                  {t(selectedCategory.title)}
                                  <hr />
                                </div>
                                {selectedCategory.data.map((result, index) => {
                                  let renderer = () => {};
                                  switch (selectedCategory.title) {
                                    case "ambiences":
                                      renderer = this.renderAmbiencesResult;
                                      break;
                                    case "colors":
                                      renderer = this.renderColorsResult;
                                      break;
                                    case "inspirations":
                                      renderer = this.renderInspirationsResult;
                                      break;
                                    case "news":
                                      renderer = this.renderNewsResult;
                                      break;
                                    case "products":
                                      renderer = this.renderProductsResult;
                                      break;
                                    case "stores":
                                      renderer = this.renderStoresResult;
                                      break;
                                    case "trends":
                                      renderer = this.renderTrendsResult;
                                      break;
                                    default:
                                    // do nothing
                                  }
                                  return (
                                    <div key={index} className="result">
                                      {renderer(result)}
                                    </div>
                                  );
                                })}
                              </div>
                            </Col>
                          </Row>
                        );
                      }
                    })()}
                  </Container>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation("common")(Search));
