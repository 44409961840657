import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import { withTranslation } from "react-i18next";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import "./product-detail.scss";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import Slider from "react-slick";
import Link from "../../components/common/link/link";


class ProductDetail extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleBrochureModal = this.toggleBrochureModal.bind(this);
        this.state = {
            isLoaded: false,
            productId: this.props.match.params.productId,
            pageInfo: {},
            productDetails: {},
            openedCalculator: false,
            selectedImageSrc: "",
            imageModal: false,
            brochureModal: false,
            videoURL: "",
            calculatorData: {
                prodcuts: [],
                selectedProduct: {
                    spreading_rate_unit: "M2/KG"
                },
                width: "",
                height: "",
                numberOfWalls: "",
                visited: {},
                paintsNeeded: 0,
            },
        };
        if (document.getElementById("main-head"))
            document.getElementById("main-head").scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });

    }

    componentDidMount() {
        const { calculatorData, productId } = this.state;

        this._isMounted = true;
        const that = this;

        AxiosInstance.get("api/products/flat").then(function (response) {
            calculatorData.selectedProduct = response.data.find(product => product.slug === productId);
            calculatorData.prodcuts = response.data;
            that.setState({ calculatorData });
        });

        // AxiosInstance.get("api/products/exterior").then(function (response) {
        //     calculatorData.prodcuts["EXTERIOR"] = response.data;
        //     that.setState({ calculatorData });
        // });

        // AxiosInstance.get("api/page/project/" + this.state.projectId)
        //     .then((response) => {
        //         document.getElementById("main-head").scrollIntoView({
        //             behavior: "smooth",
        //             block: "end",
        //             inline: "nearest"
        //         });

        //         if (that._isMounted) {
        //             that.setState({ pageInfo: response.data });
        //         }
        //     });

        AxiosInstance.get(`api/products/flat/${this.state.productId}`).then(function (response) {
            if (that._isMounted) {
                that.setState({
                    isLoaded: true,
                    productDetails: response.data,
                    selectedImageSrc: IMG_URL + response.data.image,
                    embedVideo: response.data.embed_video,
                    embedBrochure: response.data.brochure_view_url
                });
            }
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getPaintCalculator() {
        const { openedCalculator, calculatorData } = this.state;
        const { prodcuts, width, height, selectedProduct, visited, numberOfWalls, paintsNeeded } = calculatorData;
        const { t } = this.props;
        const lang = this.props.i18n.language;

        if (!openedCalculator) {
            return null;
        }

        const handleChange = e => {
            const { calculatorData } = this.state;
            calculatorData[e.target.name] = e.target.value ? parseInt(e.target.value) : ""
            this.setState({ calculatorData });
        };

        const handleBlur = e => {
            const { calculatorData } = this.state;
            calculatorData.visited[e.target.name] = true;
            this.setState({ calculatorData });
        };

        const clearForm = () => {
            let { calculatorData } = this.state;
            calculatorData = {
                ...calculatorData,
                paintsNeeded: 0,
                width: "",
                height: "",
                numberOfWalls: "",
                visited: {}
            }
            this.setState({ calculatorData });
        };

        const calc = () => {
            const { calculatorData } = this.state;
            const { width, height, numberOfWalls, selectedProduct } = calculatorData;
            if (width > 0 && height > 0 && numberOfWalls > 0)
                calculatorData.visited = {
                    numberOfWalls: true,
                    width: true,
                    height: true
                }
            if (width && height && numberOfWalls) {
                const minAmount =
                    (width * height +
                        2 * numberOfWalls * height +
                        2 * width * numberOfWalls) /
                    selectedProduct?.spreading_rate_max;
                const maxAmount =
                    (width * height +
                        2 * numberOfWalls * height +
                        2 * width * numberOfWalls) /
                    selectedProduct.spreading_rate_min;
                if (i18n.language === "ar") {
                    calculatorData.paintsNeeded = `مابين ${minAmount.toFixed()} إلى ${maxAmount.toFixed()} `
                } else {
                    calculatorData.paintsNeeded = `between ${minAmount.toFixed()} to ${maxAmount.toFixed()}`
                }
            }
            this.setState({ calculatorData });
        };

        const handleProductSelection = e => {
            const { calculatorData } = this.state;
            const { prodcuts } = calculatorData;
            const id = e.target.value;
            calculatorData.selectedProduct = prodcuts.filter(
                p => parseInt(p.id) === parseInt(id)
            )[0];
            this.setState({ calculatorData });
            calc();
        };

        return (
            <div className="calculator-wrapper">
                <div className="calculator-container">
                    <div>
                        <img src="/assets/img/close.svg" className={`close-icon ${lang === 'en' ? 'pull-right' : 'pull-left'}`} onClick={() => this.setState({ openedCalculator: false })} alt="remove" />
                    </div>
                    <div className="calculator-header">
                        <div>{t('Paint Calculator')}</div>
                        <div className="sub-header">{t('THE RIGHT PAINT FOR YOU')}</div>
                    </div>
                    <div className="calculator-form">
                        {/* <Row>
                            <Col md={12}>
                                <div className="form-group select-for-group">
                                    <label> {t("Wall Type")}</label>
                                    <select
                                        defaultValue={"default"}
                                        name="selectedType"
                                        onChange={handleTypeSelection}
                                        className="select-input"
                                    >
                                        <option value="INTERIOR">{t("INTERIOR")}</option>
                                        <option value="EXTERIOR">{t("EXTERIOR")}</option>
                                    </select>
                                </div>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col md={12}>
                                <div className="form-group select-for-group">
                                    <label>{t("GLC Product")}</label>
                                    <select
                                        defaultValue={"default"}
                                        name="selectedProduct"
                                        onChange={handleProductSelection}
                                        className="select-input"
                                        value={selectedProduct?.id}
                                    >
                                        {prodcuts.map(p => (
                                            <option key={p.id} value={p.id}>
                                                {p.title} - {p.product_number}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="dimension-col">
                                <div className="form-group dimentions">
                                    <label>{t("Width (m)")}</label>
                                    <input
                                        value={width}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="width"
                                        type="number"
                                        className="form-control text-input"
                                    />
                                    {visited.width && !width && (
                                        <div className="validation-error">
                                            {t("*Required")}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col md={6} className="dimension-col">
                                <div className="form-group dimentions">
                                    <label>{t("Height (m)")}</label>
                                    <input
                                        value={height}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="height"
                                        type="number"
                                        className="form-control text-input"
                                    />
                                    {visited.height && !height && (
                                        <div className="validation-error">
                                            {t("*Required")}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="dimension-col">
                                <div className="form-group ">
                                    <label>{t("Number Of Walls")}</label>
                                    <input
                                        value={numberOfWalls}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="numberOfWalls"
                                        type="number"
                                        className="form-control text-input"
                                    />
                                    {visited.numberOfWalls && !numberOfWalls && (
                                        <div className="validation-error">
                                            {t("*Required")}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <button
                                    disabled={!width || !height || !numberOfWalls}
                                    onClick={calc}
                                    className={`custom-btn calc-button ${(!width || !height || !numberOfWalls) && "disabled-button"}`}
                                >
                                    {t("Calculate")}
                                </button>
                            </Col>
                            <Col md={4}>
                                <button
                                    onClick={clearForm}
                                    className="custom-btn clear-button"
                                >
                                    {t("Clear")}
                                </button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="footer-description">
                                    <div className="mb-2">
                                        {t("SEE HOW MUCH PAINT YOU'LL NEED")}
                                    </div>

                                    <div className="mb-2 font-weight-bolder red-text">
                                        {t("You need")} {paintsNeeded}{" "}
                                        {selectedProduct?.spreading_rate_unit.split(
                                            "/"
                                        )[1] === "LTR"
                                            ? t("liters")
                                            : selectedProduct?.spreading_rate_unit.split(
                                                "/"
                                            )[1]}
                                    </div>

                                    <div>{t('Product available sizes are')}: {selectedProduct?.sizes}</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        );
    }

    setLargeImage = (e) => {
        this.setState({ selectedImageSrc: e.target.src });

        if (e.target.src.includes(".mp4")) {
            this.setState({ imageModal: true, videoURL: e.target.src });
        }
    }

    toggleModal() {
        this.setState({
            imageModal: !this.state.imageModal
        });
    }

    toggleBrochureModal() {
        this.setState({
            brochureModal: !this.state.brochureModal
        });
    }

    playVideo() {
        this.setState({ imageModal: true, videoURL: null });
    }

    render() {

        const { t } = this.props;
        const { isLoaded, productDetails, videoURL, embedVideo, embedBrochure } = this.state;
        const lang = this.props.i18n.language;

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
        };


        if (!isLoaded) {
            return <Loader />;
        } else {
            return (
                <div className="container product-page">
                    <Helmet>
                        <title>GLC Paints | {productDetails.title}</title>
                    </Helmet>
                    {this.getPaintCalculator()}
                    <div className="pt-5 pb-5"></div>
                    <Modal size={'xl'} isOpen={this.state.brochureModal} toggle={this.toggleBrochureModal} className="image-modal">
                        <ModalHeader toggle={this.toggleBrochureModal}></ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: embedBrochure }}></div>
                            {/* <div
                            // style="position:relative;padding-top:max(60%,326px);height:0;width:100%"
                            >
                                <iframe
                                    allow="clipboard-write"
                                    sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
                                    allowFullScreen
                                    // style="position:absolute;border:none;width:100%;height:100%;left:0;right:0;top:0;bottom:0;"
                                    src={embedBrochure}>
                                </iframe>
                            </div> */}
                        </ModalBody>
                    </Modal>
                    <Modal size={'lg'} isOpen={this.state.imageModal} toggle={this.toggleModal} className="image-modal">
                        <ModalHeader toggle={this.toggleModal}></ModalHeader>
                        <ModalBody>
                            {
                                !videoURL && <div dangerouslySetInnerHTML={{ __html: embedVideo }}></div>
                            }
                            {
                                videoURL && <video width="100%" height="100%" controls>
                                    <source src={IMG_URL + videoURL} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            }
                        </ModalBody>
                    </Modal>
                    <Row className="space">
                        <Col md={8}>
                            <div className="d-none d-md-flex">
                                <div className="vertical-images">
                                    <img key={800} onClick={e => this.setLargeImage(e)} src={IMG_URL + productDetails.image} alt="" />
                                    {
                                        productDetails.slider?.map((image, index) => {
                                            return (
                                                <img key={index} onClick={e => this.setLargeImage(e)} src={IMG_URL + image} alt="" />
                                            );
                                        })
                                    }
                                    {
                                        embedVideo && (<div onClick={e => this.playVideo()} className="video-container"><img key={801} src={IMG_URL + productDetails.image} alt="" /><div className="video-preview"></div></div>)
                                    }
                                </div>
                                <img className="product-img" src={this.state.selectedImageSrc} alt="" />
                            </div>
                            <div className="d-md-none mobile-slider">
                                <Slider {...settings}>
                                    <div key={99}>
                                        <img onClick={e => this.setLargeImage(e)} src={IMG_URL + productDetails.image} alt="" />
                                    </div>
                                    {productDetails.slider?.map((slide, index) => {
                                        return (
                                            <div key={index}>
                                                <img onClick={e => this.setLargeImage(e)} src={IMG_URL + slide} alt="" />
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="product-detail">
                                <div className="product-title">{productDetails.title}</div>
                                <div className="product-title mb-2">{productDetails.product_number}</div>
                                <div className="product-subtitle">{productDetails.summary}</div>
                                <div className="product-container-size mb-2">{productDetails.sizes}</div>
                                <button className="calculate" onClick={() => this.setState({ openedCalculator: true })}>{t('Calculate')}</button>
                                <a href="/stores" className="find-store">{t('Find a store')}</a>
                                <div className="product-color-container">
                                    <Row>
                                        {productDetails.features_icons?.map((icon, index) => {
                                            return (
                                                <Col md={6}>
                                                    <div className="color">
                                                        <img src={IMG_URL + icon.icon} alt="" />
                                                        <span>{lang === 'en' ? icon.name_en : icon.name_ar}</span>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                        {/* {productDetails.icon_color_retain && <Col>
                                            <div className="color">
                                                <img src="/assets/img/product/color-retention1.svg" alt="" />
                                                <span>High color retention</span>
                                            </div>
                                        </Col>} */}
                                        {/* {productDetails.icon_anti_fungs && <Col>
                                            <div className="color">
                                                <img src="/assets/img/product/color-retention2.svg" alt="" />
                                                <span>High color retention</span>
                                            </div>
                                        </Col>} */}
                                    </Row>
                                    {/* <Row> */}
                                    {/* {productDetails.icon_weather_chng_resist && <Col>
                                            <div className="color">
                                                <img src="/assets/img/product/color-retention3.svg" alt="" />
                                                <span>High color retention</span>
                                            </div>
                                        </Col>} */}
                                    {/* {productDetails.a && <Col>
                                            <div className="color">
                                                <img src="/assets/img/product/color-retention1.svg" alt="" />
                                                <span>High color retention</span>
                                            </div>
                                        </Col>} */}
                                    {/* </Row> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="box product-features">
                                <div className="title">{t('Product features')}</div>
                                <div dangerouslySetInnerHTML={{ __html: productDetails.features }}></div>
                            </div>
                            <div className="box technical-information">
                                <div className="title">{t('Technical Information')}</div>

                                <div className="key">{t('Colors')}</div>
                                <div className="value">{productDetails?.colors?.map((color, index) => {
                                    return (lang === 'en' ? color.name_en : color.name_ar) + (index !== productDetails.colors.length - 1 ? ', ' : '');
                                })}</div>

                                {/* <div className="key">Coverage</div>
                                <div className="value"></div> */}

                                <div className="key">{t('Coats')}</div>
                                <div className="value">{productDetails.coats}</div>

                                <div className="key">{t('Drying Time')}</div>
                                <div className="value">{lang === 'en' ? productDetails.drying_time: productDetails.drying_time?.replace("Hours", "ساعات")?.replace("Minutes", "دقائق")}</div>

                                <div className="key">{t('Finish')}</div>
                                <div className="value">{lang === 'en' ? productDetails?.finish?.name_en : productDetails?.finish?.name_ar}</div>

                                <div className="key">{t('Spreading Rate')}</div>
                                <div className="value">{productDetails.spreading_rate_min} - {productDetails.spreading_rate_max} {t(productDetails.spreading_rate_unit)}</div>
                            </div>
                        </Col>
                        <Col md={8} className="product-body">
                            <div className="heading">{t('Product Description')}</div>
                            <p className="mb-4" dangerouslySetInnerHTML={{ __html: productDetails.description }}></p>

                            {
                                productDetails.recommended_use && <>
                                    <div className="heading">{t('Recommended Use')}</div>
                                    <p dangerouslySetInnerHTML={{ __html: productDetails.recommended_use }}></p>
                                </>
                            }

                            <div className="heading">{t('Tools')}</div>
                            <div className="tools-container">
                                {productDetails.roller === 1 && <div className="tool"><img src="/assets/img/product/roller.svg" alt="" /> {t('Roller')}</div>}
                                {productDetails.brush === 1 && <div className="tool"><img src="/assets/img/product/brush.svg" alt="" /> {t('Brush')}</div>}
                                {productDetails.spray_gun === 1 && <div className="tool"><img src="/assets/img/product/spray_gun.svg" alt="" /> {t('Spraying Gun')}</div>}
                                {productDetails.putty_knife === 1 && <div className="tool"><img src="/assets/img/product/putty-knife.svg" alt="" /> {t('Putty Knife')}</div>}
                                {productDetails.trowel === 1 && <div className="tool"><img src="/assets/img/product/trowel.svg" alt="" /> {t('Trowel')}</div>}
                                {productDetails.plastic_trowel === 1 && <div className="tool"><img src="/assets/img/product/plastic_trowel.svg" alt="" /> {t('Plastic Trowel')}</div>}
                                {productDetails.airless_spray === 1 && <div className="tool"><img src="/assets/img/product/airless_spray.svg" alt="" /> {t('Airless Spray')}</div>}
                            </div>
                            {/* <div className="heading">Application Description</div>
                            <p>
                                Gel Nile 290 is an interior and exterior water-based transparent sealer. Produced with a special formula based on 100% pure acrylic emulsion that ensures excellent alkali resistance and sealing of surface pore
                                s. based on 100% pure acrylic emulsion that ensures excellent alkali resistance and sealing of surface por
                            </p> */}
                            {(productDetails.documents?.length>0 || embedBrochure) && <div className="heading">{t('Technical & Safety Documentation')}</div>}
                            <div className="files">
                                {productDetails.documents?.map((file, index) => {
                                    return (
                                        <div className="file">
                                            <div>
                                                <img src="/assets/img/product/pdf.svg" alt="" />
                                                {file.doc_name_en}
                                            </div>
                                            <a href={IMG_URL + file.doc_file} target="_blank" rel="noopener noreferrer" download>
                                                <img src="/assets/img/product/download.svg" alt="" />
                                            </a>
                                        </div>
                                    );
                                })}
                                {embedBrochure && <div className="file">
                                    <div>
                                        <img src="/assets/img/product/pdf.svg" alt="" />
                                        {t('Brochure')}
                                    </div>
                                    <img src="/assets/img/product/play.svg" alt="" onClick={e => this.toggleBrochureModal()} />
                                </div>}
                            </div>
                        </Col>
                    </Row>
                    {productDetails?.inspirations_section?.length > 0 && <div className="inspirations-container">
                        <div className="heading">{t('Inspirations')}</div>
                        <div className="row images">
                            {
                                productDetails?.inspirations_section?.map((inspiration, index) => {
                                    return (<Link title={inspiration.name} key={index} to={inspiration.link}>
                                        <img className="" src={IMG_URL + inspiration.image} alt={inspiration.name} />
                                        <div className="overlay">{inspiration.name} <img src={'/assets/img/arrow-right.svg'} alt="arrow-right" /></div>
                                    </Link>)
                                })
                            }
                        </div>
                    </div>}

                    {productDetails?.colors_section?.length > 0 && <div className="colors">
                        <div className="heading">{t('Colors')}</div>
                        <div className="row images">
                            {productDetails?.colors_section?.map((color, index) => {
                                return (<a title={color.name} key={index} href={color.link}><div key={index} style={{ backgroundColor: color.code }} className="color" />
                                    <div className="overlay">{color.name}</div>
                                </a>)
                            })}
                        </div>
                    </div>}

                </div>
            );
        }
    }
}

export default withTranslation("common")(ProductDetail);
