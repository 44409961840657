import React, { Component } from "react";

import AxiosInstance, { AltAxiosInstance } from "../../../const/const";
import VirtualizedSelect from "react-virtualized-select";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import { withTranslation } from "react-i18next";
import "./UserForm.scss";
import GlcButton from "../../../components/common/Buttons/Button";
import swal from "sweetalert";
import i18n from "i18next";
import ReCAPTCHA from "react-google-recaptcha";

class Form extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      cities: [],
      positions: [],
      alert: null,
      city: 0,
      name: "",
      phone: "",
      email: "",
      touched: {},
      errorMessageEmail: "",
      errorMessagePhone: "",
      isEmailError: false,
      isPhoneError: false
    };
  }

  componentDidMount() {
    const that = this;
    that._isMounted = true;
    AxiosInstance.get("api/cities").then(function(response) {
      if (that._isMounted) {
        that.setState({
          cities: response.data.map(c => {
            return { label: c.name, value: c.id };
          })
        });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = e => {
    e.preventDefault();
    if (!!this.recaptchaRef.current.getValue()) {
      const {
        name,
        phone,
        city,
        email,
        isEmailError,
        isPhoneError
      } = this.state;
      if (!name || !phone || !city || !email || isEmailError || isPhoneError) {
        return;
      }
      AltAxiosInstance.post("GLCWebsiteService/AddDistributor", {
        Email: this.state.email,
        PhoneNumber: this.state.phone,
        FullName: this.state.name,
        City: this.state.city.value
      })

        .then(res => {
          i18n.language === "en"
            ? swal({
                title: "Done!",
                text: "Successfully added",
                icon: "success",
                timer: 3000,
                button: false
              })
            : swal({
                title: "شكرا",
                text: "تم إرسال الطلب بنجاح",
                icon: "success",
                timer: 3000,
                button: false
              });

          this.setState({
            phone: "",
            name: "",
            city: null,
            email: "",
            touched: {},
            success: false
          });
        })
        .catch(error => {});
    } else {
      this.recaptchaRef.current.execute();
    }
  };
  validateForm(input, inputType) {
    const { t } = this.props;
    if (inputType === "email") {
      if (typeof input !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(
          /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i
        );
        if (!pattern.test(input)) {
          this.setState({
            errorMessageEmail: `${t("*Please enter valid email")}`,
            isEmailError: true
          });
        } else {
          this.setState({
            errorMessageEmail: "",
            isEmailError: false
          });
        }
      }
    } else if (inputType === "phone") {
      if (!input.match(/^[0-9]*$/)) {
        this.setState({
          errorMessagePhone: `${t("*Only numbers are allowed")}`,
          isPhoneError: true
        });
      } else {
        this.setState({
          errorMessagePhone: "",
          isPhoneError: false
        });
      }
    }
  }
  handleBlur = e => {
    const { touched } = this.state;
    touched[e.target.name] = true;
    this.setState({
      touched
    });
  };

  render() {
    const { t } = this.props;
    const { touched, email, name, phone, city } = this.state;
    return (
      <form className="centered-form" onSubmit={this.handleSubmit}>
        <div className="form-group ">
          <input
            name="name"
            type="text"
            placeholder={t("FULL NAME")}
            className={`form-control ${touched.name &&
              !name &&
              "is-invalid"} text-style about-input`}
            value={name}
            onBlur={this.handleBlur}
            onChange={e => this.setState({ name: e.target.value })}
          />
          {touched.name && !name && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}
        </div>
        <div className="form-group ">
          <input
            name="phone"
            type="text"
            placeholder={t("PHONE NUMBER")}
            className={`form-control ${touched.phone &&
              !phone &&
              "is-invalid"}  ${this.state.isPhoneError &&
              "is-invalid"} text-style about-input`}
            value={phone}
            onBlur={this.handleBlur}
            onChange={e => {
              this.validateForm(e.target.value, "phone");
              this.setState({ phone: e.target.value });
            }}
          />
          {touched.phone && !phone && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}
          <div className="invalid-feedback">{this.state.errorMessagePhone}</div>
        </div>
        <div className="form-group">
          <input
            name="email"
            type="text"
            placeholder={t("EMAIL ADDRESS")}
            className={`form-control ${touched.email &&
              !email &&
              "is-invalid"}  ${this.state.isEmailError &&
              "is-invalid"}  text-style about-input`}
            value={email}
            onBlur={this.handleBlur}
            onChange={e => {
              this.validateForm(e.target.value, "email");
              this.setState({ email: e.target.value });
            }}
          />
          {touched.email && !email && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}
          <div className="invalid-feedback">{this.state.errorMessageEmail}</div>
        </div>
        <div className="form-group about-input">
          <VirtualizedSelect
            className="text-style city-select"
            name="city_id"
            placeholder={t("CITY")}
            value={city}
            options={this.state.cities}
            onChange={e => this.setState({ city: e })}
          />
        </div>
        <div>
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            sitekey="6LfhOeIUAAAAABhBnyHRmRzO_TiKvPuYyxMgh1gu"
          />
        </div>

        <GlcButton
          type="submit"
          myStyle="about-form-send-btn"
          text={t("Send")}
        />
      </form>
    );
  }
}

export default withTranslation("common")(Form);
