import React, { Component } from "react";
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import "./Content.scss";
import $ from "jquery";

$(document).ready(function() {
  $(document).on("contextmenu", ".dynamic-img", function(e) {
    return false;
  });
});

class InspirationContent extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tableRows: [],
      pageInfo: {},
      selectedCombinationGroup: {},
      selectedImage: {},
      bigImage: 1,
      selectedGroupNum: 1,
      selectedTab: {},
      productsRows: []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.setState({ isLoaded: false }, () => {
        document.getElementById("main-head").scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest"
        });
      });
      this.loadPageData();
    }
  }

  componentDidMount() {
    this.loadPageData();
    var thisVar = this;
    thisVar._isMounted = true;
    const slug = this.props.match.params.slug;
    AxiosInstance.get(`api/inspirations/${slug}/products`).then(function(
      response
    ) {
      if (thisVar._isMounted) {
        thisVar.setState(
          {
            productsRows: response.data
          },
          () => {
            document.getElementById("main-head").scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest"
            });
          }
        );
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  loadPageData = () => {
    var thisVar = this;
    const slug = this.props.match.params.slug;
    const tabTitle = this.props.match.params.tabTitle;
    AxiosInstance.get(`api/inspirations/${slug}`).then(function(response) {
      response.data.map((item, i) => (item.index = i));
      let selectedTab = response.data.filter(i => i.subsection === tabTitle);
      if (selectedTab.length > 0) {
        selectedTab = selectedTab[0];
      } else {
        selectedTab = response.data[0];
      }

      thisVar.setState({
        tableRows: response.data,
        selectedImage: IMG_URL + selectedTab.image1_option1,
        selectedTab
      });
      thisVar.selectGroup1();

      // Api for Page
      AxiosInstance.get("api/page/inspiration/subpages").then(function(
        response
      ) {
        thisVar.setState({
          isLoaded: true,
          pageInfo: response.data.filter(page => page.slug === slug)[0]
        });
      });
    });
  };

  selectGroup1 = (event, selectedTab) => {
    selectedTab = selectedTab || this.state.selectedTab;
    this.setState({
      selectedCombinationGroup: {
        image1: selectedTab.image1_option1,
        image2: selectedTab.image2_option1,
        image3: selectedTab.image3_option1
      },
      bigImage: 1
    });
    if (
      this.state.selectedImageNumu === "1" ||
      this.state.selectedImageNumu === undefined
    )
      this.setState({ selectedImage: IMG_URL + selectedTab.image1_option1 });
    else if (this.state.selectedImageNumu === "2")
      this.setState({ selectedImage: IMG_URL + selectedTab.image2_option1 });
    else if (this.state.selectedImageNumu === "3")
      this.setState({ selectedImage: IMG_URL + selectedTab.image3_option1 });
  };

  selectGroup2 = () => {
    const { selectedTab } = this.state;
    this.setState({
      selectedCombinationGroup: {
        image1: selectedTab.image1_option1,
        image2: selectedTab.image2_option1,
        image3: selectedTab.image3_option1
      },
      bigImage: 2
    });
    if (
      this.state.selectedImageNumu === "1" ||
      this.state.selectedImageNumu === undefined
    )
      this.setState({ selectedImage: IMG_URL + selectedTab.image1_option2 });
    else if (this.state.selectedImageNumu === "2")
      this.setState({ selectedImage: IMG_URL + selectedTab.image2_option2 });
    else if (this.state.selectedImageNumu === "3")
      this.setState({ selectedImage: IMG_URL + selectedTab.image3_option2 });
  };

  setAsBigImage = event => {
    const url = event.target.attributes.src.value;
    this.setState({
      bigImage: 1,
      selectedImage: url,
      selectedImageNumu: event.target.dataset.imgnum
    });
  };

  updateSelectedTab = tabId => {
    const { tableRows } = this.state;
    const tab = tableRows.filter(t => t.subsection === tabId)[0];
    this.setState({ selectedTab: tab });
    this.selectGroup1(null, tab);
    const slug = this.props.match.params.slug;
    const lang = i18n.language;
    this.props.history.push(`/${lang}/inspiration/${slug}/${tabId}`);
  };

  goToColor = path => {
    const lang = i18n.language;
    this.props.history.push("/" + lang + path);
  };

  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    let result = [];
    if (tempDiv.innerHTML.includes("<p")) {
      tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));
    } else {
      result.push(html);
    }
    let result2 = result.splice(0, 1)[0];
    let temp = result2.split("");
    if (temp[0] === ".") {
      temp.splice(0, 1);
      temp.push(".");
      let result3 = temp.join("");
      return result3;
    } else {
      return result2;
    }
  };
  getObjectOf = obj => {
    return obj || {};
  };

  render() {
    if (!this.state.isLoaded) {
      return <Loader />;
    }

    const {
      tableRows,
      selectedTab,
      selectedImage,
      selectedCombinationGroup
    } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <title>GLC Paints | Inspiration</title>
          <title>{selectedTab.seo_title}</title>
          <meta
            name="description"
            content={this.extractParagraphTexts(selectedTab.description)}
          />
          <meta name="keywords" content={t(selectedTab.subsection)} />
          <meta property="og:title" content={selectedTab.seo_title} />
          <meta
            property="og:image"
            content={IMG_URL + selectedTab.socialmedia_image}
          />
        </Helmet>
        <div className="dynamic-img interior-bg">
          <img
            src={IMG_URL + this.state.pageInfo.banner_image}
            alt={t("Green wall with vintage furniture")}
          />
          <div className="interior-title">
            <h1>{this.state.pageInfo.title}</h1>
            <p>{this.state.pageInfo.subtitle}</p>
          </div>
        </div>
        <div className="tablist custom-tabs inspiration-tabs">
          <TabsComponent defaultIndex={selectedTab.index}>
            <TabList>
              {tableRows.map((tab, index) => {
                return (
                  <Tab
                    onClick={() => {
                      this.updateSelectedTab(tab.subsection);
                    }}
                    key={index}
                  >
                    {t(tab.subsection)}
                  </Tab>
                );
              })}
            </TabList>
            {tableRows.map((item, index) => {
              return (
                <TabPanel key={index}>
                  <div className="type-tab-containt">
                    <div className="insp-boxx">
                      <div className="insp-scale dynamic-img inspiration-big-img-div">
                        <img
                          src={selectedImage}
                          className="ambiance-bg inspiration-big-img"
                          alt={`${item.section} inspiration ${item.subsection}`}
                        />
                        <span className="insp1-overlay-bg"></span>
                      </div>

                      <div className="insp-scale">
                        <div className="dynamic-img insp-boxxx">
                          <img
                            onClick={this.setAsBigImage}
                            npm={1}
                            src={IMG_URL + selectedCombinationGroup.image1}
                            className="ambiance-bg"
                            alt={`${item.section} inspiration ${item.subsection}`}
                          />
                          <img
                            onClick={this.setAsBigImage}
                            data-imgnum={2}
                            src={IMG_URL + selectedCombinationGroup.image2}
                            className="ambiance-bg"
                            alt={`${item.section} inspiration ${item.subsection}`}
                          />
                          <img
                            onClick={this.setAsBigImage}
                            data-imgnum={3}
                            src={IMG_URL + selectedCombinationGroup.image3}
                            className="ambiance-bg"
                            alt={`${item.section} inspiration ${item.subsection}`}
                          />
                        </div>
                        <Container className="color-combination fit-height">
                          <Row className="insp-text-center">
                            <Col md={12}>
                              <p>
                                {" "}
                                {this.extractParagraphTexts(item.description)}
                              </p>
                            </Col>
                          </Row>
                          <Row className="insp-button-center">
                            <Col md={12} className="radio">
                              <input
                                id="combination1"
                                type="radio"
                                name="combination"
                                className="combination-name"
                                onChange={this.selectGroup1}
                                checked={this.state.bigImage === 1}
                              />
                              <label htmlFor="combination1">
                                <div className="checker"></div>
                                {i18n.language === "ar" ? (
                                  <h3>مجموعة ألوان 1</h3>
                                ) : (
                                  <h3>Color Combination 1</h3>
                                )}
                              </label>
                            </Col>
                            <div className="colors-container">
                              <Col
                                className="color-container"
                                onClick={e =>
                                  this.goToColor(
                                    `/colors/${
                                      this.getObjectOf(item.option1color1)
                                        .family.slug
                                    }/${
                                      this.getObjectOf(item.option1color1).name
                                    }`
                                  )
                                }
                                md={4}
                                style={{
                                  backgroundColor: this.getObjectOf(
                                    item.option1color1
                                  ).hex_code
                                }}
                              >
                                <div className="color-name">
                                  {this.getObjectOf(item.option1color1).name}
                                </div>
                                <div className="color-code">
                                  {
                                    this.getObjectOf(item.option1color1)
                                      .color_code
                                  }
                                </div>
                              </Col>

                              <Col
                                className="color-container"
                                onClick={e =>
                                  this.goToColor(
                                    `/colors/${
                                      this.getObjectOf(item.option1color2)
                                        .family.slug
                                    }/${
                                      this.getObjectOf(item.option1color2).name
                                    }`
                                  )
                                }
                                md={4}
                                style={{
                                  backgroundColor: this.getObjectOf(
                                    item.option1color2
                                  ).hex_code
                                }}
                              >
                                <div className="color-name">
                                  {this.getObjectOf(item.option1color2).name}
                                </div>
                                <div className="color-code">
                                  {
                                    this.getObjectOf(item.option1color2)
                                      .color_code
                                  }
                                </div>
                              </Col>
                              <Col
                                className="color-container"
                                onClick={e =>
                                  this.goToColor(
                                    `/colors/${
                                      this.getObjectOf(item.option1color3)
                                        .family.slug
                                    }/${
                                      this.getObjectOf(item.option1color3).name
                                    }`
                                  )
                                }
                                md={4}
                                style={{
                                  backgroundColor: this.getObjectOf(
                                    item.option1color3
                                  ).hex_code
                                }}
                              >
                                <div className="color-name">
                                  {this.getObjectOf(item.option1color3).name}
                                </div>
                                <div className="color-code">
                                  {
                                    this.getObjectOf(item.option1color3)
                                      .color_code
                                  }
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row className="insp-button-center">
                            <Col md={12} className="radio">
                              <input
                                id="combination2"
                                type="radio"
                                name="combination"
                                className="combination-name"
                                onChange={this.selectGroup2}
                                checked={this.state.bigImage === 2}
                              />
                              <label htmlFor="combination2">
                                <div className="checker"></div>
                                {i18n.language === "ar" ? (
                                  <h3>مجموعة ألوان 2</h3>
                                ) : (
                                  <h3>Color Combination 2</h3>
                                )}
                              </label>
                            </Col>
                            <div className="colors-container">
                              <Col
                                className="color-container"
                                onClick={e =>
                                  this.goToColor(
                                    `/colors/${
                                      this.getObjectOf(item.option2color1)
                                        .family.slug
                                    }/${
                                      this.getObjectOf(item.option2color1).name
                                    }`
                                  )
                                }
                                md={4}
                                style={{
                                  backgroundColor: this.getObjectOf(
                                    item.option2color1
                                  ).hex_code
                                }}
                              >
                                <div className="color-name">
                                  {this.getObjectOf(item.option2color1).name}
                                </div>
                                <div className="color-code">
                                  {
                                    this.getObjectOf(item.option2color1)
                                      .color_code
                                  }
                                </div>
                              </Col>
                              <Col
                                className="color-container"
                                onClick={e =>
                                  this.goToColor(
                                    `/colors/${
                                      this.getObjectOf(item.option2color2)
                                        .family.slug
                                    }/${
                                      this.getObjectOf(item.option2color2).name
                                    }`
                                  )
                                }
                                md={4}
                                style={{
                                  backgroundColor: this.getObjectOf(
                                    item.option2color2
                                  ).hex_code
                                }}
                              >
                                <div className="color-name">
                                  {this.getObjectOf(item.option2color2).name}
                                </div>
                                <div className="color-code">
                                  {
                                    this.getObjectOf(item.option2color2)
                                      .color_code
                                  }
                                </div>
                              </Col>
                              <Col
                                className="color-container"
                                onClick={e =>
                                  this.goToColor(
                                    `/colors/${
                                      this.getObjectOf(item.option2color3)
                                        .family.slug
                                    }/${
                                      this.getObjectOf(item.option2color3).name
                                    }`
                                  )
                                }
                                md={4}
                                style={{
                                  backgroundColor: this.getObjectOf(
                                    item.option2color3
                                  ).hex_code
                                }}
                              >
                                <div className="color-name">
                                  {this.getObjectOf(item.option2color3).name}
                                </div>
                                <div className="color-code">
                                  {
                                    this.getObjectOf(item.option2color3)
                                      .color_code
                                  }
                                </div>
                              </Col>
                            </div>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </TabsComponent>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(InspirationContent);
