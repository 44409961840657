import React, { Component } from "react";
import AxiosInstance, { Loader } from "../../const/const";
import GoogleMapReact from "google-map-react";
import { Container, Row, Col } from "reactstrap";
import { Tabs as TabsComponent } from "react-tabs";
import "./Stores.scss";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import GlcMarker from "./GlcMarker/GlcMarker";
import { Helmet } from "react-helmet";
const apiKey = "1";

class Stores extends Component {
  _isMounted = false;
  _timer = null;
  mapStyles = {
    width: "100%",
    height: "auto"
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      defaultProps: {
        center: {
          lat: 30.2674356,
          lng: 31.4649226
        },
        zoom: 11
      },
      countries: {},
      stores: [],
      filterdStores: [],
      selectedCountry: { cities: [] },
      selectedCity: null,
      storesAndHQ: [],
      loading: true,
      pageInfo: {}
    };
  }

  getCountries = data => {
    const countries = {};
    const filterData = data.filter(s => s.country != null);
    filterData.map(row => {
      countries[row.store_country_local.id] = countries[
        row.store_country_local.id
      ] || { cities: {} };
      countries[row.store_country_local.id].name = row.store_country_local.name;
      countries[row.store_country_local.id].id = row.store_country_local.id;
      if (row.store_city_local) {
        countries[row.store_country_local.id].cities[
          row.store_city_local.id
        ] = {};
        countries[row.store_country_local.id].cities[
          row.store_city_local.id
        ].name = row.store_city_local.name;
      }
    });
    return countries;
  };

  getTypes = data => {
    return [...new Set(data.map(row => row.type))];
  };

  componentDidMount(props) {
    this._isMounted = true;
    var thisVar = this;
    if (thisVar._isMounted) {
      this.setState({
        loading: false
      });
    }
    AxiosInstance.get("api/page/stores").then(function(response) {
      document.getElementById("main-head").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
      if (thisVar._isMounted) {
        thisVar.setState({ pageInfo: response.data });
      }
    });

    AxiosInstance.get("api/stores").then(function(response) {
      const countries = thisVar.getCountries(response.data);
      const selectedCountry = countries[Object.keys(countries)[0]];
      const selectedCity = Object.keys(selectedCountry.cities)[0];
      const types = thisVar.getTypes(response.data);
      const filterdStores = thisVar.filterStores(
        response.data,
        selectedCountry,
        selectedCity
      );
      const tempArr = filterdStores.filter(s => s.country != null);
      const filterData = response.data.filter(s => s.country != null);
      if (thisVar._isMounted) {
        thisVar.setState({
          isLoaded: true,
          stores: filterData,
          countries,
          selectedCountry,
          selectedCity,
          types,
          selectedType: types[0],
          filterdStores: tempArr
        });
      }
    });
    if (thisVar._isMounted) {
      this._timer = setTimeout(() => {
        let result = window.confirm("Wants to know your location");
        if (result === true) {
          this.getLocation();
        }
      }, 15000);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._timer);
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showUserLocation);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  showUserLocation = position => {
    this.setState({
      defaultProps: {
        center: {
          lng: position.coords.longitude,
          lat: position.coords.latitude
        }
      }
    });

    let userLocation = {
      lat: this.state.defaultProps.center.lat,
      lng: this.state.defaultProps.center.lng,
      name: "My Location",
      id: 1000
    };
    let storesWithUserLocation = [...this.state.filterdStores];

    storesWithUserLocation.push(userLocation);
    this.setState({ filterdStores: storesWithUserLocation }, () => {
      this.updateMapCenterUserLocation(storesWithUserLocation);
      console.log(storesWithUserLocation);
    });
  };
  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = key => {
    let storesWithHQ = [...this.state.filterdStores];
    storesWithHQ.push({
      lat: 30.2674356,
      lng: 31.4649226,
      name: "GLC Paints HQ",
      get_diretion_url:
        "https://www.google.com/maps/place/GLC+Paints/@30.2681445,31.4647777,17z/data=!4m12!1m6!3m5!1s0x0:0x1ff93f0006167ad0!2sGLC+Paints!8m2!3d30.2678526!4d31.4659579!3m4!1s0x0:0x1ff93f0006167ad0!8m2!3d30.2678526!4d31.4659579",
      id: 1
    });

    storesWithHQ.map(store => {
      store.show = false;
    });

    storesWithHQ[parseInt(key)].show = !storesWithHQ[parseInt(key)].show;
    this.setState({ storesWithHQ });
    this.setState({ filterdStores: storesWithHQ });
    this.updateMapCenterStoreClick(storesWithHQ[parseInt(key)]);
  };
  showStoreInfo = name => {
    const { filterdStores } = this.state;
    filterdStores.map(store => {
      store.show = false;
    });
    let index = 0;
    this.setState(
      state => {
        index = state.filterdStores.findIndex(e => e.name === name);
        state.filterdStores[index].show = !state.filterdStores[index].show; // eslint-disable-line no-param-reassign
        return { filterdStores: state.filterdStores };
      },
      () => {
        this.updateMapCenterStoreClick(filterdStores[index]);
      }
    );
  };

  displayMarkers = () => {
    let storesWithHQ = [...this.state.filterdStores];
    storesWithHQ.push({
      lat: 30.2674356,
      lng: 31.4649226,
      name: "54 St., Block 13027,First Industrial Zone El Obour - Egypt",
      id: 1
    });
    return storesWithHQ.map((store, index) => {
      return (
        <GlcMarker
          lat={store.lat}
          lng={store.lng}
          title={store.name}
          id={store.id}
          key={index}
          store={store}
        />
      );
    });
  };

  filterStores = (stores, selectedCountry, selectedCity) => {
    let temaprr = stores.filter(s => s.store_country_local !== null);
    let filterdStores = temaprr.filter(
      s => parseInt(s.store_country_local.id) === parseInt(selectedCountry.id)
    );
    // To make sure that the stores have no city with the value 'null'
    const tempArray = filterdStores.filter(s => s.city !== null);
    if (Object.keys(selectedCountry.cities).length > 0 && selectedCity) {
      filterdStores = tempArray.filter(
        s => parseInt(s.store_city_local.id) === parseInt(selectedCity)
      );
    }
    for (let i = 0; i < filterdStores.length; i++) {
      let phoneFields = [];
      phoneFields = filterdStores[i].phone.split("- ");
      filterdStores[i].phoneFields = phoneFields;
      filterdStores[i].show = false;
    }

    return filterdStores;
  };

  openDirection = store => {
    window.open(
      "https://www.google.com/maps/dir/?api=" +
        apiKey +
        "&destination=" +
        store.lat +
        "," +
        store.lng +
        "&zoom=13z",
      "_blank"
    );
  };

  renderByType = type => {
    const { t } = this.props;
    const { filterdStores } = this.state;
    const filterdStoresByType = filterdStores.filter(s => s.type === type);
    return (
      <div className="stores-list">
        {filterdStoresByType.map((store, i) => {
          return (
            <div
              itemScope
              key={i}
              className="store-link-container"
              onClick={() => {
                this.showStoreInfo(store.name);
              }}
            >
              <div className="store-address">
                <div className="store-title">
                  <span itemProp="name">{store.name}</span>
                </div>
                <div className="store-details">
                  <Row>
                    <Col sm={12} md={12} lg={12} xl={12}>
                      <div className="phone">
                        <i className="fa fa-phone"></i>
                        <span itemProp="telephone"> {store.phoneFields}</span>
                      </div>

                      <div className="email">
                        <i className="fa fa-envelope"></i>
                        <span itemProp="email"> {store.email}</span>
                      </div>
                    </Col>
                    <Col sm={12} md={12} lg={12} xl={12}>
                      <div className="address">
                        <i className="fa fa-map-marker"></i>
                        <span itemProp="address"> {store.address}</span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div itemProp="hasMap" className="go-button">
                        <button onClick={() => this.openDirection(store)}>
                          {t("GET DIRECTION")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <hr />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  changeCountry = e => {
    const { countries, stores } = this.state;
    const selectedCountry = countries[e.target.value];
    let selectedCity = null;
    if (Object.keys(selectedCountry.cities).length > 0) {
      selectedCity = Object.keys(selectedCountry.cities)[0];
    }
    const filterdStores = this.filterStores(
      stores,
      selectedCountry,
      selectedCity
    );
    this.setState({ selectedCountry, selectedCity, filterdStores });
    this.updateMapCenter(filterdStores);
  };

  changeCity = e => {
    const { stores, selectedCountry } = this.state;
    const selectedCity = e.target.value;
    const filterdStores = this.filterStores(
      stores,
      selectedCountry,
      selectedCity
    );
    this.setState({ selectedCity, filterdStores });
    this.updateMapCenter(filterdStores);
  };

  updateMapCenter = filterStores => {
    if (filterStores.length > 0) {
      let { lat, lng } = filterStores[0];

      this.setState({
        defaultProps: { center: { lat: lat, lng: lng }, zoom: 11 }
      });
    }
  };
  updateMapCenterStoreClick = store => {
    this.setState({
      defaultProps: { center: { lat: store.lat, lng: store.lng }, zoom: 14 }
    });
  };
  updateMapCenterUserLocation = filterStores => {
    if (filterStores.length > 0) {
      let { lat, lng } = filterStores[filterStores.length - 1];

      this.setState({
        defaultProps: { center: { lat: lat, lng: lng, zoom: 11 } }
      });
      console.log(`lat: ${lat} .. lng: ${lng}`);
    }
  };
  renderMap() {
    return (
      <GoogleMapReact
        onChildClick={this.onChildClickCallback}
        center={this.state.defaultProps.center}
        zoom={this.state.defaultProps.zoom}
      >
        {this.displayMarkers()}
      </GoogleMapReact>
    );
  }
  render() {
    const { t } = this.props;
    const {
      isLoaded,
      types,
      pageInfo,
      countries,
      selectedCountry,
      selectedCity
    } = this.state;

    if (!isLoaded) {
      return <Loader />;
    } else {
      return (
        <div>
          <Helmet>
            <title>GLC Paints | Stores</title>
            <meta
              name="description"
              content={t("GLC Paints") + " " + pageInfo.subtitle}
            />
            <meta
              name="keywords"
              content={
                t("GLC Paints HQ") + "" + t("Stores") + "" + t("Show Rooms")
              }
            />
          </Helmet>
          <section className="main-bg">
            <div className="big-title">
              <div className="title-center">
                <h1>{pageInfo.title}</h1>
                <p>{pageInfo.subtitle}</p>
              </div>
            </div>
          </section>

          <div className="stores-container">
            <Container fluid>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <div className="map-container">{this.renderMap()}</div>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <div className="filter-container">
                    <Row>
                      <h4 className="hq-address-head">
                        {i18n.language === "en"
                          ? "GLC Paints"
                          : "الشركة الألمانية اللبنانية للصناعة، دهانات جي ال سي"}
                      </h4>
                    </Row>
                    <Row>
                      <span className="hq-address1">{t("HQ")}:</span>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <h5 className="hq-address">
                          {t(
                            "54 St, Block 13027, First Industrial Zone El Obour - Egypt"
                          )}
                        </h5>
                      </Col>

                      <Col md={4}>
                        <div className="form-group select-for-group">
                          <select
                            value={selectedCountry.id}
                            name="selectedCountry"
                            onChange={e => {
                              this.changeCountry(e);
                            }}
                            className="select-input"
                          >
                            {Object.keys(countries).map((countryKey, i) => {
                              return (
                                <option key={i} value={countryKey}>
                                  {countries[countryKey].name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        {(() => {
                          if (Object.keys(selectedCountry.cities).length > 0) {
                            return (
                              <div className="form-group select-for-group">
                                <select
                                  value={selectedCity}
                                  name="selectedCity"
                                  onChange={e => {
                                    this.changeCity(e);
                                  }}
                                  className="select-input"
                                >
                                  {Object.keys(selectedCountry.cities).map(
                                    (cityKey, i) => {
                                      return (
                                        <option key={i} value={cityKey}>
                                          {
                                            countries[selectedCountry.id]
                                              .cities[cityKey].name
                                          }
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            );
                          }
                        })()}
                      </Col>
                    </Row>
                  </div>
                  <div className="tabs-container custom-tabs">
                    <TabsComponent>
                      <div>
                        {types.map((type, index) => {
                          return <div onClick={() => {}} key={index}></div>;
                        })}
                      </div>
                      {types.map((type, index) => {
                        return (
                          <div key={index}>
                            <div className="type-tab-containt">
                              {this.renderByType(type)}
                            </div>
                          </div>
                        );
                      })}
                    </TabsComponent>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation("common")(Stores);
