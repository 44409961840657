import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import Moment from "react-moment";
import "./index.scss";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Slider from "react-slick";


$(document).ready(function () {
    $(document).on("contextmenu", ".dynamic-img", function (e) {
        return false;
    });
});

class Projects extends Component {
    executeOnClick(isExpanded) { }
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            tableRows: {},
            pageInfo: {},
            blogs: Array.from({ length: 5 }),
            hasMore: true
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const thisVar = this;
        AxiosInstance.get("api/page/projects").then(function (response) {
            document.getElementById("main-head").scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });
            if (thisVar._isMounted) {
                thisVar.setState({ pageInfo: response.data });
            }
        });

        AxiosInstance.get("api/projects").then(function (response) {
            if (thisVar._isMounted) {
                thisVar.setState({
                    isLoaded: true,
                    tableRows: response.data
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { t } = this.props;
        const { isLoaded, pageInfo } = this.state;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
        };


        if (!isLoaded) {
            return <Loader />;
        } else {
            return (
                <div className="all-projects">
                    <Helmet>
                        <title>GLC Paints | {t('Projects')}</title>
                        <meta
                            name="keywords"
                            content={t("Projects") + t("GLC Paints")}
                        />
                        <meta
                            name="description"
                            content={t(
                                "LEARN MORE ABOUT YOUR FAVORITE PAINT BRAND ,GLC Paints"
                            )}
                        />
                    </Helmet>
                    <section className="main-bg">
                        <div className="big-title">
                            <div className="title-center">
                                <h1>{pageInfo.title}</h1>
                                <p>{pageInfo.subtitle}</p>
                            </div>
                        </div>
                    </section>
                    <section className="main-bg space">
                        <div className="blog-tabs">
                            <div className="content">
                                {this.state.tableRows.map((item, index) => (
                                    <div itemScope className="blog-one pb-60" key={index}>
                                        <Container fluid>
                                            <Row>
                                                <Col className="dynamic-img" xs={12} md={4}>
                                                    <Slider {...settings}>
                                                        {/* <div key={index}>
                                                            <img
                                                                src={IMG_URL + item.banner}
                                                                className="bg blog-thumb"
                                                                alt={item.slug}
                                                            />
                                                        </div> */}
                                                        {JSON.parse(item.slider).map((slide, j) => {
                                                            return (
                                                                <div key={index + j}>
                                                                    <img src={IMG_URL + slide} alt="" />
                                                                </div>
                                                            );
                                                        })}
                                                    </Slider>
                                                </Col>
                                                <Col xs={12} md={8}>
                                                    <div>
                                                        <div className="news-title">
                                                            <a href={'/projects/' + item.slug}><h2>{item.name}</h2></a>
                                                        </div>
                                                    </div>
                                                   {/* <div>
                                                        <h6>
                                                            <Moment format="MMMM D, Y">
                                                                {item.created_at}
                                                            </Moment>
                                                        </h6>
                                                    </div>*/}
                                                    <div
                                                        className="read-more-wrapper"
                                                        ref={node => (this.wrapper = node)}
                                                    >
                                                        <p>
                                                            {item.summary}
                                                        </p>
                                                    </div>

                                                    <div className="learn-more-container">
                                                        <a
                                                            href={item.external_link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {/* <GlcButton
                                                                    myStyle="news-learn-more"
                                                                    text={t("Learn More")}
                                                                /> */}
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
    }
}

export default withTranslation("common")(Projects);
