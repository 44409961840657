import React from 'react';

class ReadMooreHtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            charLimit: props.charLimit
        };
        this.initialState = this.state;
    }

    getReadMoreContent() {
        const {charLimit} = this.state;
        const {readMoreText, readLessText, text} = this.props;
        if (text.length > charLimit) {
            return (
                <span className='short-text'>
        <span>{new DOMParser()
            .parseFromString(text, "text/html")
            .documentElement.textContent.substr(0, charLimit)}</span>

          <span
              className='readMoreText'
              style={{color: '#b9b9bdd6' , cursor: 'pointer'}}
              role='presentation'
              onClick={this.showLongText.bind(this)}
          >
                  {readMoreText}
                  </span>
                  </span>
            );
        } else if (text.length < charLimit) {
            return <span className='short-text' dangerouslySetInnerHTML={{__html: text}}/>
        }
        return (
            <span className='short-text'>
                  <span dangerouslySetInnerHTML={{__html: text}}/>

                  <span
                      className='readMoreText'
                      style={{color: '#b9b9bdd6', cursor: 'pointer'}}
                      role='presentation'
                      onClick={this.showShortText.bind(this)}
                  >
              {readLessText}
                  </span>
                  </span>
        );
    }

    showLongText() {
        const {text} = this.props;
        this.setState({charLimit: text.length});
        this.getReadMoreContent();
    }

    showShortText() {
        this.setState(this.initialState);
        this.getReadMoreContent();
    }

    render() {
        return <div>{this.getReadMoreContent()}</div>;
    }
}
ReadMooreHtml.defaultProps = {
    charLimit: 310,
    readMoreText: '...Read more',
    readLessText: ''
};

export default ReadMooreHtml;