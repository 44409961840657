import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import ReactHtmlParser from "react-html-parser";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import Link from "../../components/common/link/link";
import "./Inspiration.scss";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import $ from "jquery";

$(document).ready(function() {
  $(document).on("contextmenu", ".dynamic-img", function(e) {
    return false;
  });
});

class Inspiration extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoaded: false,
      inspirationData: {}
    };
  }

  componentDidMount() {
    var thisVar = this;
    this._isMounted = true;
    AxiosInstance.get("api/page/inspiration").then(function(response) {
      setTimeout(() => {
        document.getElementById("main-head").scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest"
        });
      }, 500);
      if (thisVar._isMounted) {
        thisVar.setState({
          isLoaded: true,
          inspirationData: response.data
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  red = () => {
    this.setState({ flowersColor: "red" });
  };
  blue = () => {
    this.setState({ flowersColor: "blue" });
  };
  yellow = () => {
    this.setState({ flowersColor: "yellow" });
  };
  white = () => {
    this.setState({ flowersColor: "white" });
  };

  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    let result = [];
    if (tempDiv.innerHTML.includes("<p")) {
      tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));
    } else {
      result.push(html);
    }
    let result2 = result.splice(0, 1)[0];
    let temp = result2.split("");
    if (temp[0] === ".") {
      temp.splice(0, 1);
      temp.push(".");
      let result3 = temp.join("");
      return result3;
    } else {
      return result2;
    }
  };
  render() {
    const { isLoaded, inspirationData } = this.state;
    const { t } = this.props;
    if (!isLoaded) {
      return <Loader />;
    } else {
      return (
        <div className="insp">
          <Helmet>
            <title>GLC Paints | Inspiration</title>
            <title>{inspirationData.seo_title}</title>
            <meta
              name="description"
              content={t(
                "Our color experts at GLC have selected some images to guide you in your color selection process"
              )}
            />
            <meta name="keywords" content={t(inspirationData.meta_keywords)} />
            <meta property="og:title" content={inspirationData.seo_title} />
            <meta
              property="og:description"
              content={inspirationData.seo_title}
            />
            <meta
              property="og:image"
              content={IMG_URL + inspirationData.socialmedia_image}
            />
          </Helmet>
          <div className=" insp-full-img">
            <div className="insp-page-title">
              <h1>{this.state.inspirationData.title}</h1>
              <p>{this.state.inspirationData.subtitle}</p>
            </div>
            <img
              src={require("../../assets/img/inspiration/header/bg.jpg")}
              className="bg"
              alt={t("Multi Color wall")}
            />
            <img
              src={require("../../assets/img/inspiration/header/flowers.png")}
              className="flowers"
              alt={t("Colorful flowers")}
            />
            <img
              src={require("../../assets/img/inspiration/header/lamp3.png")}
              className="lamp3"
              alt={t("Hanging lamp")}
            />
            <img
              src={require("../../assets/img/inspiration/header/vase.png")}
              className="vase"
              alt={t("A Vase")}
            />
            <img
              src={require("../../assets/img/inspiration/header/white-rose.png")}
              className={
                "white-rose " +
                (this.state.flowersColor === "white" ? "onTop" : "")
              }
              alt={t("White flowers")}
            />
            <img
              src={require("../../assets/img/inspiration/header/red-rose.png")}
              className={this.state.flowersColor === "red" ? "onTop" : ""}
              alt={t("Red flowers")}
            />
            <img
              src={require("../../assets/img/inspiration/header/blue-rose.png")}
              className={this.state.flowersColor === "blue" ? "onTop" : ""}
              alt={t("Blue flowers")}
            />
            <img
              src={require("../../assets/img/inspiration/header/yellow-rose.png")}
              className={this.state.flowersColor === "yellow" ? "onTop" : ""}
              alt={t("Yellow flowers")}
            />
            <img
              src={require("../../assets/img/inspiration/header/selector-full.png")}
              className="selector-full"
              alt={t("Selector for choosing the flowers' colors")}
            />
            <div className="selectors">
              <span className="red-selector" onClick={this.red}></span>
              <span className="yellow-selector" onClick={this.yellow}></span>
              <span className="blue-selector" onClick={this.blue}></span>
              <span className="white-selector" onClick={this.white}></span>
            </div>
          </div>
          <div className="insp-content">
            <Container>
              <Row>
                <Col className="inspcontentheader">
                  {i18next.language === "ar" ? (
                    <p>
                      {this.extractParagraphTexts(
                        this.state.inspirationData.subtext_1
                      )}
                    </p>
                  ) : (
                    ReactHtmlParser(this.state.inspirationData.subtext_1)
                  )}
                </Col>
              </Row>
            </Container>
          </div>
          <Container fluid className="inspiration-boxx space">
            <div className="dynamic-img scale">
              <Link to="/inspiration/interior">
                <img
                  src={IMG_URL + this.state.inspirationData.interior_image}
                  className="bg"
                  alt={t("Green wall with vintage furniture")}
                />
                <span className="insp-overlay-bg"></span>
                <div className="scale-content">
                  <h2>{this.state.inspirationData.interior_text}</h2>
                  <p>{this.state.inspirationData.interior_subtext}</p>
                </div>
              </Link>
            </div>
            <div className="dynamic-img scale">
              <Link to="/inspiration/exterior">
                <img
                  src={IMG_URL + this.state.inspirationData.exterior_image}
                  className="bg"
                  alt={t("Modern silver building with white chairs")}
                />
                <span className="insp-overlay-bg"></span>
                <div className="scale-content">
                  <h2>{this.state.inspirationData.exterior_text}</h2>
                  <p>{this.state.inspirationData.exterior_subtext}</p>
                </div>
              </Link>
            </div>
          </Container>
        </div>
      );
    }
  }
}

export default withTranslation("common")(Inspiration);
