import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import GlcButton from "../../components/common/Buttons/Button";
//import ReadMore from "./ReadMore";
import ReadMore from "./ReadMooreHtml";
import Moment from "react-moment";
import "./News.scss";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Slider from "react-slick";

$(document).ready(function() {
  $(document).on("contextmenu", ".dynamic-img", function(e) {
    return false;
  });
});
class News extends Component {
  executeOnClick(isExpanded) {}
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tableRows: {},
      pageInfo: {},
      blogs: Array.from({ length: 5 }),
      hasMore: true
    };
  }
  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const result = [];
    if (tempDiv.innerHTML.includes("<p")) {
      tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));
    }
    let result2 = result.splice(0, 1)[0];
    let temp = result2.split("");
    if (temp[0] === ".") {
      temp.splice(0, 1);
      temp.push(".");
      let result3 = temp.join("");
      return result3;
    } else {
      return result2;
    }
  };
  componentDidMount() {
    this._isMounted = true;
    const thisVar = this;
    AxiosInstance.get("api/page/news").then(function(response) {
      document.getElementById("main-head").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
      if (thisVar._isMounted) {
        thisVar.setState({ pageInfo: response.data });
      }
    });

    const urlParams = new URLSearchParams(window.location.search);
    const preview = urlParams.get('preview') == null ? false : urlParams.get('preview');
    const news_api_endpoint = preview ?  "api/news?preview=true"  : "api/news"
    AxiosInstance.get( news_api_endpoint ).then(function(response) {
      if (thisVar._isMounted) {
        thisVar.setState({
          isLoaded: true,
          tableRows: response.data
        });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    let result = [];
    if (tempDiv.innerHTML.includes("<p")) {
      tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));
    } else {
      result.push(html);
    }
    let result2 = result.splice(0, 1)[0];
    let temp = result2.split("");
    if (temp[0] === ".") {
      temp.splice(0, 1);
      temp.push(".");
      let result3 = temp.join("");
      return result3;
    } else {
      return result2;
    }
  };
  fetchMoreData = () => {
    if (this.state.blogs.length >= 10) {
      this.setState({ hasMore: false });
      return;
    }
    setTimeout(() => {
      this.setState({
        blogs: this.state.blogs.concat(Array.from({ length: 1 }))
      });
    }, 500);
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };
    const { t } = this.props;
    const { isLoaded, pageInfo } = this.state;
    if (!isLoaded) {
      return <Loader />;
    } else {
      return (
        <div>
          <Helmet>
            <title>GLC Paints | News</title>
            <meta
              name="keywords"
              content={t("News") + t("GLC Paints") + t("Trends")}
            />
            <meta
              name="description"
              content={t(
                "LEARN MORE ABOUT YOUR FAVORITE PAINT BRAND ,GLC Paints"
              )}
            />
          </Helmet>
          <section className="main-bg">
            <div className="big-title">
              <div className="title-center">
                <h1>{pageInfo.title}</h1>
                <p>{pageInfo.subtitle}</p>
              </div>
            </div>
          </section>
          <section className="main-bg space">
            <div className="blog-tabs">
              <div className="content">
                {this.state.tableRows.map((item, index) => (
                  <div  itemScope className="blog-one pb-60" key={index}>
                    <Container fluid>
                      <Row>
                        <Col className="dynamic-img" xs={12} md={4}>
                         {/* <img
                            src={IMG_URL + item.image}
                            className="bg blog-thumb"
                            alt={item.slug}
                          />*/}

                          <Slider {...settings}>
                            {/* <div key={index}>
                                                            <img
                                                                src={IMG_URL + item.banner}
                                                                className="bg blog-thumb"
                                                                alt={item.slug}
                                                            />
                                                        </div> */}
                            { [  item.image, ...(item.gallery || [])  ].map((slide, j) => {
                              return (
                                  <div key={index + j}>
                                    <img  className="bg blog-thumb"  src={IMG_URL + slide} alt="" />
                                  </div>
                              );
                            })}
                          </Slider>
                        </Col>
                        <Col xs={12} md={8}>
                          <div>
                            <div className="news-title">
                              <h2>{item.title}</h2>
                            </div>
                          </div>
                          <div>
                            <h6>
                              <Moment format="MMMM D, Y">
                                {item.created_at}
                              </Moment>
                            </h6>
                          </div>
                          <div>
                            <div
                              className="read-more-wrapper"
                              ref={node => (this.wrapper = node)}
                            >
                              <p></p>

                              <ReadMore
                                text={item.body}
                                numberOfLines={4}
                                lineHeight={1.2}
                                showLessButton={true}
                              />
                            </div>
                          </div>

                          {item.external_link != null ? (
                            <div>
                              <div className="learn-more-container">
                                <a
                                  href={item.external_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <GlcButton
                                    myStyle="news-learn-more"
                                    text={t("Learn More")}
                                  />
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
}

export default withTranslation("common")(News);
