import React, { Component } from "react";
import Slider from "./Slider";
import Content from "./Content";
import ChangeBg from "./ChangeBg/ChangeBg";
import AxiosInstance, { Loader } from "../../const/const";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";

const imgURL = process.env.REACT_APP_IMG_URL;


class Homepage extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoaded: false
    };
  }

  componentDidMount() {
    const thisVar = this;
    thisVar._isMounted = true;
    AxiosInstance.get("api/page/home").then(function(response) {
      document.getElementById("main-head").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
      if (thisVar._isMounted) {
        thisVar.setState(
          {
            data: response.data
          },
          () => thisVar.setState({ isLoaded: true })
        );
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { isLoaded, data } = this.state;
    const { t } = this.props;
    if (!isLoaded) {
      return <Loader />;
    }
    if (isLoaded)
      return (
        <div>
          <Helmet>
            <title>GLC Paints | Home</title>
            <title>{data.seo_title}</title>
            <meta property="og:title" content={data.seo_title} />
            <meta
              property="og:image"
              content={imgURL + data.socialmedia_image}
            />

            <meta
              name="twitter:card"
              content={imgURL + data.socialmedia_image}
            />
            <meta name="twitter:site" content="http://www.glcpaints.com" />
            <meta name="twitter:title" content="GLC Paints" />
            <meta name="twitter:creator" content="GLC Paints" />
            <meta
              name="twitter:image:src"
              content={imgURL + data.socialmedia_image}
            />
            <meta
              name="twitter:image"
              content={imgURL + data.socialmedia_image}
            />

            <meta name="description" content={t("GLC Paints")} />
            <meta
              name="keywords"
              content={
                t("Colors") +
                " " +
                t("Inspiration") +
                " " +
                t("Trends") +
                " " +
                t("Decorative Finishes")
              }
            />
          </Helmet>
          <Slider data={data} />
          <ChangeBg data={data} />
          <Content data={data} />
        </div>
      );
    else {
      return <div></div>;
    }
  }
}

export default withTranslation("common")(Homepage);
