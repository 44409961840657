import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import "./ChangeBg.scss";
import Link from "../../../components/common/link/link";
import { IMG_URL } from "../../../const/const";

class ChangeBg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tableRows: props.data,
      imageId: 2
    };
  }

  componentDidMount() {
    this.Blue();
  }

  Yellow = () => {
    this.setState({
      imageId: 0,
      imageURL: this.state.tableRows.dynamic_image.yellow,
      imageURL_m: this.state.tableRows.dynamic_image.yellow_mobile,
      colorName: "Yellow",
      content: <h4> {this.state.tableRows.yellow_psycology_text} </h4>
    });
  };
  Gray = () => {
    this.setState({
      imageId: 1,
      imageURL: this.state.tableRows.dynamic_image.grey,
      imageURL_m: this.state.tableRows.dynamic_image.grey_mobile,
      colorName: "Grey",

      content: <h4> {this.state.tableRows.black_psycology_text} </h4>
    });
  };
  Blue = () => {
    const { t } = this.props;
    this.setState({
      imageId: 2,
      imageURL: this.state.tableRows.dynamic_image.blue,
      imageURL_m: this.state.tableRows.dynamic_image.blue_mobile,
      colorName: "Blue",
      content: <h4>{t(this.state.tableRows.blue_psycology_text)} </h4>
    });
  };
  Cream = () => {
    this.setState({
      imageId: 3,
      imageURL: this.state.tableRows.dynamic_image.white,
      imageURL_m: this.state.tableRows.dynamic_image.white_mobile,
      colorName: "White",
      content: <h4> {this.state.tableRows.white_psycology_text} </h4>
    });
  };
  Red = () => {
    this.setState({
      imageId: 4,
      imageURL: this.state.tableRows.dynamic_image.red,
      imageURL_m: this.state.tableRows.dynamic_image.red_mobile,
      colorName: "Red",
      content: <h4> {this.state.tableRows.red_psycology_text} </h4>
    });
  };
  Green = () => {
    this.setState({
      imageId: 5,
      imageURL: this.state.tableRows.dynamic_image.green,
      imageURL_m: this.state.tableRows.dynamic_image.green_mobile,
      colorName: "Green",
      content: <h4> {this.state.tableRows.green_psycology_text} </h4>
    });
  };

  render() {
    const { t } = this.props;
    return (
      <section className="change-bg">
        <div className="grid-one-column">
          <div className="box-border">
            <img
              src={IMG_URL + this.state.imageURL}
              className="bg img-animation desk flip-img"
              alt={t("A Sofa and a cupboard with a colorful wall")}
            />
            <img
              src={IMG_URL + this.state.imageURL_m}
              className="bg img-animation mob flip-img"
              alt={t("A Sofa and a cupboard with a colorful wall")}
            />
            <div
              className="frame-img"
              style={{
                backgroundImage: "url(../../assets/img/homepage/colors.png)"
              }}
            >
              <div onClick={this.Yellow} className="yellow-div">
                {" "}
              </div>
              <div onClick={this.Blue} className="blue-div">
                {" "}
              </div>
              <div onClick={this.Gray} className="gray-div">
                {" "}
              </div>
              <div onClick={this.Red} className="red-div">
                {" "}
              </div>
              <div onClick={this.Cream} className="white-div">
                {" "}
              </div>
              <div onClick={this.Green} className="green-div">
                {" "}
              </div>
              <div onClick={this.Red} className="red-div">
                {" "}
              </div>
            </div>
            <span className="homepage-overlay-bg"> </span>
            <Link to="/colors/color-psychology">
              <div className="box-border-content">
                <Container
                  fluid
                  style={{
                    color: this.state.colorName === "White" ? "#222" : "#fff"
                  }}
                >
                  <Row className="changebg-grid">
                    <Col xs="12" sm="12" className="box-border-text">
                      <Row className="row-1"> {t("THE PSYCHOLOGY OF")} </Row>
                      <Row className="row-2"> {t("COLORS")} </Row>
                      <Row className="row-3"> {t(this.state.colorName)} </Row>
                      <Row className="row-5"> {this.state.content} </Row>
                      <Row className="row-6"> {t("CHECK ALL COLORS")} </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("common")(ChangeBg);
