import React, { useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import RouteLayout from "./layouts/RouteLayout";
import Homepage from "./pages/homepage/HomePage";
import Ambiance from "./pages/ambiance/Ambiance";
import AmbItems from "./pages/ambiance/AmbItems";
import Colors from "./pages/Colors/Colors";
import ColorItems from "./pages/Colors/ColorItems";
import ColorPsychology from "./pages/Colors/ColorPsychology/ColorPsychology";
import Trends from "./pages/Colors/Trends/Trends";
import Inspiration from "./pages/inspiration/Inspiration";
import InspirationContent from "./pages/inspiration/InspirationContent";
import ProductDetail from "./pages/products/product-detail";
import ProductList from "./pages/products/product-list";
import Stores from "./pages/Stores/Stores";
import AboutUs from "./pages/aboutUs/AboutUs";
import News from "./pages/News/News";
import Project from "./pages/projects/Project";
import Projects from "./pages/projects/Projects";
import Compare from "./pages/products/Compare";
import NotFound from "./pages/NotFound/NotFound";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import i18n from "i18next";
import "./App.scss";
import "./responsive.scss";
import "./i18n.scss";
import PaintCalculator from "./pages/products/Calculator/Calculator";
import Search from "./pages/Search/Search";
import { Helmet } from "react-helmet";
import "./pages/products/Products.scss";



// 1- home page (SEO service /page/home) UI_SEO OK
// 2- ambiance UI_SEO OK
// 3- ambiance/slug (SEO service /ambience/slug) UI_SEO OK
// 4- colors (SEO service /page/colors) UI_SEO OK

// 5- colors/trend/slug (SEO)
// 6- colors/slug (SEO)
// 7- colors/color-psychology (SEO)

// 8- inspiration UI_SEO OK
// 9- inspiration/slug/tab (SEO service /inspirations/slug) UI_SEO OK

// 10- products/slug/tab/productId (SEO)
// 11- products/paint-calculator (SEO)
// 12- stores (SEO)
// 13- aboutus (SEO)
// 14- news/slug (SEO)
// 15- search (SEO)

function App(props) {
  const [direction, setDirection] = useState(
    i18n.language === "en" ? "ltr" : "rtl"
  );
  const url = new URL(window.location).pathname;
  const lang = url.split("/")[1] ;

  const [lng, setLang] = useState(lang === "en" ? "en" : "ar");
  i18n.on("languageChanged", function (lng) {
    setDirection(lng === "en" ? "ltr" : "rtl");
    setLang(lng === "en" ? "en" : "ar");
  });

  return (
    <div className={"App " + direction} lang={lng} dir={direction}>
      <Helmet>
        <title>{"GLC Paints"}</title>
      </Helmet>
      <Helmet htmlAttributes={{ lang: lng }} />
      <Router>
        <Switch>
          <RouteLayout exact path="/:lang(en|ar)/" component={Homepage} key={() => Math.random()} />

          <RouteLayout exact path="/:lang(en|ar)/ambiance" component={Ambiance} />
          <RouteLayout exact path="/:lang(en|ar)/ambiance/:slug" component={AmbItems} />

          <RouteLayout exact path="/:lang(en|ar)/projects/list" component={Projects} />
          <RouteLayout exact path="/:lang(en|ar)/projects/:projectId" component={Project} />

          <RouteLayout exact path="/:lang(en|ar)/colors" component={Colors} />
          <RouteLayout exact path="/:lang(en|ar)/colors/color-psychology" component={ColorPsychology} />
          <RouteLayout exact path="/:lang(en|ar)/colors/trends/:slug" component={Trends} />
          <RouteLayout exact path="/:lang(en|ar)/colors/:slug" component={ColorItems} />
          <RouteLayout exact path="/:lang(en|ar)/colors/:slug/:color?" component={ColorItems} />

          <RouteLayout exact path="/:lang(en|ar)/inspiration" component={Inspiration} />
          <RouteLayout exact path="/:lang(en|ar)/inspiration/:slug/:tabTitle?" component={InspirationContent} />

          <RouteLayout exact path="/:lang(en|ar)/products/detail/:productId" component={ProductDetail} />
          <RouteLayout exact path="/:lang(en|ar)/products/list" component={ProductList} />
          <RouteLayout exact path="/:lang(en|ar)/products/compare/:p1?/:p2?/:p3" component={Compare} />
          {/* <RouteLayout exact path="/:lang(en|ar)/products-intro" component={ProductsIntro} /> */}
          <RouteLayout exact path="/:lang(en|ar)/products/paint-calculator" component={PaintCalculator} />
          {/* <RouteLayout exact path="/:lang(en|ar)/products/:typeTab/:familyTab?/:productName?" component={Products} /> */}

          <RouteLayout exact path="/:lang(en|ar)/stores" component={Stores} />
          <RouteLayout exact path="/:lang(en|ar)/about-us" component={AboutUs} />
          <RouteLayout exact path="/:lang(en|ar)/news" component={News} />
          <RouteLayout exact path="/:lang(en|ar)/search" component={Search} />
          <RouteLayout exact path="/:lang(en|ar)/privacy-policy" component={PrivacyPolicy} />
          <RouteLayout exact path="/:lang(en|ar)/terms-of-use" component={TermsOfUse} />
          
          <RouteLayout component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
