import React, { Component } from "react";
import Link from "../../components/common/link/link";
import { Carousel, CarouselItem, CarouselControl } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Slider.scss";
import { withTranslation } from "react-i18next";

const imgURL = process.env.REACT_APP_IMG_URL;

class MainSlider extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      activeIndex: 0,
      loaded: false,
      items: [
        
        {
          src: "../../assets/img/homepage/slider/1/bg.jpg",
          srcOne: "../../assets/img/homepage/slider/1/obj1.png",
          srcTwo: "../../assets/img/homepage/slider/1/obj2.png",
          srcM: "../../assets/img/homepage/slider/1/bg-m.jpg",
          srcOneM: "../../assets/img/homepage/slider/1/obj1-m.png",
          srcTwoM: "../../assets/img/homepage/slider/1/obj2-m.png",
          altText: t("A colorful wall"),
          altTextOne: t("A light pink chair"),
          altTextTwo: t("A vase on the top of a wooden stand"),
          caption: "ambiance",
          pageLink: "/ambiance"
        },
        {
          src: "../../assets/img/homepage/slider/2/bg.jpg",
          srcOne: "../../assets/img/homepage/slider/2/obj1.png",
          srcTwo: "../../assets/img/homepage/slider/2/obj2.png",
          srcM: "../../assets/img/homepage/slider/2/bg-m.jpg",
          srcOneM: "../../assets/img/homepage/slider/2/obj1-m.png",
          srcTwoM: "../../assets/img/homepage/slider/2/obj2-m.png",
          altText: t("A colorful wall"),
          altTextOne: t("A modern wooden furniture"),
          altTextTwo: t("Hanging lamp"),
          caption: "trends",
          pageLink: "/colors"
        },
        {
          src: "../../assets/img/homepage/slider/3/bg.jpg",
          srcOne: "../../assets/img/homepage/slider/3/obj1.png",
          srcTwo: "../../assets/img/homepage/slider/3/obj2.png",
          srcM: "../../assets/img/homepage/slider/3/bg-m.jpg",
          srcOneM: "../../assets/img/homepage/slider/3/obj1-m.png",
          srcTwoM: "../../assets/img/homepage/slider/3/obj2-m.png",
          altText: t("A colorful wall"),
          altTextOne: t("A modern wooden furniture"),
          altTextTwo: t("Hanging lamp"),
          caption: "inspiration",
          pageLink: "/inspiration"
        },
        ...props.data.carousel_images.map(item => {
          return {
            image: imgURL + item.image,
            image_mobile: imgURL + item.image_mobile,
            title: item.title,
            substitle: item.substitle,
            subtitle2: item.subtitle2,
            caption: item.title,
            link: item.link,
            pageLink: item.pageLink
          };
        })
      ]
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 500);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    const { items } = this.state;
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    const { items } = this.state;
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex, items, loaded } = this.state;
    const { t } = this.props;
    const someItems = items.slice(3);
    const slides = someItems.map((item, index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={index + 3}
        >
          <a href={item.link}>
            <div className="slider-vh-left">
              <h1>{item.title}</h1>
              <p className="descBig">{item.substitle}</p>
              <p className="descSmall">{item.subtitle2}</p>
            </div>
            {item.image.includes(".mp4") ?
              <video className="slider-video" muted autoPlay loop>
                <source src={item.image} type="video/mp4"/>
                Your browser does not support the video tag.
              </video> :
              <img
                src={item.image}
                alt={item.caption}
                className="img-fluid back"
              />
            }
            
          </a>

          <div className="mob">
            {item.image.includes(".mp4") ?
              <video className="slider-video" muted autoPlay loop>
                <source src={item.image} type="video/mp4"/>
                Your browser does not support the video tag.
              </video> :
              <img
                src={item.image_mobile}
                alt={item.caption}
                className="full-img back"
              />
            }
          </div>
        </CarouselItem>
      );
    });

    slides.unshift(
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={2}>
        <Link to={items[2].pageLink}>
          <div className="slider-vh-left">
            <h1>{t("Inspiration")}</h1>
            <p className="descBig">{t("We know color")}</p>
            <p className="descSmall">{t("Like no one else")}</p>
          </div>
          <div className="desktop">
            <img
              src={items[2].src}
              alt={items[2].altText}
              className="full-img back scaled"
            />
            <img
              src={items[2].srcOne}
              alt={items[2].altTextOne}
              className="full-img animation lamb slider-obj"
            />
            <img
              src={items[2].srcTwo}
              alt={items[2].altTextTwo}
              className="full-img animation room2 slider-obj"
            />
          </div>
        </Link>
        <div className="mob">
          <img
            src={items[2].srcM}
            alt={items[2].altText}
            className="full-img back"
          />
          <img
            src={items[2].srcOneM}
            alt={items[2].altTextOne}
            className="full-img animation lamb slider-obj"
          />
          <img
            src={items[2].srcTwoM}
            alt={items[2].altTextTwo}
            className="full-img animation room2 slider-obj"
          />
        </div>
      </CarouselItem>
    );

    slides.unshift(
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={1}>
        <Link to={items[1].pageLink}>
          <div className="slider-vh-left">
            <h1>{t("Color Influences")}</h1>
            <p className="descBig">{t("Be in the moment")}</p>
            <p className="descSmall">{t("Follow the latest trends")}</p>
          </div>
          <div className="desktop">
            <img
              src={items[1].src}
              alt={items[1].altText}
              className="full-img back scaled"
            />
            <img
              src={items[1].srcOne}
              alt={items[1].altTextOne}
              className="full-img animation room2 slider-obj"
            />
            <img
              src={items[1].srcTwo}
              alt={items[1].altTextTwo}
              className="full-img animation lamb slider-obj"
            />
          </div>
        </Link>
        <div className="mob">
          <img
            src={items[1].srcM}
            alt={items[1].altText}
            className="full-img back"
          />
          <img
            src={items[1].srcOneM}
            alt={items[1].altTextOne}
            className="full-img animation room3 slider-obj"
          />
          <img
            src={items[1].srcTwoM}
            alt={items[1].altTextTwo}
            className="full-img animation lamb slider-obj"
          />
        </div>
      </CarouselItem>
    );

    slides.unshift(
      <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={0}>
        <Link to={items[0].pageLink}>
          <div className="slider-vh-left">
            <h1>{t("AMBIENCE")}</h1>
            <p className={loaded ? "descBig" : "descBig moved"}>
              {t("Special Effects")}
            </p>
            <p className="descSmall">{t("Luxurious decorative finishes")}</p>
          </div>
          <div className="desktop">
            <img
              src={items[0].src}
              alt={items[0].altText}
              className={loaded ? "back slider-bg img-wide" : "back img-wide"}
            />
            <img
              src={items[0].srcOne}
              alt={items[0].altTextOne}
              className={
                loaded
                  ? "img-fluid animation slider-obj chair"
                  : "img-fluid animation chair-100 slider-obj"
              }
            />
            <img
              src={items[0].srcTwo}
              alt={items[0].altTextTwo}
              className={
                loaded
                  ? "img-fluid animation slider-obj table"
                  : "img-fluid animation table-33 slider-obj"
              }
            />
          </div>
        </Link>
        <div className="mob">
          <img
            src={items[0].srcM}
            alt={items[0].altText}
            className={loaded ? "back slider-bg img-wide" : "back img-wide"}
          />
          <img
            src={items[0].srcOneM}
            alt={items[0].altTextOne}
            className={
              loaded
                ? "img-fluid animation slider-obj chair"
                : "img-fluid animation chair-100 slider-obj"
            }
          />
          <img
            src={items[0].srcTwoM}
            alt={items[0].altTextTwo}
            className={
              loaded
                ? "img-fluid animation slider-obj table"
                : "img-fluid animation table-33 slider-obj"
            }
          />
        </div>
      </CarouselItem>
    );
    return (
      <div className="homepage-carousel">
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          keyboard={true}
          pause={false}
          ride="carousel"
          interval="10000"
          slide={true}
          className="carousel-fade"
        >
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </div>
    );
  }
}

export default withTranslation("common")(MainSlider);
