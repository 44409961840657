import React, { Component } from "react";
import AxiosInstance from "../../const/const";
import makeCarousel from "react-reveal/makeCarousel";
import Slide from "react-reveal/Slide";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Row } from "reactstrap";
import Fade from "react-reveal/Fade";
import "./AboutUsSlider.scss";
import i18n from "i18next";

const width = "100%";
const Container = styled.div`
    padding: 0;
    width: 100%;
    z-index:100
}
`;
const SlideComponent = styled.div`
  position: relative;
  overflow: hidden;
  margin-left: 0;
  width: ${width};
  z-index: 100;
`;

const Dot = styled.span`
  font-size: 1.5em;
  cursor: pointer;
  text-shadow: 1px 1px 1px #fff;
  user-select: none;
  color: #fff;
`;
const Dots = styled.span`
  position: absolute;
  top: 40%;
  text-align: center;
  width: 96%;
  z-index: 100;
`;
const CarouselUI = ({ position, total, handleClick, children }) => {
  return (
    <Container>
      <SlideComponent className="slide-component">{children}</SlideComponent>

      <Dots className="slider-dots">
        {Array(...Array(total)).map((val, index) => (
          <Dot key={index} onClick={handleClick} data-position={index}>
            {index === position ? "● " : "○ "}
          </Dot>
        ))}
      </Dots>
    </Container>
  );
};
const Carousel = makeCarousel(CarouselUI);

class aboutUsSlider extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      tableRows: []
    };
  }

  componentDidMount() {
    var thisVar = this;
    thisVar._isMounted = true;
    AxiosInstance.get("api/about").then(function(response) {
      if (thisVar._isMounted) {
        thisVar.setState({
          isLoaded: true,
          tableRows: response.data
        });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const result = [];
    tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));

    return result;
  };
  renderCarousel = () => {
    let slides = [];
    const enSlides = [
      `<p>Our journey to success began in 1979 </p><p> when our founder, Abdel Fattah Ibrahim El Hout, established the first factory in the Arabian Gulf, in the Kingdom of Saudi Arabia.</p>
    <p>In 1991, we took the decision to expand our businesses in Lebanon, a more established, firmer and more focused expansion.</p>
    <p> In 1994, we implemented the first milestone and that is to enter the African continent through the Egyptian market.</p> `,
      `<p>The export business to Egypt flourished and diversified rapidly, so we founded our first factory in Cairo in Shobra Al Kheyma in 1997.</p> 
      <p>This plant was a pivotal point in our history. The high quality of our products and the growing demand in the local and African markets led to further development and modernization of our factories in 2005. We expanded our production lines in response to the increasing demand, especially that Egypt- the hub for investment in the African market.</p>`,
      `<p>Since we believe that any successful work must be integrated, we initiated our new strategy and established a tin packaging factory to facilitate the manufacturing process and achieve self-sufficiency, followed by a plastic packaging factory.</p> 
      <p>Despite the political and social turbulence, our faith in Egypt and its people was never shaken. Still betting on the Egyptian market, we laid the foundation to build one of the largest construction paints factories in the country in 2012.</p> `,
      `<p>The factory was inaugurated in 2016 and set a milestone in terms of deploying the latest and best-in-class manufacturing technologies, not only in Egypt but in the whole of the Middle East and Africa region.</p> 
      <p>Today, GLC is considered one of the largest paint manufacturers in that region. Our annual production volume is over 50 million liters of high quality paints that offer full solutions to all market segments; we export to 12 countries and employ 1500 highly qualified professionals.</p> `
    ];
    const arSlides = [
      `<p>بدأت مسيرتنا عام 1979 حين بدأ المؤسس عبد الفتاح إبراهيم الحوت أول محطة نجاح في عالم الدهانات في الخليج العربي في المملكة العربية السعودية، من هنا بدأت قصة نجاح تلو الأخرى.</p>
    <p>عام 1991 كان قرار التوسع في لبنان، توسعات أقوى تأثيراً وأكثر ثباتاً وأكثر تركيزاً على أهدافنا. بحلول عام 1994 بدأنا تحقيق أول أهدافنا وهي الدخول إلى القارة السمراء من خلال جمهورية مصر العربية.</p>`,
      `<p>توسعت الصادرات إلى مصر وتنوعت المنتجات فكان القرار بتأسيس أولى مصانعنا في القاهرة بمنطقة شبرا الخيمة
  عام 1997 . هذا المصنع كان المحطة المفصلية في تاريخنا حيث أن جودة منتجاتنا والطلب المستمر عليها، في الأسواق المحلية والإفريقية، دفعنا إلى تطوير وتحديث مصانعنا عام 2005 وأقمنا توسيعات كبيرة لخطوط الإنتاج لمواكبة إحتياجات السوق وتلبية الطلب المتزايد، خصوصاً أن مصر تعتبر المركز الأساسي للتوسع والإستثمار في الأسواق الإفريقية.
  </p>`,
      `<p>وبما أننا نؤمن أن أي عمل ناجح مطلوب أن يكون متكامل إنطلقت طموحاتنا لأولى خطوات الفكر الجديد في إنشاء مصانع مغذية للدهانات وهي مصنع للعبوات الصفيح وتلاه مصنع العبوات البلاستيكية وذلك لتسهيل عملية التصنيع و تحقيق الإكتفاء الذاتي.
برغم كل التحديات السياسية والإجتماعية التي مرت علينا إلا أن إيماننا بمصر وبشعبها دفعنا إلى التحدي والمراهنة على السوق المصري، مراهنة كانت في محلها إذ أن مسيرة النجاح أدت إلى وضع حجر أساس عام 2012 لبناء واحداً من أكبر مصانع الدهانات الإنشائية في مصر.
</p>`,
      `<p>تم إفتتاح هذا المصنع عام 2016 وهو يعد علامة فارقة من حيث توظيف أحدث وأفضل تقنيات التصنيع ليس فقط في مصر إنما في الشرق الأوسط وأفريقيا.</p>
<p>تعد  GLC  اليوم من كبرى شركات تصنيع الدهانات في مصر وأفريقيا حيث يبلغ حجم إنتاجها أكثر من 50 مليون لتر سنوياً من منتجات الدهانات العالية الجودة والتي تقدم حلولاً شاملة لكافة قطاعات السوق، تصدر إلى 12 بلداً، وهي توظف 1500 من أكفئ الموظفين في إختصاصتهم.</p>`
    ];
    if (i18n.language === "en") {
      slides = enSlides;
    } else {
      slides = arSlides;
    }
    return (
      <div className="carousel-block">
        <Carousel defaultWait={0}>
          {slides.map((item, index) => (
            <Fade key={index}>
              <Slide>
                <div className="row">
                  <div className="aboutslidertxt col-12 col-lg-10 vertical-center">
                    <Row className="about-us-info-text pt-pb-20">
                      <div className="about-us-info-text">
                        {ReactHtmlParser(item)}
                      </div>
                    </Row>
                  </div>
                </div>
              </Slide>
            </Fade>
          ))}
        </Carousel>
      </div>
    );
  };

  render() {
    return (
      <div className="about-us-slider">
        {(() => {
          return this.renderCarousel();
        })()}
      </div>
    );
  }
}

export default aboutUsSlider;
