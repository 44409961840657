import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import ReactHtmlParser from "react-html-parser";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import i18next from "i18next";
import $ from "jquery";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";


$(document).ready(function() {
  $(document).on("contextmenu", ".dynamic-img", function(e) {
    return false;
  });
});
class AmbItems extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoaded: false,
      tableRows: [],
      brochureModal: false
    };
    this.toggleBrochureModal = this.toggleBrochureModal.bind(this);
  }

  toggleBrochureModal() {
    this.setState({
        brochureModal: !this.state.brochureModal
    });
}

  componentDidMount() {
    const thisVar = this;
    AxiosInstance.get("api/ambience/" + this.props.match.params.slug).then(
      function(response) {
        const data = response.data;
        for (var i = 0; i < data.length; i++) {
          data[i].video_url += "?rel=0";
        }
        document.getElementById("main-head").scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest"
        });
        thisVar.setState({
          isLoaded: true,
          tableRows: data
        });
      }
    );
  }
  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    let result = [];
    if (tempDiv.innerHTML.includes("<p")) {
      tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));
    } else {
      result.push(html);
    }
    let result2 = result.splice(0, 1)[0];
    let temp = result2.split("");
    if (temp[0] === ".") {
      temp.splice(0, 1);
      temp.push(".");
      let result3 = temp.join("");
      return result3;
    } else {
      return result2;
    }
  };

  extractListTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const result = [];
    tempDiv.querySelectorAll("span").forEach(e => result.push(e.textContent));

    return result;
  };
  renderDo() {
    const { t } = this.props;
    return this.state.tableRows.map((item, i) => {
      return (
        <div key={i}>
          <Helmet>
            <title>GLC Paints | {item.title}</title>
            <meta
              name="description"
              content={this.extractParagraphTexts(item.text1)}
            />
            <meta name="keywords" content={item.title + "-" + item.excerpt} />
            <meta property="og:title" content={item.seo_title} />
            <meta
              property="og:image"
              content={IMG_URL + item.socialmedia_image}
            />
          </Helmet>
          <div
            itemScope
            className="dynamic-img amb-item-bg"
            style={{ backgroundImage: `url(${IMG_URL + item.banner})` }}
          >
            <div className="amb-item-title">
              <h1 itemProp="name">{item.title}</h1>
              <p>{item.excerpt}</p>
            </div>
          </div>
          <div>
            <section className="amb-item-content pt-40 ">
              <div className="boxx ">
                <div className="content-full-screen scale-amb-item">
                  <div className="scale-content">
                    <p itemProp="description">
                      {this.extractParagraphTexts(item.text1)}
                    </p>
                  </div>
                </div>
                <div className="dynamic-img content-full-screen scale-amb-item-img">
                  <img
                    src={IMG_URL + item.image1}
                    className="bg blog-thumb"
                    alt={item.slug}
                  />
                  <span className="overlay-amb-item-bg"></span>
                </div>
              </div>
            </section>
            <section>
              <div className="boxx">
                <div className="boxxxx scale-amb-item-img">
                  <div>
                    <img
                      src={IMG_URL + item.image2}
                      className="dynamic-img bg amb-four"
                      alt={item.slug}
                    />
                  </div>
                  <div>
                    <img
                      src={IMG_URL + item.image3}
                      className="dynamic-img bg amb-four"
                      alt={item.slug}
                    />
                  </div>
                  <div>
                    <img
                      src={IMG_URL + item.image4}
                      className="dynamic-img bg amb-four"
                      alt={item.slug}
                    />
                  </div>
                  <div>
                    <img
                      src={IMG_URL + item.image5}
                      className="dynamic-img bg amb-four"
                      alt={item.slug}
                    />
                  </div>
                </div>
                <div className="scale-amb-item">
                  <div className="scale-content-left ambConent2  mob-space">
                    <div>
                      <p itemProp="description">
                        {" "}
                        {this.extractParagraphTexts(item.text2)}
                      </p>
                    </div>

                    <Modal size={'xl'} isOpen={this.state.brochureModal} toggle={this.toggleBrochureModal} className="image-modal">
                        <ModalHeader toggle={this.toggleBrochureModal}></ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: item.brochure_view_url }}></div>
                        </ModalBody>
                    </Modal>
                    <div
                      className={
                        "amb-brochure " +
                        (item.brochure_view_url
                          ? ""
                          : "hide1") +
                        " amb-brochure-mobile-off"
                      }
                    >
                      <h6>
                        {item.title}- {t("Brochure")}
                      </h6>
                      {item.brochure_view_url != null ? (
                        <div
                          style={{cursor: 'pointer'}}
                          className="pdfdownload cursor-pointer"
                          onClick={() => this.toggleBrochureModal()}
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require("../../assets/img/homepage/icons/pdf-view.svg")}
                            alt={t("View PDF Brochure")}
                          />
                          {t("VIEW")}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* {item.pdf_file != null ? (
                        <a
                          className="pdfdownload "
                          href={IMG_URL + item.pdf_file}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          {i18next.language === "ar" ? (
                            <img
                              className={
                                item.brochure_view_url === null
                                  ? "one-icon-ar"
                                  : ""
                              }
                              alt={t("Download PDF Brochure")}
                              src={require("../../assets/img/homepage/icons/pdf-download.svg")}
                            />
                          ) : (
                            <img
                              className={
                                item.brochure_view_url === null
                                  ? "one-icon-en"
                                  : ""
                              }
                              src={require("../../assets/img/homepage/icons/pdf-download.svg")}
                              alt={t("Download PDF Brochure")}
                            />
                          )}

                          {t("DOWNLOAD")}
                        </a>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="frame-yt space">
              <div className="boxx">
                <div className="scale-amb-item">
                  <div id="amb_Features" className="scale-content  mob-space">
                    <div className="header"> {t("Features")}:</div>
                    {item.text3.includes("span") ? (
                      <ul>
                        {this.extractListTexts(item.text3).map((e, index) => (
                          <li key={index}>{e}</li>
                        ))}
                      </ul>
                    ) : (
                      ReactHtmlParser(item.text3)
                    )}
                  </div>
                </div>
                <div className="scale-amb-item amb-brochure-mobile-on">
                  <div
                    className={
                      "amb-brochure " +
                      ( item.brochure_view_url
                        ? ""
                        : "hide1") +
                      "mob-space-brochure"
                    }
                  >
                    <h6>
                      {item.title}- {t("Brochure")}
                    </h6>
                    {  item.brochure_view_url != null ? (
                      <a
                        className="pdfdownload"
                        href={item.brochure_view_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require("../../assets/img/homepage/icons/pdf-view.svg")}
                          alt={t("View PDF Brochure")}
                        />
                        {t("VIEW")}
                      </a>
                    ) : (
                      ""
                    )}
                    {item.pdf_file != null ? (
                      <a
                        className="pdfdownload"
                        href={IMG_URL + item.pdf_file}
                        target="_blank"
                        download
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require("../../assets/img/homepage/icons/pdf-download.svg")}
                          alt={t("Download PDF Brochure")}
                        />
                        {t("DOWNLOAD")}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  className={
                    item.brochure_view_url === null && item.pdf_file === null
                      ? "no-icon"
                      : "with-icons"
                  }
                ></div>

                <div className="scale-amb-item">
                  <div className="frame-embed video-container-full">
                    <iframe
                      src={item.video_url.replace(
                        "https://www.youtube.com/watch?v=",
                        "https://www.youtube.com/embed/"
                      )}
                      height="400px"
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                      title="video"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    });
  }

  render() {
    const { isLoaded } = this.state;

    if (!isLoaded) {
      return <Loader />;
    } else {
      return (
        <div>
          <section className="main-bg">{this.renderDo()}</section>
        </div>
      );
    }
  }
}

export default withTranslation("common")(AmbItems);
