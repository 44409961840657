import React, {Component} from "react";
import AxiosInstance, {IMG_URL, Loader} from "../../const/const";
import {withTranslation} from "react-i18next";
import "./index.scss";
import {Helmet} from "react-helmet";
import Slider from "react-slick";

function CustomArrow(props) {
    return (
        <button className={`arrows ${props.type}`} onClick={props.onClick}></button>
    );
}


class Project extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            projectId: this.props.match.params.projectId,
            pageInfo: {},
            projectDetails: {},
            allProjects: []
        };

    }

    componentDidMount() {
        this._isMounted = true;
        const that = this;

        // AxiosInstance.get("api/page/project/" + this.state.projectId)
        //     .then((response) => {
        //         document.getElementById("main-head").scrollIntoView({
        //             behavior: "smooth",
        //             block: "end",
        //             inline: "nearest"
        //         });

        //         if (that._isMounted) {
        //             that.setState({ pageInfo: response.data });
        //         }
        //     });

        AxiosInstance.get("api/projects").then(function (response) {
            if (that._isMounted) {
                that.setState({
                    allProjects: response.data.slice(0, 3)
                });
            }
        });

        AxiosInstance.get(`api/projects/${this.state.projectId}`).then(function (response) {
            if (that._isMounted) {
                that.setState({
                    isLoaded: true,
                    projectDetails: response.data
                });
            }
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    next() {

    }

    previous() {

    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }


    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: <CustomArrow type="prev"/>,
            nextArrow: <CustomArrow type="next"/>,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };

        const {t} = this.props;
        const {isLoaded, projectDetails} = this.state;
        if (!isLoaded) {
            return <Loader/>;
        } else {
            return (
                <div className="project-page">
                    <Helmet>
                        <title>GLC Paints | {t('Project')}</title>
                    </Helmet>
                    <section className="main-bg space">
                        <img className="banner" src={IMG_URL + projectDetails.banner} alt="Project"/>
                    </section>
                    <div className="main-bg">
                        <section className="main-bg space">
                            <div className="text-center p-5">
                                {/* <h5>Title goes here</h5> */}
                                {/* <h6 className="subtitle">Subtitle goes here</h6> */}
                                <div dangerouslySetInnerHTML={{__html: projectDetails.description}}></div>
                            </div>
                        </section>
                        <div className="slider-container">
                            {projectDetails.slider.length > 1 && <Slider {...settings}>
                                {projectDetails?.slider.map((slide, index) => {
                                    return (
                                        <div key={index}>
                                            <img src={IMG_URL + slide} alt=""/>
                                        </div>
                                    );
                                })}
                            </Slider>}{projectDetails.slider.length == 1 &&
                            <div>
                                <img src={IMG_URL + projectDetails.slider[0]} alt=""/>
                            </div>
                        }

                        </div>
                        <section className="main-bg space other-projects">
                            <div className="p-5">
                                <h6>{t('Other Projects')}</h6>
                                <div className="row">

                                    {this.state.allProjects.map((project, index) => {
                                        return (
                                            <a className="col-12 col-md-4" href={`/projects/${project.slug}`}
                                               key={index}>
                                                <img src={IMG_URL + project.banner} alt=""/>
                                                <div className="project-name">{project.name}</div>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>

                </div>
            );
        }
    }
}

export default withTranslation("common")(Project);
