import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../../const/const";
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import "./Calculator.scss";
import $ from "jquery";
import { Helmet } from "react-helmet";

$(document).ready(function() {
  $(document).on("contextmenu", ".dynamic-img", function(e) {
    return false;
  });
});
class PaintCalculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageInfo: {},
      isLoaded: false,
      alert: null,
      visited: {},
      products: [],
      selectedProduct: {
        spreading_rate_unit: "M2/KG"
      },
      // selectedType: "INTERIOR",
      paintsNeeded: 0,
      width: "",
      height: "",
      numberOfWalls: ""
    };
  }
  openPrompt() {
    const { t } = this.props;
    const { selectedProduct } = this.state;

    const getAlert = () => (
      <SweetAlert
        showCloseButton
        title={t("Calculate Paints")}
        btnSize="xs"
        onConfirm={() => this.hideAlert()}
      >
        {t("You need")} {this.state.paintsNeeded}{" "}
        {selectedProduct.spreading_rate_unit.split(
          "/"
        )[1] === "LTR"
          ? t("liters")
          : selectedProduct.spreading_rate_unit.split(
              "/"
            )[1]}
      </SweetAlert>
    );

    this.setState({
      alert: getAlert()
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }
  componentDidMount() {
    const that = this;
    AxiosInstance.get("api/page/calculator").then(function(response) {
      document.getElementById("main-head").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
      that.setState({
        isLoaded: true,
        pageInfo: response.data
      });
    });

    AxiosInstance.get("api/products/flat").then(function (response) {
      that.setState({ products: response.data, selectedProduct: response.data[0] });
  });

    // AxiosInstance.get("api/products/interior").then(function(response) {
    //   const { products } = that.state;
    //   products["INTERIOR"] = response.data;
    //   that.setState({
    //     prodcuts: products,
    //     selectedProduct: response.data[0]
    //   });
    // });

    // AxiosInstance.get("api/products/exterior").then(function(response) {
    //   const { products } = that.state;
    //   products["EXTERIOR"] = response.data;
    //   that.setState({
    //     prodcuts: products
    //   });
    // });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value ? parseInt(e.target.value) : ""
    });
  };

  handleBlur = e => {
    const { visited } = this.state;
    visited[e.target.name] = true;
    this.setState({
      visited
    });
  };

  calc = () => {
    const { width, height, numberOfWalls, selectedProduct } = this.state;
    if (width > 0 && height > 0 && numberOfWalls > 0)
      this.setState({
        visited: {
          numberOfWalls: true,
          width: true,
          height: true
        }
      });
    if (width && height && numberOfWalls) {
      const minAmount =
        (width * height +
          2 * numberOfWalls * height +
          2 * width * numberOfWalls) /
        selectedProduct.spreading_rate_max;
      const maxAmount =
        (width * height +
          2 * numberOfWalls * height +
          2 * width * numberOfWalls) /
        selectedProduct.spreading_rate_min;
      if (i18n.language === "ar") {
        this.setState(
          {
            paintsNeeded: `مابين ${minAmount.toFixed()} إلى ${maxAmount.toFixed()} `
          },
          () => {
            this.openPrompt();
          }
        );
      } else {
        this.setState(
          {
            paintsNeeded: `between ${minAmount.toFixed()} to ${maxAmount.toFixed()}`
          },
          () => {
            this.openPrompt();
          }
        );
      }
    }
  };

  handleProductSelection = e => {
    const { products } = this.state;
    const id = e.target.value;
    this.setState({
      selectedProduct: products.filter(
        p => parseInt(p.id) === parseInt(id)
      )[0]
    });
    this.calc();
  };

  // handleTypeSelection = e => {
  //   const { prodcuts } = this.state;
  //   const selectedType = e.target.value;
  //   this.setState({ selectedType, selectedProduct: prodcuts[0] });
  //   this.calc();
  // };

  clearForm = () => {
    this.setState({
      paintsNeeded: 0,
      width: "",
      height: "",
      numberOfWalls: "",
      visited: {}
    });
  };

  render() {
    const { t } = this.props;
    const {
      isLoaded,
      pageInfo,
      visited,
      products,
      selectedProduct,
      // selectedType,
      numberOfWalls,
      width,
      height,
      paintsNeeded
    } = this.state;
    if (!isLoaded) {
      return <Loader />;
    } else {
      return (
        <div className="paint-calculator">
          <Helmet>
            <title>GLC Paints | Paints Calculator</title>
            <meta name="description" content={pageInfo.subtitle} />
            <meta
              name="keywords"
              content={pageInfo.title + " " + t("Products") + " " + t("Paints")}
            />
          </Helmet>
          <section className="main-bg">
            <div className="big-title">
              <div className="title-center">
                <h1>{pageInfo.title}</h1>
                <p>{pageInfo.subtitle}</p>
              </div>
            </div>
          </section>
          <div className="space">
            <div className="blog-tabs">
              <Container className="calculator-form">
                <Row>
                  <Col md={12}>
                    <div className="calculator-title">
                      {t("FILL THE FORM BELOW:")}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row>
                      {/* <Col md={12}>
                        <div className="form-group select-for-group">
                          <label> {t("Wall Type")}</label>
                          <select
                            defaultValue={"default"}
                            name="selectedType"
                            onChange={this.handleTypeSelection}
                            className="select-input"
                          >
                            <option value="INTERIOR">{t("INTERIOR")}</option>
                            <option value="EXTERIOR">{t("EXTERIOR")}</option>
                          </select>
                        </div>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="form-group select-for-group">
                          <label>{t("GLC Product")}</label>
                          <select
                            defaultValue={"default"}
                            name="selectedProduct"
                            onChange={this.handleProductSelection}
                            className="select-input"
                          >
                            {products.map(p => (
                              <option key={p.id} value={p.id}>
                                {p.title} - {p.product_number}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="">
                        <div className="form-group dimentions">
                          <label>{t("Width (m)")}</label>
                          <input
                            value={width}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            name="width"
                            type="number"
                            className="form-control text-input"
                          />
                          {visited.width && !width && (
                            <div className="validation-error">
                              {t("*Required")}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={6} className="">
                        <div className="form-group dimentions">
                          <label>{t("Height (m)")}</label>
                          <input
                            value={height}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            name="height"
                            type="number"
                            className="form-control text-input"
                          />
                          {visited.height && !height && (
                            <div className="validation-error">
                              {t("*Required")}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="">
                        <div className="form-group ">
                          <label>{t("Number Of Walls")}</label>
                          <input
                            value={numberOfWalls}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            name="numberOfWalls"
                            type="number"
                            className="form-control text-input"
                          />
                          {visited.numberOfWalls && !numberOfWalls && (
                            <div className="validation-error">
                              {t("*Required")}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8} className="btn-col">
                        <button
                          disabled={!width || !height || !numberOfWalls}
                          onClick={this.calc}
                          className={`calc-button '  + ${(!width ||
                            !height ||
                            !numberOfWalls) &&
                            "disabled-button"}`}
                        >
                          {t("Calculate")}
                        </button>
                      </Col>
                      <Col md={4}>
                        <button
                          onClick={this.clearForm}
                          className="clear-button"
                        >
                          {t("Clear")}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <div className="dynamic-img paint-image">
                      <img
                        src={IMG_URL + selectedProduct.image}
                        alt={`paints products ${selectedProduct.title} ${selectedProduct.product_number}`}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={12}>
                    <div className="footer-description">
                      {t("SEE HOW MUCH PAINT YOU'LL NEED")}
                      <Row>
                        <Col md={12}>
                          <div className="footer-description">
                            {" "}
                            {t("You need")} {paintsNeeded}{" "}
                            {selectedProduct.spreading_rate_unit.split(
                              "/"
                            )[1] === "LTR"
                              ? t("liters")
                              : selectedProduct.spreading_rate_unit.split(
                                  "/"
                                )[1]}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
              {this.state.alert}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation("common")(PaintCalculator);
