export default {
  "STAY IN TOUCH": "ابقى على تواصل",
  LEGAL: "القسم القانوني",
  "Privacy Policy": " سياسة الخصوصية",
  "Terms Of Use": "شروط الاستخدام",
  HOME: "الرئيسية",
  "Decorative Finishes": "دهانات ديكورية",
  Colors: "الألوان",
  colors: "الألوان",
  Inspiration: "الإلهام",
  inspirations: "الإلهام",
  Inspirations: "الإلهام",
  products: "المنتجات",
  Products: "المنتجات",
  Stores: "المعارض",
  stores: "المعارض",
  "ABOUT US": "من نحن",
  "Our Story": "قصتنا",
  "Our History": "تاريخنا",
  "Our Mission": "رسالتنا",
  "Our Values": "قيمنا",
  "Our Vision": "رؤيتنا",
  "Become a Distributor": "كيف تصبح موزعا",
  "BECOME A DISTRIBUTOR": "كيف تصبح موزعا",
  "FOLLOW US ON SOCIAL MEDIA": "تابعنا على وسائل التواصل الاجتماعي",
  Overview: "لمحة عامة",
  OVERVIEW: "لمحة عامة",
  Exterior: "خارجي",
  Interior: "داخلي",
  News: "الاخبار",
  news: "الاخبار",
  "About us": "من نحن",
  Search: "ابحث",
  "Paint Calculator": "حاسبة المنتجات",
  INTERIOR: "داخلي",
  EXTERIOR: "خارجي",
  "FILL THE FORM BELOW:": ":املأ النموذج أدناه",
  "SEE HOW MUCH PAINT YOU'LL NEED": "إحسب كمية الدهان",
  LTR: "لتر",
  "You need": "ستحتاج",
  Clear: "امسح",
  Calculate: "احسب",
  "Calculate Paints": "احسب كمية الدهان",
  "Width (m)": "عرض (م)",
  "Height (m)": "ارتفاع (م)",
  "*Required": "*مطلوب",
  "Our Promise": "وعدنا",
  Trust: "الثقة",
  Reliability: "الإعتماد",
  Innovation: "التجدد",
  Passion: "الشغف",
  "work with us": "إعمل معنا",
  "FULL NAME": "الاسم الكامل",
  "PHONE NUMBER": "رقم التلفون",
  "EMAIL ADDRESS": "البريد الالكتروني",
  CITY: "المدينة",
  Send: "ارسل",
  "GET DIRECTION": "كيف تصل",
  "Learn More": "اعرف المزيد",
  "COLOR PSYCHOLOGY": " علم الألوان",
  Description: "الشرح",
  Features: "المميزات",
  Tools: " الأدوات",
  Download: "تنزيل",
  View: "استعراض",
  Brochure: "بروشور",
  DOWNLOAD: "تنزيل",
  VIEW: "استعراض",
  "THE PSYCHOLOGY OF": "علم",
  COLORS: "الألوان",
  "CHECK ALL COLORS": "إكتشف مجموعة الألوان الكاملة",
  "Let us help you": "دعنا نساعدك",
  "Results By Category": "النتائج تبعا للصنف",
  Ambiences: "امبيانس",
  ambiences: "امبيانس",
  Trends: "اتجاهات الألوان",
  trends: "اتجاهات الألوان",
  "Type here": "اكتب هنا",
  Blue: "ازرق",
  BLUE: "ازرق",
  Yellow: "اصفر",
  YELLOW: "اصفر",
  Green: "اخضر",
  GREEN: "اخضر",
  Red: "احمر",
  RED: "احمر",
  White: "ابيض",
  WHITE: "ابيض",
  Grey: "رمادي",
  GREY: "رمادي",
  Pink: "وردي",
  Black: "اسود",
  Orange: "برتقالي",
  Brown: "بني",
  Violit: "بنفسجي",
  "Show Details": "عرض التفاصيل",
  Brush: "الفرشاة",
  Roller: "الرولة",
  "Spraying Gun": "مسدس الرش",
  "Airless Spray": "مسدس الرش لاهوائي",
  "Putty Knife": "سكينة معجون",
  Trowel: "مجحاف",
  "Plastic Trowel": "مجحاف بلاستيك",
  BEDROOM: "غرفة النوم",
  "LIVING ROOM": "غرفة المعيشة",
  KITCHEN: "المطبخ",
  "DINING ROOM": "غرفة الطعام",
  "KIDS ROOM": "غرفة الأولاد",
  MODERN: "حديث",
  CLASSIC: "كلاسيكي",
  RETAILER: "متاجر التجزئة",
  "Color Name/Code": "اسم/كود اللون",
  "A LITTLE  JOURNEY IN COLOR": "رحلة صغيرة في عالم الألوان",
  ' " To deliver consistent trusted paint solutions "':
    "'في GLC هدفنا هو تقديم حلول للدهانات تستحق الثقة. '  ",
  "Number Of Walls": "عدد الجدران",
  liters: "لتر",
  interior: "داخلي",
  exterior: "خارجي",
  "Intro page": "صفحة البداية",
  "wood guard": "حامي الخشب",
  "CHOOSE TYPE": "أختر نوع",
  "GLC Paints": " دهانات GLC",
  "GLC Products": "منتجات GLC",
  "GLC Product": "منتجات GLC",
  "Wall Type": "نوع الجدار",
  "GLC Paints Logo": " لوغو شركة GLC للدهانات ",
  "GLC Paints mission": "مهمة دهانات GLC",
  "GLC Paints vision": "رؤية دهانات GLC",
  "GLC Paints values": "قيم دهانات GLC",
  "Left Arrow": "سهم متجه لليسار",
  "Right Arrow": "سهم متجه لليمين",
  "View PDF Brochure": "عرض البروشورPDF",
  "Download PDF Brochure": "تحميل البروشور PDF",
  "GLC Trends Colors": "اتجاهات ألوان GLC",
  "Little tree branch with Geometric forms":
    "فرع شجرة صغير مع بعض الاشكال الهندسية",
  "A house with modern style surrounded by trees":
    "منزل ذو طابع عصري محاط بالأشجار",
  "A Sofa and a cupboard with a colorful wall": "كنبة و خزانة كتب و جدار ملون",
  "Multi Color wall": "جدار متعدد الألوان",
  "Colorful flowers": "أزهار ملونة",
  "Hanging lamp": "لمبة معلقة ",
  "A colorful wall": "جدار ملون",
  "A light pink chair": "كرسي ذو لون زهري فاتح",
  "A vase on the top of a wooden stand": "مزهرية على ستاند خشبي ",
  "A Vase": "مزهرية",
  "White flowers": "أزهار بيضاء",
  "Red flowers": "أزهار حمراء",
  "Blue flowers": "أزهار زرقاء",
  "Yellow flowers": "أزهار صفراء",
  "Selector for choosing the flowers' colors": "أداة لتغيير ألوان الأزهار",
  "Green wall with vintage furniture": "جدار أخضر ومفروشات عتيقة",
  "Modern silver building with white chairs":
    "بناء فضي ذو طابع حديث مع كراسي بيضاء",
  "GLC Paints HQ": "المركز الرئيسي لدهانات GlC",
  "GLC Store": " متجر لدهانات GlC",
  "GLC Company Address": "عنوان شركة GLC",
  "A gray wall with a black and white painting":
    "حائط رمادي ولوحة مرسومة باللونين الأبيض والأسود",
  "A wooden table and a basket beneth it": "طاولة خشبية تحتها يوجد سلة",
  "A basket": "سلة من القش",
  "FIRST NAME": "الاسم الاول",
  "LAST NAME": "الاسم الأخير",
  POSITION: "الشاغر الوظيفي",
  ADDRESS: "العنوان",
  "*Please enter valid email": "*ادخل بريد الكتروني صالح",
  "*Only numbers are allowed": "*ادخل رقم تلفون صالح ",
  "A very personal choice": "اختيار شخصي جدا",
  "2020 Color influences": "إتجاهات ألوان 2020",
  "PLAYFUL AND VERSATILE": "هادئ، مريح، يساعد على التركيز",
  "Paints For The Wall": "دهانات للجدران",
  "That Has everthing": "",
  "Special Effects": "تشطيبات ديكورية فخمة",
  "Luxurious decorative finishes": "",
  AMBIENCE: "أمبيانس",
  "Color Influences": "إتجاهات الألوان",
  "Be in the moment": "عش اللحظة",
  "Follow the latest trends": "تعرف على آخر الصيحات",
  "We know color": "نحن خبراءالألوان",
  "Like no one else": "",
  HQ: "المركز الرئيسي",
  "Platine Color Card": "كرت اللون البلاتيني",
  "Outstanding Whiteness": "بياض ناصع",
  "Outstanding Opacity": "تغطية ممتازة",
  "Excellent Anti-carboination Properties": "قابلية ممتازة ضد الكربنة ",
  "High Washability": "قابلية عالية للغسيل",
  "Moisture Resistant": "مقاوم للرطوبة",
  "Water Repellent": "مقاومة عالية لامتصاص الماء",
  "Excellent Weather Resistance": "مقاوم للظروف الجوية",
  "Weather Resistant": "مقاوم للتقلبات المناخية ",
  "Outstanding Protection": "حماية قصوى",
  "Excellent Water Proofing": "عازل ممتاز للماء",
  "Excellent Pentration": "إختراق ممتاز",
  "High Colour Retention": "خاصية ثبات الألوان لفترة طويلة",
  "Fast Drying": "سريع الجفاف",
  "Excellent Dirt Pickup Resistance": "مقاومة ممتازة لإلتقاط الأوساخ",
  "Excellent Protection Against Algy and Fungs":
    "حماية ممتازة ضد الفطريات والطحالب",
  "Upload CV": "السيرة الذاتية",
  Explore: "إكتشف",
  "Color Trends": "إتجاهات الألوان",
  "54 St, Block 13027, First Industrial Zone El Obour - Egypt":
    " مدينة العبور -المنطقة الصناعية الأولى، بلوك ١٣٠٢٧ قطعة ٣ شارع ٥٤ ",
  "54 St, Block 13027": "بلوك ١٣٠٢٧ قطعة ٣ شارع ٥٤",
  "First Industrial Zone": "المنطقة الصناعية الأولى",
  "El Obour - Egypt": "مدينة العبور - مصر",
  Career: "وظائف",
  Careers: "وظائف",
  "We are a diverse work force that is constantly looking to expand, join us if you think you share our values":
    "نحن فريق عمل متنوع ونتطلع دائما للتوسع، إنضم إلينا إذا كنت تشاركنا قيمنا وتطلعاتنا",
  "We are constantly looking to partner with people who understand and value our culture, if you have what it takes, join the family":
    "نتطلع باستمرار إلى الشراكة مع أشخاص يفهمون ثقافتنا ويقدرونها ، إذا كان لديك ما يلزم ، انضم إلى عائلة جي ال سي وأصبح أحد موزعينا",
  "Join  Us": "انضم إلينا",
  "Our color experts at GLC have selected some images to guide you in your color selection process":
    "في جي ال سي يسعدنا أن نقوم بمساعدتك بعملية إختيار اللون المناسب لك، لقد قام خبراء الألوان لدينا بجمع عدد من الأفكار التي تساعدك على تحديد مجموعة الألوان المناسبة لك.",
  "LEARN MORE ABOUT YOUR FAVORITE PAINT BRAND ,GLC Paints":
    "اعرف المزيد عن الشركة الالمانية اللبنانية للدهانات",
  "GLC is an established full solution paint provider. We are guardians of our customers most valuable assets.":
    "تعد شركة GLC من رواد مصنعي الدهانات ذات الجودة العالية.نحن نحمي أصول عملائنا القيمة",
    "Favorite" : "المفضلة",
    "products found": "منتج موجود",
    "Search for a product": "ابحث عن منتج",
    "Compare": "مقارنة",
    "Delete selection": "حذف الاختيار",
    "Compare products": "مقارنة المنتجات",
    "products added": "منتج مضاف",
    "Filters": "فرز",
    "Find a store": "ابحث عن متجر",
    "Product features": "مميزات المنتج",
    "Technical Information": "معلومات فنية",
    "Technical & Safety Documentation": "المستندات الفنية",
    "Product Description": "وصف المنتج",
    "PRODUCT COMPARISON": "مقارنة المنتجات",
    "Specifications": "مواصفات",
    "Product Use": "استخدام المنتج",
    "Product Type": "نوع المنتج",
    "Color": "اللون",
    "Finish": "التشطيب",
    "Coverage": "التغطية",
    "Drying Time": "وقت الجفاف",
    "Coats": "الطبقات",
    "Back to Products": "العودة للمنتجات",
    "Add product": "إضافة منتج",
    "A maximum of 3 products can be compared at a time": "يمكن مقارنة 3 منتجات فقط في نفس الوقت",
    "Up to 3 products can be compared": "يمكن مقارنة 3 منتجات على الأكثر",
    "Other Projects": "مشاريع أخرى",
    "Clear All": "مسح الكل",
    "Spreading Rate": " معدل الفرد النظري",
    "Product available sizes are": "حجم العبوة المتاح من هذا المنتج",
    "THE RIGHT PAINT FOR YOU": "الدهان المناسب لك",
    "Recommended Use": "الاستخدام الموصى به",
    "Projects": "المشاريع",
    "M2/LTR": "متر مربع / لتر",
    "M2/KG": "متر مربع / كجم",
};
