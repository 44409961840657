import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import { withTranslation } from "react-i18next";
import Link from "../../components/common/link/link";
import { Helmet } from "react-helmet";
import "./Colors.scss";

class Colors extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tableRows: [],
      pageInfo: {}
    };
  }

  componentDidMount() {
    const that = this;
    that._isMounted = true;

    // Api for Color Boxes
    AxiosInstance.get("api/colors").then(function(response) {
      document.getElementById("main-head").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
      if (that._isMounted) {
        that.setState({
          tableRows: response.data
        });
      }
      // Api for Colors Page
      AxiosInstance.get("api/page/colors").then(function(response) {
        if (that._isMounted) {
          that.setState({
            isLoaded: true,
            pageInfo: response.data
          });
        }
      });
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    const date= new Date();
    const { isLoaded, tableRows, pageInfo } = this.state;
    
    // FIXME: 
    // const currentYear = date.getFullYear(); 
    const currentYear = 2023;

    if (!isLoaded) {
      return <Loader />;
    } else {
      return (
        <div>
          <Helmet>
            <title>GLC Paints | Colors</title>
            <meta name="description" content="GLC paints-Colors" />
          </Helmet>
          <section className="main-bg ">
            <div className="big-title colors-header-big-title">
              <div className="title-center">
                <h1 className="colors-header">{pageInfo.title}</h1>
                <p>{pageInfo.subtitle}</p>
              </div>
            </div>
            <div className="grid-container-padding">
              <div className="parent small-space">
                {/* TRENDS */}
                <div className="col-12 colors-div">
                  <Link to={`/colors/trends/${currentYear}`}>
                    <img
                      src={IMG_URL + pageInfo.trends_image}
                      className="colors-bg"
                      alt={t("GLC Trends Colors")}
                    />
                    <span className="colors-overlay-bg"></span>
                    <div className="colors-scale-content">
                      <h2 className="colors-h1">{pageInfo.trends_title}</h2>
                    </div>
                  </Link>
                </div>
                {/* 2 - 3 - 4 - 5 - 7 - 8 th Boxes from API */}
                {tableRows.map((item, index) => (
                  <div itemScope className="col-12 colors-div" key={index}>
                    <Link to={`/colors/${item.slug}`}>
                      <img
                        src={IMG_URL + item.feature_image}
                        className="colors-bg"
                        alt={item.slug}
                      />
                      <span className="colors-overlay-bg"></span>
                      <div itemScope className="colors-scale-content">
                        <h2 itemProp="name" className="colors-h1">
                          {item.name}
                        </h2>
                        <p itemProp="description">{item.excerpt}</p>
                      </div>
                    </Link>
                  </div>
                ))}
                {/* COLOR PSYCHOLOGY */}
                <div className="col-12 colors-div">
                  <Link to="/colors/color-psychology">
                    <img
                      src={IMG_URL + pageInfo.color_psychology_image}
                      className="colors-bg"
                      alt={t("GLC Trends Colors")}
                    />
                    <span className="colors-overlay-bg"></span>
                    <div className="colors-scale-content">
                      <h2 className="colors-h1">{t("COLOR PSYCHOLOGY")}</h2>
                      <p>{pageInfo.color_psychology}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
}
export default withTranslation("common")(Colors);
