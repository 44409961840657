import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import { withTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import "./product-detail.scss";
import "./Compare.scss";
import Link from "../../components/common/link/link";
import InfiniteScroll from 'react-infinite-scroller';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';



class ProductList extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true,
            pageInfo: {},
            allProducts: [],
            filteredProducts: [],
            renderedProducts: [],
            pageIndex: 0,
            favList: [],
            filters: [],
            selectedFilters: {},
            selectedForCompare: [],
            mobileFilterOpened: false,
            favoriteFiltered: false,
        };

    }

    componentDidMount() {
        this._isMounted = true;
        const that = this;

        this.setState({ selectedForCompare: JSON.parse(localStorage.getItem('compare')) || [] });

        AxiosInstance.get(`api/page/products`).then(function (response) {
            if (that._isMounted) {
                that.setState({
                    pageInfo: response.data,
                });
            }
        });

        AxiosInstance.get("api/products/flat")
            .then((response) => {
                that.setState({ allProducts: response.data, filteredProducts: response.data });
            });


        this.setState({ favList: JSON.parse(localStorage.getItem("favorite")) || [] });

        AxiosInstance.get(`api/products/flat/filters`).then(function (response) {
            const langProp = that.props.i18n.language === 'en' ? 'name_en' : 'name_ar';
            if (that._isMounted) {
                that.setState({
                    filters: response.data.map(filter => {
                        filter.options = filter.options.map(option => {
                            option.name = option[langProp];
                            return option;
                        });
                        return filter;
                    }),
                });
            }

        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleFavorite(pId) {
        const favList = JSON.parse(localStorage.getItem("favorite")) || [];
        if (favList.includes(pId))
            favList.splice(favList.indexOf(pId), 1);
        else
            favList.push(pId);
        localStorage.setItem('favorite', JSON.stringify(favList));
        this.setState({ favList });
    }

    removeInlineStyles(html) {
        // const htmlNode = document.createElement('div');
        // htmlNode.innerHTML = html;
        // htmlNode.querySelectorAll('*').forEach(function (node) {
        //     node.removeAttribute('style');
        // });
        return html;
    }

    toggleCompare(product) {
        const { selectedForCompare } = this.state;
        let isExist = false;

        selectedForCompare.map(p => {
            if (p.id === product.id) {
                selectedForCompare.splice(selectedForCompare.indexOf(p), 1);
                isExist = true;
            }
        });

        if (!isExist) {
            selectedForCompare.push(product);
        }

        this.setState({ selectedForCompare });
        localStorage.setItem('compare', JSON.stringify(selectedForCompare));
    }

    isSelectedToCompare(product) {
        const { selectedForCompare } = this.state;
        let isExist = false;

        selectedForCompare.map(p => {
            if (p.id === product.id) {
                isExist = true;
            }
        });

        return isExist;
    }

    renderProduct(product) {
        const { t } = this.props;
        const { selectedForCompare } = this.state;
        const isSelectedToCompare = this.isSelectedToCompare(product);
        const lang = i18n.language;

        return (
            <div className={`product-box ${isSelectedToCompare ? 'bordered' : ''}`}>
                {
                    isSelectedToCompare && <div className="compare-icon">
                        <img src="/assets/img/left-right-arrows.svg" alt="" />
                    </div>
                }
                <div className="product-actions">
                    <button className={`'fav-btn ' ${this.state.favList.includes(product.id) ? 'favorite' : ''}`} onClick={e => this.toggleFavorite(product.id)}>
                        <i className={`fa ${this.state.favList.includes(product.id) ? 'fa-heart' : 'fa-heart-o'}`} aria-hidden="true"></i>
                    </button>

                    {product.is_new_product === 1 ? <img className="new-product-icon" src="/assets/img/product/new-product.svg" alt="new-product" /> : ''}
                </div>
                <div className="product-img">
                    <Link to={`/products/detail/${product.slug}`}>
                        <img src={IMG_URL + product.image} alt="product" />
                    </Link>
                </div>
                <div className="product-name">
                    <Link to={`/products/detail/${product.slug}`}>{product.title}</Link>
                    <div className="product-number">{product.product_number}&nbsp;</div>
                </div>
                {/* <div className="product-desc" dangerouslySetInnerHTML={{ __html: this.removeInlineStyles(product.features) }}></div> */}
                <div className="product-desc">
                    <div dangerouslySetInnerHTML={{ __html: product.features }}></div>
                </div>
                {(selectedForCompare.length < 3 || isSelectedToCompare) && <div className="product-compare">
                    <label className="checkbox-wrapper reverse">
                        <input className="checkbox" type="checkbox" checked={isSelectedToCompare} onClick={() => this.toggleCompare(product)} />
                        <span>{t('Compare')}</span>
                        <span className="checkmark"></span>
                    </label>
                    <Link to={`/products/detail/${product.slug}`}>
                        {lang === 'en' && <img className="" src="/assets/img/arrow-right.svg" alt="" />}
                        {lang === 'ar' && <img className="" src="/assets/img/arrow-left.svg" alt="" />}
                    </Link>
                </div>}
                {
                    (selectedForCompare.length >= 3 && !isSelectedToCompare) && <div className="compare-error">{t('Up to 3 products can be compared')}</div>
                }
            </div >
        );
    }

    loadMoreProducts() {
        const { filteredProducts, renderedProducts, pageIndex } = this.state;
        renderedProducts.push(...filteredProducts.slice(renderedProducts.length, (pageIndex + 1) * 9));
        this.setState({ renderedProducts, pageIndex: pageIndex + 1 });
    }

    getRenderedProducts() {
        const { renderedProducts } = this.state;
        return (
            <Row className="no-gutters">
                {renderedProducts.map((product, index) => {
                    return (
                        <Col className="col-product-box" xs={6} md={4} key={index}>
                            {this.renderProduct(product)}
                        </Col>
                    );
                })}
            </Row>
        );
    }

    toggleFilter(filter, option) {
        const { selectedFilters } = this.state;
        if (selectedFilters[option.name]) {
            selectedFilters[option.name] = null;
        } else {
            selectedFilters[option.name] = filter.key;
        }
        this.filterChanged();
    }

    toggleFavoriteFilter() {
        const { favList, allProducts, favoriteFiltered } = this.state;
        allProducts.map(product => {
            if (favList.includes(product.id)) {
                product.isFavorite = true;
            } else {
                product.isFavorite = false;
            }
        });
        this.setState({ allProducts, favoriteFiltered: !favoriteFiltered });
        this.toggleFilter({ key: 'isFavorite', options: [{ isFavorite: true }] }, { name: true });
    }

    filterChanged(clear = false) {

        let { allProducts, selectedFilters } = this.state;

        if (clear) {
            selectedFilters = {};
        }

        const compare = (product, key, value) => {
            const langProp = this.props.i18n.language === 'en' ? 'name_en' : 'name_ar';
            const obj = product[key];

            if (obj instanceof Array) {
                return obj.some(item => item[langProp] === value);
            } if (typeof obj === 'boolean') {
                return obj === Boolean(value);
            } else {
                return obj[langProp] === value;
            }
        };


        // const filteredProducts = Object.keys(selectedFilters).filter(item => selectedFilters[item] !== null).length !== 0 ? [] : allProducts;
        // const addProduct = product => {
        //     if (filteredProducts.findIndex(p => p.id === product.id) === -1) {
        //         filteredProducts.push(product);
        //     }
        // }
        // allProducts.map(product => {
        //     Object.keys(selectedFilters).forEach(value => {
        //         if (!selectedFilters[value]) return;
        //         if(product[selectedFilters[value]] ? compare(product, selectedFilters[value], value) : false)
        //         addProduct(product);
        //     });
        // });

        const filteredProducts = allProducts.filter(product => {
            let result = true;
            Object.keys(selectedFilters).forEach(value => {
                if (!selectedFilters[value]) return;
                result = result && (product[selectedFilters[value]] ? compare(product, selectedFilters[value], value) : false);
            });
            return result;
        });

        this.setState({ filteredProducts, pageIndex: 1, renderedProducts: filteredProducts.slice(0, 9), selectedFilters });
    }

    deleteSelection() {
        localStorage.removeItem('compare');
        this.setState({ selectedForCompare: [] });
    }

    goToComparePage() {
        const { selectedForCompare } = this.state;
        const lang = i18n.language;
        const productsList = selectedForCompare.map(p => p.id).join('/');
        this.props.history.push("/" + lang + '/products/compare/' + productsList);
    }

    clearAllFilters() {
        const { favoriteFiltered } = this.state;
        if (favoriteFiltered) {
            this.toggleFavoriteFilter();
            this.setState({ favoriteFiltered: !favoriteFiltered });
        }
        this.filterChanged(true);
    }

    getFilters() {
        const { filters, selectedFilters, favoriteFiltered } = this.state;
        const { t } = this.props;

        return (
            <div className="product-filters">
                <div className="mobile-filter-header d-md-none">
                    <img className="close-icon" src="/assets/img/close.svg" onClick={e => this.setState({ mobileFilterOpened: false })} alt="" />
                    <img src="/assets/img/filters.svg" alt="" /> {t('Filters')}
                    <button className="clear-all-btn position-absolute text-danger" onClick={() => this.clearAllFilters()}>{t('Clear All')}</button>
                </div>
                <Accordion defaultActiveKey="100">
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle eventKey="100">
                                {t('Favorite')} <i className="fa fa-angle-down" aria-hidden="true"></i>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse expand={true} eventKey="100">
                            <div className="filter-body">
                                <label className="checkbox-wrapper">
                                    <input checked={favoriteFiltered} className="checkbox" type="checkbox" onChange={e => this.toggleFavoriteFilter()} /> {t('Favorite')}
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                {filters.map((filter, index) => {
                    return (
                        <Accordion defaultActiveKey={`f${index}`}>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle eventKey={`f${index}`}>
                                        {filter.label} <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </Accordion.Toggle>
                                </Card.Header>

                                <Accordion.Collapse expand={true} eventKey={`f${index}`}>
                                    <div className="filter-body">
                                        {
                                            filter.options.map((option, i) => {
                                                return (
                                                    <label className="checkbox-wrapper">
                                                        <input className="checkbox" key={`i-${i}`} checked={selectedFilters[option.name]} type="checkbox" onChange={e => this.toggleFilter(filter, option)} /> {option.name}
                                                        <span className="checkmark"></span>
                                                    </label>
                                                );
                                            })
                                        }
                                    </div>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    );
                })}
            </div>);
    }

    searchForProduct(text) {
        const { allProducts } = this.state;
        const filteredProducts = allProducts.filter(product => {
            return product.title.toLowerCase().includes(text.toLowerCase()) || product.product_number.toLowerCase().includes(text.toLowerCase())
                || product.features.toLowerCase().includes(text.toLowerCase());
        });
        this.setState({ filteredProducts, pageIndex: 0, renderedProducts: filteredProducts.slice(0, 9) });
        if (!text)
            this.filterChanged();
    }

    openMobileFilter() {
        this.setState({ mobileFilterOpened: !this.state.mobileFilterOpened });
    }

    render() {

        const { t } = this.props;
        const { pageInfo, isLoaded, filteredProducts, pageIndex, selectedForCompare, selectedFilters, mobileFilterOpened, allProducts } = this.state;
        const lang = i18n.language;

        if (!isLoaded) {
            return <Loader />;
        } else {
            return (
                <>
                    <div className="product-list-page">
                        <Helmet>
                            <title>GLC Paints | {t('Products')} </title>
                        </Helmet>
                        <section className="">
                            <div className="big-title prod-title-mob">
                                <div className="title-center">
                                    <h1> {pageInfo.title} </h1>
                                    <p> {pageInfo.subtitle} </p>
                                </div>
                            </div>
                        </section>

                        <div className="container pt-3">
                            <Row className="space">
                                <Col className={`d-none d-md-block ${mobileFilterOpened ? 'mobile-filter-opened' : ''}`} md={3}>
                                    {this.getFilters()}
                                </Col>
                                <Col md={9}>
                                    <Row className={`no-gutters search-container`}>
                                        <div className="filter-bar">
                                            <div className=" d-none d-md-block">{filteredProducts.length} {t('products found')}</div>
                                            <button className="d-block d-md-none mobile-filter-button" onClick={e => this.openMobileFilter()}><img src="/assets/img/filters.svg" className="ml-1 mr-1" alt="" /> {t('Filters')}</button>
                                            <div className="input-wrapper">
                                                <img src="/assets/img/red-search.svg" alt="search" />
                                                <input type="text" placeholder={t('Search for a product')} onChange={e => this.searchForProduct(e.target.value)} />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className={`justify-content-between no-gutters ${lang === 'en' ? 'pr-2' : 'pl-2'}`}>
                                        <div className="selected-filters d-none d-md-flex">
                                            {Object.keys(selectedFilters).map((key, index) => {
                                                if (!selectedFilters[key] || key === 'true') return;
                                                return <div key={index} className="filter">{key} <img onClick={() => this.toggleFilter({}, { name: key })} src="/assets/img/red-close.svg" alt="" /></div>;
                                            })}
                                        </div>
                                        {allProducts.length !== filteredProducts.length && <div className="d-none d-md-flex"><button className="clear-all-btn" onClick={() => this.clearAllFilters()}>{t('Clear All')}</button></div>}
                                    </Row>
                                    <InfiniteScroll
                                        pageStart={pageIndex}
                                        loadMore={this.loadMoreProducts.bind(this)}
                                        hasMore={pageIndex * 9 < filteredProducts.length}
                                    >
                                        {this.getRenderedProducts()}
                                    </InfiniteScroll>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {
                        selectedForCompare.length >= 1 && (
                            <div className="compare-bar">
                                <div className="d-none d-md-inline-block">
                                    <img src="/assets/img/compare.svg" alt="" />
                                    <span>{selectedForCompare.length} {t('products added')}</span>
                                </div>
                                <div className="text-center">
                                    <button className="compare" onClick={() => this.goToComparePage()}>{t('Compare products')}</button>
                                    <button className="delete" onClick={() => this.deleteSelection()}>{t('Delete selection')}</button>
                                </div>
                            </div>
                        )
                    }
                </>
            );
        }
    }
}

export default withTranslation("common")(ProductList);
