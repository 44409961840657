import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import { withTranslation } from "react-i18next";
import { Container } from "reactstrap";
import ColorsData from "./ColorsData";
import "../homepage/HomePage.scss";
import { Helmet } from "react-helmet";

class ColorItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      colorName: "",
      tableRows: {}
    };
  }
  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    let result = [];
    if (tempDiv.innerHTML.includes("<p")) {
      tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));
    } else {
      result.push(html);
    }
    let result2 = result.splice(0, 1)[0];
    let temp = result2.split("");
    if (temp[0] === ".") {
      temp.splice(0, 1);
      temp.push(".");
      let result3 = temp.join("");
      return result3;
    } else {
      return result2;
    }
  };
  componentDidMount() {
    var thisVar = this;
    const colorName = this.props.match.params.color;
    AxiosInstance.get("api/colors/" + this.props.match.params.slug).then(
      function(response) {
        thisVar.setState(
          {
            isLoaded: true,
            pageContent: response.data,
            colorName
          },
          () => {
            document.getElementById("main-head").scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest"
            });
          }
        );
      }
    );
  }

  renderDo() {
    const { pageContent, colorName } = this.state;
    return (
      <div>
        <Helmet>
          <title>GLC Paints | {pageContent.name}</title>
          <meta
            name="description"
            content={this.extractParagraphTexts(pageContent.description)}
          />
          <meta
            name="keywords"
            content={pageContent.name + "-" + pageContent.excerpt}
          />
        </Helmet>
        <div
          className="amb-item-bg"
          style={{ backgroundImage: `url(${IMG_URL + pageContent.banner})` }}
        >
          <div className="amb-item-title">
            <h1>{pageContent.name}</h1>
            <p>{pageContent.excerpt}</p>
          </div>
        </div>
        <section className="box colors-items-area">
          <Container fluid>
            <ColorsData data={pageContent} color={colorName} />
          </Container>
        </section>
      </div>
    );
  }

  render() {
    const { isLoaded } = this.state;
    if (!isLoaded) {
      return <Loader />;
    } else {
      return (
        <div>
          <section className="main-bg ">{this.renderDo()}</section>
        </div>
      );
    }
  }
}

export default withTranslation("common")(ColorItems);
