import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "./NotFount.scss";

class NotFound extends Component {
  state = { redirect: null };
  componentDidMount() {
    const path = this.props.location.pathname;
    const hasLang = path.includes("en") || path.includes("ar");
    if (!hasLang) {
      const defaultLang = "en";
      this.props.history.push(`/${defaultLang}${path}`);
    }
    if (path.includes("en")) {
      this.setState({ redirect: `/en/404.html` });
    } else if (path.includes("ar")) {
      this.setState({ redirect: `/ar/404.html` });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div className="big-title">
        <div className="title-center">
          <div>
            <p>Page Not Found</p>

            <p className="title-center">
              <a className="go-home" href="/">
                Return to Home Page
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NotFound);
