import React, { Component } from "react";
import Link from "../link/link";
import { Navbar, Nav } from "react-bootstrap";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import "./Header.scss";
import { withRouter } from "react-router-dom";

// When scroll down (70px) mouse-over top show menu

$(window).scroll(function () {
  $(this).scrollTop() > 70
    ? $("#main-head")
      .addClass("scrollDown")
      .removeClass("firstInit")
      .removeClass("scrollUp")
    : $("#main-head")
      .addClass("scrollUp")
      .removeClass("scrollDown");
});

$("nav").mouseout(function () {
  if ($(window).scrollTop() > 70) {
  }
});

$(document).ready(function () {
  $("#nav-icon1").click(function () {
    $(this).toggleClass("open");
  });
});
$(document).ready(function () {
  $("#nav-icon1").click(function () {
    $("#redbox").toggleClass();
  });
});

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: "",
      prevScrollpos: window.pageYOffset,
      visible: true
    };
  }
  search = e => {
    e.preventDefault();
    const lang = i18n.language;
    this.props.history.push(
      "/" + lang + "/search?query=" + this.state.searchQuery
    );
    this.setState({ searchQuery: "" });
  };

  componentDidMount() {
    window.onscroll = function () {
      if (window.pageYOffset > 15) {
      }
    };
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });
  };
  changeLang = value => {
    const lang = value;
    i18n.changeLanguage(lang);
    let link = window.location.pathname;
    if (lang === "ar") link = link.replace("/en", "/ar");
    else link = link.replace("/ar", "/en");
    this.props.history.push(link);
    window.location.reload(false);
  };
  render() {
    const { t } = this.props;

    return (
      <div id="main-head" className="firstInit">
        <div id="stylish">
          <div
            className={
              !this.state.visible
                ? "navbar--hidden main-navigation-container"
                : "main-navigation-container"
            }
          >
            <div className="col-md-2">
              <Link to="/">
                <img
                  itemProp="logo"
                  className="logo"
                  src={"/./assets/img/logo.png"}
                  alt={t("GLC Paints Logo")}
                />
              </Link>
            </div>

            <div className="col-md-8 navigation-items">
              <ul>
                <li>
                  <Link to={"/ambiance"}>{t("Decorative Finishes")}</Link>
                </li>
                <li>
                  <Link to={"/colors"}>{t("Colors")}</Link>
                </li>
                <li className="sub-svg">
                  <Link to={"/inspiration"}>{t("Inspiration")}</Link>
                  <section className="inspiration-sub-menu submenu-container">
                    <div className="rtl-list">
                      <div className="list">
                        <Link to={"/Inspiration"}>{t("Overview")}</Link>
                      </div>
                      <div className="list">
                        <Link
                          to="/inspiration/interior"
                          className="category-subtitle"
                        >
                          {t("Interior")}
                        </Link>
                      </div>
                      <div className="list">
                        <Link
                          to="/inspiration/exterior"
                          className="category-subtitle"
                        >
                          {t("Exterior")}
                        </Link>
                      </div>
                    </div>
                  </section>
                </li>
                <li>
                  <Link to={"/products/list"}>{t("Products")}</Link>
                  {/* <section className="products-sub-menu submenu-container">
                    <div className="rtl-list">
                      <div className="list">
                        <Link to={"/products-intro"}>{t("Products")}</Link>
                      </div>
                      <div className="list">
                        <Link
                          to="/products/paint-calculator"
                          className="category-subtitle"
                        >
                          <i className="fa fa-calculator"></i>
                          {t("Paint Calculator")}
                        </Link>
                      </div>
                    </div>
                  </section> */}
                </li>
                {/* <li>
                  <Link to="/stores" className="category-title">
                    {t("Stores")}
                  </Link>
                </li>
                <li className="sub-svg">
                  <Link to="/about-us" className="category-title">
                    {t("About us")}
                  </Link>
                  <section className="products-sub-menu submenu-container">
                    <div className="about-us-list">
                      <div className="list">
                        {i18n.language === "ar" ? (
                          <HashLink smooth to={"/ar/about-us/#our-story"}>
                            {t("Our Story")}
                          </HashLink>
                        ) : (
                          <HashLink smooth to={"/en/about-us/#our-story"}>
                            {t("Our Story")}
                          </HashLink>
                        )}
                      </div>
                      <div className="list">
                        {i18n.language === "ar" ? (
                          <HashLink
                            smooth
                            to="/ar/about-us/#mission"
                            className="category-subtitle"
                          >
                            {t("Our Mission")}
                          </HashLink>
                        ) : (
                          <HashLink
                            smooth
                            to="/en/about-us/#mission"
                            className="category-subtitle"
                          >
                            {t("Our Mission")}
                          </HashLink>
                        )}
                      </div>
                      <div className="list">
                        {i18n.language === "ar" ? (
                          <HashLink
                            smooth
                            to="/ar/about-us/#values"
                            className="category-subtitle"
                          >
                            {t("Our Values")}
                          </HashLink>
                        ) : (
                          <HashLink
                            smooth
                            to="/en/about-us/#values"
                            className="category-subtitle"
                          >
                            {t("Our Values")}
                          </HashLink>
                        )}
                      </div>
                      <div className="list">
                        {i18n.language === "ar" ? (
                          <HashLink
                            smooth
                            to={"/ar/about-us/#careers"}
                            className="category-subtitle"
                          >
                            {t("Careers")}
                          </HashLink>
                        ) : (
                          <HashLink
                            smooth
                            to={"/en/about-us/#careers"}
                            className="category-subtitle"
                          >
                            {t("Careers")}
                          </HashLink>
                        )}
                      </div>
                    </div>
                  </section>
                </li>

                <li>
                  <Link to="/news" className="category-title">
                    {" "}
                    {t("News")}
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className="col-md-2 searchbox">
              {/* <form onSubmit={this.search}>
                <input
                  value={this.state.searchQuery}
                  onChange={e => this.setState({ searchQuery: e.target.value })}
                  type="search"
                  placeholder={t("search")}
                />
              </form> */}
              <div className="change-lang">
                <ul>
                  <li>
                    <Link to="/stores">
                      <img src="/assets/img/store.svg" alt=""/>
                    </Link>
                  </li>
                  <li>
                    <Link to="/search">
                      <i className="fa fa-search padding-right"></i>
                    </Link>
                  </li>
                  <li>
                    {i18n.language === "ar" ? (
                      <span
                        onClick={() => {
                          this.changeLang("en");
                        }}
                      >
                        ENG
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          this.changeLang("ar");
                        }}
                      >
                        عربي
                      </span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mobile-nav">
            <Navbar expand="lg" collapseOnSelect>
              <div id="mobile-nav-bg"></div>
              <Navbar.Brand>
                <Link to="/">
                  <img
                    itemProp="logo"
                    className="logo"
                    src={"/./assets/img/logo.png"}
                    alt={t("GLC Paints Logo")}
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                id="mob-toggle1"
                aria-controls="basic-navbar-nav"
              />
              <Navbar.Collapse id="mob-nav1">
                <Nav className="mr-auto">
                  <Nav.Link href={`/${i18n.language}/ambiance`}>
                    {t("Decorative Finishes")}
                  </Nav.Link>
                  <Nav.Link href={`/${i18n.language}/colors`}>
                    {t("Colors")}
                  </Nav.Link>
                  <Nav.Link href={`/${i18n.language}/Inspiration`}>
                    {t("Inspiration")}
                  </Nav.Link>
                  <Nav.Link href={`/${i18n.language}/products/list`}>
                    {t("Products")}
                  </Nav.Link>
                  {/* <Nav.Link
                    href={`/${i18n.language}/products/paint-calculator`}
                  >
                    {t("Paint Calculator")}
                  </Nav.Link> */}
                  {/* <Nav.Link href={`/${i18n.language}/stores`}>
                    {t("Stores")}
                  </Nav.Link> */}
                  {/* <Nav.Link href={`/${i18n.language}/about-us`}>
                    {t("About us")}
                  </Nav.Link> */}
                  {/* <Nav.Link href={`/${i18n.language}/news`}>
                    {t("News")}
                  </Nav.Link> */}
                  <Nav.Link href={`/${i18n.language}/search`}>
                    {t("Search")}
                  </Nav.Link>
                  <div className="mob-change-lang">
                    <ul>
                      <li>
                        {i18n.language === "ar" ? (
                          <span
                            onClick={() => {
                              this.changeLang("en");
                            }}
                          >
                            ENG
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              this.changeLang("ar");
                            }}
                          >
                            عربي
                          </span>
                        )}
                      </li>
                    </ul>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
        <div id="rectangle">
          <div className="main-navigation-container" id="navigation-container">
            <div className="col-md-2">
              <Link to="/">
                <img
                  itemProp="logo"
                  className="logo"
                  src={"/./assets/img/logo.png"}
                  alt={t("GLC Paints Logo")}
                />
              </Link>
            </div>

            <div className="col-md-8 navigation-items">
              <ul>
                <li>
                  <Link to={"/ambiance"}>{t("Decorative Finishes")}</Link>
                </li>
                <li>
                  <Link to={"/colors"}>{t("Colors")}</Link>
                </li>
                <li className="sub-svg">
                  <Link to={"/Inspiration"}>{t("Inspiration")}</Link>

                  <section className="inspiration-sub-menu submenu-container">
                    <div className="rtl-list">
                      <div className="list">
                        <Link to="/inspiration" className="category-subtitle">
                          {t("Overview")}
                        </Link>
                      </div>
                      <div className="list">
                        <Link
                          to="/inspiration/interior"
                          className="category-subtitle"
                        >
                          {t("Interior")}
                        </Link>
                      </div>
                      <div className="list">
                        <Link
                          to="/inspiration/exterior"
                          className="category-subtitle"
                        >
                          {t("Exterior")}
                        </Link>
                      </div>
                    </div>
                  </section>
                </li>
                <li>
                  <Link to={"/products/list"}>{t("Products")}</Link>
                  {/* <section className="products-sub-menu submenu-container">
                    <div className="rtl-list">
                      <div className="list">
                        <Link to={"/products-intro"}>{t("Products")}</Link>
                      </div>
                      <div className="list">
                        <Link
                          to="/products/paint-calculator"
                          className="category-subtitle"
                        >
                          <i className="fa fa-calculator"></i>
                          {t("Paint Calculator")}
                        </Link>
                      </div>
                    </div>
                  </section> */}
                </li>
                {/* <li>
                  <Link to="/stores" className="category-title">
                    {t("Stores")}
                  </Link>
                </li>
                <li className="sub-svg">
                  <Link to="/about-us" className="category-title">
                    {t("About us")}
                  </Link>
                  <section className="products-sub-menu submenu-container">
                    <div className="about-us-list">
                      <div className="list">
                        {i18n.language === "ar" ? (
                          <HashLink smooth to={"/ar/about-us/#our-story"}>
                            {t("Our Story")}
                          </HashLink>
                        ) : (
                          <HashLink smooth to={"/en/about-us/#our-story"}>
                            {t("Our Story")}
                          </HashLink>
                        )}
                      </div>
                      <div className="list">
                        {i18n.language === "ar" ? (
                          <HashLink
                            smooth
                            to="/ar/about-us/#mission"
                            className="category-subtitle"
                          >
                            {t("Our Mission")}
                          </HashLink>
                        ) : (
                          <HashLink
                            smooth
                            to="/en/about-us/#mission"
                            className="category-subtitle"
                          >
                            {t("Our Mission")}
                          </HashLink>
                        )}
                      </div>
                      <div className="list">
                        {i18n.language === "ar" ? (
                          <HashLink
                            smooth
                            to="/ar/about-us/#values"
                            className="category-subtitle"
                          >
                            {t("Our Values")}
                          </HashLink>
                        ) : (
                          <HashLink
                            smooth
                            to="/en/about-us/#values"
                            className="category-subtitle"
                          >
                            {t("Our Values")}
                          </HashLink>
                        )}
                      </div>
                      <div className="list">
                        {i18n.language === "ar" ? (
                          <HashLink
                            smooth
                            to="/ar/about-us/#careers"
                            className="category-subtitle"
                          >
                            {t("Careers")}
                          </HashLink>
                        ) : (
                          <HashLink
                            smooth
                            to="/en/about-us/#careers"
                            className="category-subtitle"
                          >
                            {t("Careers")}
                          </HashLink>
                        )}
                      </div>
                    </div>
                  </section>
                </li>

                <li>
                  <Link to="/news" className="category-title">
                    {" "}
                    {t("News")}
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className="col-md-2 searchbox">
              <form onSubmit={this.search}>
                <Link to="/stores">
                  <img src="/assets/img/store.svg" alt=""/>
                </Link> 
                <i className="fa fa-search padding-right ml-1"></i>
                {i18n.language === "ar" ? (
                  <input
                    value={this.state.searchQuery}
                    onChange={e =>
                      this.setState({ searchQuery: e.target.value })
                    }
                    type="search"
                    placeholder="ابحث"
                  />
                ) : (
                  <>
                    <input
                      value={this.state.searchQuery}
                      onChange={e =>
                        this.setState({ searchQuery: e.target.value })
                      }
                      type="search"
                      placeholder="search"
                    />
                  </>
                )}
              </form>
              <div className="change-lang">
                <ul>
                  <li>
                    {i18n.language === "ar" ? (
                      <span
                        onClick={() => {
                          this.changeLang("en");
                        }}
                      >
                        ENG
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          this.changeLang("ar");
                        }}
                      >
                        عربي
                      </span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mobile-nav">
            <Navbar expand="lg">
              <Navbar.Brand>
                <Link to="/">
                  <img
                    itemProp="logo"
                    className="logo"
                    src={"/./assets/img/logo.png"}
                    alt={t("GLC Paints Logo")}
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="mob-nav2">
                <Nav className="mr-auto">
                  <Nav.Link href={`/${i18n.language}/ambiance`}>
                    {t("Decorative Finishes")}
                  </Nav.Link>
                  <Nav.Link href={`/${i18n.language}/colors`}>
                    {t("Colors")}
                  </Nav.Link>
                  <Nav.Link href={`/${i18n.language}/Inspiration`}>
                    {t("Inspiration")}
                  </Nav.Link>
                  <Nav.Link
                    href={`/${i18n.language}/products/list`}
                    className="category-title"
                  >
                    {t("Products")}
                  </Nav.Link>
                  {/* <Nav.Link
                    href={`/${i18n.language}/products/paint-calculator`}
                    className="category-title"
                  >
                    {t("Paint Calculator")}
                  </Nav.Link> */}
                  {/* <Nav.Link
                    href={`/${i18n.language}/stores`}
                    className="category-title"
                  >
                    {t("Stores")}
                  </Nav.Link> */}
{/* 
                  <Nav.Link href={`/${i18n.language}/about-us`}>
                    {t("About us")}
                  </Nav.Link> */}
                  {/* <Nav.Link
                    href={`/${i18n.language}/news`}
                    className="category-title"
                  >
                    {t("News")}
                  </Nav.Link> */}
                  <Nav.Link href={`/${i18n.language}/search`}>
                    {t("Search")}
                  </Nav.Link>
                  <div className="mob-change-lang">
                    <ul>
                      <li>
                        {i18n.language === "ar" ? (
                          <span
                            onClick={() => {
                              this.changeLang("en");
                            }}
                          >
                            ENG
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              this.changeLang("ar");
                            }}
                          >
                            عربي
                          </span>
                        )}
                      </li>
                    </ul>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withTranslation("common")(Header));
