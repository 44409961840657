import React from "react";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import "./GlcMarker.scss";

// InfoWindow component
const InfoWindow = props => {
  const { store } = props;
  return (
    <div className={`${store.show && "bounce"}  info-window`}>
      <div className="info-window-store-name">{store.name}</div>
      <div className="info-window-get-direction">
        <a
          href={store.get_diretion_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {i18n.language === "en" ? "Get Direction" : "كيف تصل"}
        </a>
      </div>
      <div className="trinagle"></div>
    </div>
  );
};
const GlcMarker = props => {
  const { id, title, lat, lng, store } = props;
  return (
    <div
      className={`${store.show && store.country === 1 && "bounce"} pin `}
      id={id}
      key={id}
      title={title}
      lat={lat}
      lng={lng}
    >
      {(props.title ===
        "54 St., Block 13027,First Industrial Zone El Obour - Egypt" && (
        <div className="glc-hq">
          <img
            className="hq-img"
            src={require("./../../../assets/img/stores/GLC-office.svg")}
            alt="GLC Paints HQ"
          />
          {store.show && <InfoWindow store={props.store} />}
        </div>
      )) ||
        (props.title === "My Location" && (
          <div className="blue-circle">
            {store.show && <InfoWindow store={props.store} />}
          </div>
        )) ||
        (props.title !==
          "54 St., Block 13027,First Industrial Zone El Obour - Egypt" &&
          props.title !== "My Location" && (
            <div>
              <img
                src={require("./../../../assets/img/stores/GLC-shop.png")}
                alt="GLC Paints Store"
              />
              {store.show && <InfoWindow store={props.store} />}
            </div>
          ))}
    </div>
  );
};

export default withTranslation("common")(GlcMarker);
