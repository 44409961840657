import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../../const/const";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import "../../homepage/HomePage.scss";
import "./ColorPsychology.scss";
import $ from "jquery";

$(document).ready(function() {
  $(document).on("contextmenu", ".dynamic-img", function(e) {
    return false;
  });
});
class ColorItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      pageContent: { colors: [] },
      selectedItem: {},
      fullImage: null,
      showDetails: false
    };
  }
  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const result = [];
    tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));

    return result;
  };
  componentDidMount() {
    const thisVar = this;
    AxiosInstance.get("api/page/psychology").then(function(response) {
      document.getElementById("main-head").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
      const selectedItem = response.data.colors[0];
      selectedItem.isSelected = true;
      const fullImage = IMG_URL + response.data.coloring_image;
      thisVar.setState({
        isLoaded: true,
        pageContent: response.data,
        selectedItem,
        fullImage
      });
    });
  }

  selectBrush = e => {
    const brushIndex = e.target.attributes.brushindex.value;
    let { pageContent, selectedItem } = this.state;
    this.setSelected(selectedItem, pageContent.colors[brushIndex]);
  };

  setSelected = (oldItem, newItem) => {
    oldItem.isSelected = false;
    newItem.isSelected = true;
    this.setState({ selectedItem: newItem, showDetails: true });
  };

  goBack = () => {
    this.setState({ showDetails: false });
  };

  setAsFullImg = e => {
    const imgSrc = e.target.attributes.src;
    if (imgSrc) {
      this.setState({
        fullImage: imgSrc.value
      });
    } else {
      this.setState({
        fullImage: null
      });
    }
  };

  renderDo() {
    const { t } = this.props;
    const { pageContent, selectedItem, fullImage, showDetails } = this.state;
    return (
      <div>
        <Helmet>
          <title>GLC Paints | Color Psychology</title>

          <meta name="description" content="WHAT COLOR IS YOUR ATTITUDE" />
        </Helmet>
        <section
          className="dynamic-img amb-item-bg"
          style={{
            backgroundImage: `url(${IMG_URL + pageContent.banner_image})`
          }}
        >
          <div className="amb-item-title">
            <h1>{pageContent.title}</h1>
            <p>{pageContent.subtitle}</p>
          </div>
        </section>
        <section className="main-bg padding-40 color-pscho">
          <Container fluid space="true">
            <Row>
              <Col lg={6} md={12}>
                <div
                  className={(showDetails ? "hidden " : "") + "palette fade-in"}
                >
                  <div className="color-title">{t(selectedItem.name)}</div>
                  <div className="color-code">{selectedItem.color_code}</div>
                  <div className="slider-container">
                    <section className="box colors-items-area">
                      <Container fluid>
                        <Row className="brush-row">
                          {pageContent.colors.map((item, brushIndex) => (
                            <Col
                              className={
                                "brush " + (item.isSelected ? "selected" : "")
                              }
                              key={brushIndex}
                              xs={{ size: 4 }}
                            >
                              <img
                                brushindex={brushIndex}
                                onClick={this.selectBrush}
                                src={IMG_URL + item.image}
                                className="bg blog-thumb"
                                alt={item.hex_code}
                              />
                            </Col>
                          ))}
                        </Row>
                      </Container>
                    </section>
                  </div>
                </div>
                <div
                  className={
                    (showDetails ? "" : "hidden ") + "color-description fade-in"
                  }
                >
                  <img
                    src={IMG_URL + selectedItem.image}
                    className=""
                    alt={selectedItem.hex_code}
                  />
                  <div className="title">
                    <span
                      onClick={this.goBack}
                      className="back-button arrow-mobile-on"
                    >
                      <img
                        src={
                          "../../../assets/img/homepage/icons/arrow-slider-left-grey.svg"
                        }
                        alt={t("Left Arrow")}
                      />
                    </span>
                    <span className="selec-color">{t(selectedItem.name)}</span>
                  </div>
                  <div className="description">
                    <p>
                      {this.extractParagraphTexts(selectedItem.description)}
                    </p>
                  </div>
                  <div
                    onClick={this.goBack}
                    className="back-button arrow-mobile-off"
                  >
                    <img
                      src={
                        "../../../assets/img/homepage/icons/arrow-slider-left-grey.svg"
                      }
                      alt={t("Left Arrow")}
                    />
                  </div>
                </div>
              </Col>
              <Col className="" lg={6} md={12}>
                <Row>
                  <Col
                    className="full-width-image"
                    md={12}
                    style={{ backgroundColor: selectedItem.hex_code }}
                  >
                    <img
                      src={IMG_URL + pageContent.coloring_image}
                      className={
                        "dynamic-img bg blog-thumb full-image " +
                        (fullImage ? "" : "hide")
                      }
                      alt={selectedItem.name}
                    />
                  </Col>
                  <div className="small-images-container">
                    <img
                      onClick={this.setAsFullImg}
                      style={{ backgroundColor: selectedItem.hex_code }}
                      src={IMG_URL + pageContent.coloring_image}
                      className="dynamic-img small-images blog-thumb"
                      alt={selectedItem.name}
                    />
                    <div
                      onClick={this.setAsFullImg}
                      className="small-images"
                      style={{ backgroundColor: selectedItem.hex_code }}
                    ></div>
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }

  render() {
    const { isLoaded } = this.state;

    if (!isLoaded) {
      return <Loader />;
    } else {
      return <div>{this.renderDo()}</div>;
    }
  }
}

export default withTranslation("common")(ColorItems);
