import React, { Component } from "react";
import { IMG_URL } from "../../const/const";
import Link from "../../components/common/link/link";
import "./HomePage.scss";
import { withTranslation } from "react-i18next";
import $ from "jquery";

$(document).ready(function() {
  $(document).on("contextmenu", ".dynamic-img", function(e) {
    return false;
  });
});
class HomepageBoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tableRows: props.data
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="grid-two-column homepepage-mt-mb">
          <div className="dynamic-img scale home-margin">
            <Link to={`/colors/trends/2023`}>
              <img
                src={IMG_URL + this.state.tableRows.trends_image}
                className="bg "
                alt={t("Little tree branch with Geometric forms")}
              />
              <span className="homepage-overlay-bg"></span>
              <div className="scale-content trends">
                <h2 className="home-h1">{t("Trends")}</h2>
                <p>{t(this.state.tableRows.trends_subtext)}</p>
              </div>
            </Link>
          </div>
          <div className="dynamic-img  scale home-margin">
            <Link to="/inspiration">
              <img
                src={IMG_URL + this.state.tableRows.inspiration_image}
                className="bg dynamic-img"
                alt={t("A house with modern style surrounded by trees")}
              />
              <span className="homepage-overlay-bg"></span>
              <div className="scale-content">
                <h2 className="home-h1">{t("Inspiration")}</h2>
                <p>{t(this.state.tableRows.inspiration_subtext)}</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(HomepageBoxes);
