import React, { Component } from "react";
import HomepageBoxes from "./HomapageBoxes";
import "./HomePage.scss";

class Content extends Component {
  render() {
    return (
      <div>
        <main>
          <section>
            <HomepageBoxes data={this.props.data} />
          </section>
        </main>
      </div>
    );
  }
}

export default Content;
