import React, { Component } from "react";
import AxiosInstance, { Loader } from "../../const/const";
import UserForm from "./UserForm/UserForm";
import CareersForm from "./CareersForm/CareersForm";
import AboutUsSlider from "./AboutUsSlider";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import "./AboutUs.scss";
import i18n from "i18next";

const FormUser = {
  mail: "",
  name: "",
  phone: "",
  city_id: null
};
const CareerUser = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  city_id: null,
  position: "",
  address: ""
};

class AboutUs extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tableRows: {}
    };
  }

  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const result = [];
    tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));

    return result;
  };

  componentDidMount() {
    var thisVar = this;
    thisVar._isMounted = true;
    AxiosInstance.get("api/about").then(function(response) {
      document.getElementById("main-head").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
      if (thisVar._isMounted) {
        thisVar.setState({
          isLoaded: true,
          tableRows: response.data
        });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { isLoaded, tableRows } = this.state;
    const { t } = this.props;

    if (!isLoaded) {
      return <Loader />;
    } else {
      return (
        <div className="about space">
          <Helmet>
            <title>GLC Paints | About Us</title>
            <meta property="og:title" content="About GLC Paints" />
            <meta
              property="og:image"
              content="http://www.glcpaints.com/assests/img/about/Mission-values-background.jpg"
            />

            <meta
              name="description"
              content={t(
                "GLC is an established full solution paint provider. We are guardians of our customers most valuable assets."
              )}
            />
            <meta name="keywords" content="Values Story Mission Vision " />
          </Helmet>
          <div className="about-full-img">
            <section id="our-story" name="section1">
              <div className="about-content">
                <div className="overlay">
                  <Container fluid className="about-text">
                    <Row>
                      <Col xs="12" sm="12" md="6">
                        <h1>{t(tableRows.title)}</h1>
                      </Col>
                    </Row>
                    {
                      <Row>
                        <Col xs="12" sm="12" className="about-margin">
                          <AboutUsSlider />
                        </Col>
                      </Row>
                    }
                  </Container>
                </div>
              </div>
            </section>

            <div>
              <section
                itemScope
                name="section2"
                id="mission"
                className="amb-item-content pt-40 box-back-color our-mission"
              >
                <div className="boxx">
                  <div className="scale">
                    <img
                      src={require("../../assets/img/about/our-mission.jpg")}
                      alt={t("GLC paints mission")}
                    />
                    <span className=""></span>
                    <div id="our-mission" className="scale-content">
                      <h2>{t("Our Mission")}</h2>
                      {i18n.language === "en" ? (
                        <p>
                          To deliver consistent <br></br> trusted paint
                          solutions
                        </p>
                      ) : (
                        <p>في GLC هدفنا هو تقديم حلول للدهانات تستحق الثقة.</p>
                      )}
                    </div>
                  </div>
                  <div className="content">
                    <div className="about-row-content scale-content-left ">
                      {i18n.language === "en" ? (
                        <div>
                          <p>
                            <b>GLC </b>
                            is an established full solution paint provider. We
                            are guardians of our customers most valuable assets.
                          </p>
                          <p>
                            We strive to maintain this through continuous hard
                            work to clearly understand our clients needs, and
                            constantly improve our performance. We are there
                            every step of the way to advise, support and provide
                            the products that our customers require.
                          </p>
                          <p>
                            We are a team of highly qualified professionals,
                            whose constant training and development are
                            paramount to our existence. We implement effective
                            business processes that deliver results.
                          </p>
                          <p>
                            We treat people fairly, with respect and dignity. We
                            reward contribution and achievement and are strictly
                            committed to our values of social responsibility,
                            integrity and safety.
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p>
                            تعد شركة <b>GLC</b> من رواد مصنعي الدهانات ذات
                            الجودة العالية.نحن نحمي أصول عملائنا القيمة
                          </p>

                          <p>
                            نسعى دائماً للحفاظ على هذا من خلال مواصلة العمل
                            الدؤوب لفهم إحتياجات عملائنا، وتطوير معايير أدائنا
                            بإستمرار. نحن هناك في كل خطوة لتقديم المشورة والدعم
                            وتقديم المنتجات التي يطلبها عملاؤنا.
                          </p>
                          <p>
                            نحن فريق من المهنيين المؤهلين، الذين يعد تدريبهم
                            وتطويرهم من بين أهم أولوياتنا. نقوم بإدارة العمليات
                            بطريقة فعالة تحقق النتائج المرغوبة.
                          </p>
                          <p>
                            نعامل الناس بإنصاف وإحترام وكرامة. ونكافئ المساهمات
                            والإنجازات ونلتزم إلتزامًا صارمًا بقيمنا الخاصة
                            بالمسؤولية الإجتماعية والنزاهة والسلامة.
                          </p>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-md-5">
                          <div className="our-promise">{t("Our Promise")}</div>
                        </div>
                        <div className="col-md-7">
                          <div className="our-promise-details">
                            {t(
                              ' " To deliver consistent trusted paint solutions "'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                itemScope
                className="amb-item-content box-back-color our-vision"
              >
                <div className="boxx">
                  <div className="">
                    <div className="about-row-content scale-content-left scale-responsive">
                      {i18n.language === "en" ? (
                        <div>
                          <p>
                            Leading the <b>paint </b>
                            <br /> industry in
                          </p>
                          <p className="egypt">
                            Egypt, ME
                            <br />& Africa
                          </p>
                          <p>
                            region by becoming the supplier of choice to all
                            customers."
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p>
                            <br />
                            أن نكون أكبر موردي <b>الدهانات</b> في
                          </p>
                          <p className="egypt">
                            جمهورية مصر العربية والشرق الأوسط
                            <br />
                            وافريقيا
                          </p>
                          <p>وأن نصبح الموردين المفضلين للدهانات.</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="scale">
                    <img
                      src={require("../../assets/img/about/our-vision.jpg")}
                      alt={t("GLC paints vision")}
                    />
                    <span className="content"></span>
                    <div className="scale-content" id="our-vision">
                      <h2>{t("Our Vision")}</h2>
                    </div>
                  </div>
                </div>
              </section>
              <section
                itemScope
                name="section3"
                id="values"
                className="amb-item-content box-back-color our-values"
              >
                <div className="boxx">
                  <div className="scale">
                    <img
                      src={require("../../assets/img/about/our-values.jpg")}
                      alt={t("GLC paints values")}
                    />
                    <div id="our-values" className="scale-content">
                      <h2>{t("Our Values")}</h2>
                      {i18n.language === "en" ? (
                        <p>
                          Our values constitute our ethical code of conduct in
                          doing business. They guide us in all our actions and
                          remain intact in all our markets.
                        </p>
                      ) : (
                        <p>
                          قيمنا هي جوهر هويتنا. هذا ما نؤمن به حقاً وهو ما يدفع
                          طريقة عملنا، هنا في GLC كل يوم
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="our-values-boxes scale-amb-item">
                    <div className="scale-content circle-container">
                      <Row className="circle-group">
                        <input id="item1" type="checkbox" />
                        <label className="item1 values-content" htmlFor="item1">
                          <div>{t("Trust")}</div>
                          <div>
                            <p className="v-title">{t("Trust")}</p>

                            {i18n.language === "en" ? (
                              <p className="box-content fade-in">
                                Every business relationship is built on trust.
                                Trust in our partners, our customers, and
                                ourselves. We trust our employees in maintaining
                                proper business ethics, and in delivering our
                                promise of product excellence and impeccable
                                service.
                              </p>
                            ) : (
                              <p className="box-content fade-in">
                                كل علاقة تجارية مبنية على الثقة. ثقة في الأشخاص
                                الذين نعمل معهم وثقة بأنفسنا وموظفينا الذين
                                يحتاجون إلى عكس أخلاقيات العمل المناسبة والحفاظ
                                على وعدنا بتقديم أفضل الخدمات وأرقى المنتجات.
                              </p>
                            )}

                            <p>
                              {
                                <img
                                  src={require("../../assets/img/homepage/icons/arrow-left.svg")}
                                  alt={t("Left Arrow")}
                                />
                              }
                            </p>
                          </div>
                        </label>

                        <input id="item2" type="checkbox" />
                        <label className="item2" htmlFor="item2">
                          <div>{t("Reliability")}</div>
                          <div>
                            <p className="v-title">
                              <b>{t("Reliability")}</b>
                            </p>
                            {i18n.language === "en" ? (
                              <p className="box-content fade-in">
                                We consistently strive to produce world-class
                                products. The durability and sustainability of
                                our products is what set us apart from our
                                competitors and will continue to guarantee our
                                leadership in any market we enter.
                              </p>
                            ) : (
                              <p className="box-content fade-in">
                                جودة منتجاتنا هي في صلب تركيزنا. المتانة
                                والإستدامة في دهاناتنا هو ما يميزنا عن منافسينا
                                ، ونحن نسعى بإستمرار لإنتاج دهانات وفق المعايير
                                العالمية. نحن نقدم أفضل الحلول المتخصصة وفقاً
                                لإحتياجات كل عميل.
                              </p>
                            )}

                            <p>
                              {
                                <img
                                  src={require("../../assets/img/homepage/icons/arrow-left.svg")}
                                  alt={t("Left Arrow")}
                                />
                              }
                            </p>
                          </div>
                        </label>

                        <input id="item3" type="checkbox" />
                        <label className="item3" htmlFor="item3">
                          <div>{t("Innovation")}</div>
                          <div>
                            <p className="v-title">{t("Innovation")}</p>
                            {i18n.language === "en" ? (
                              <p className="box-content fade-in">
                                We do not settle for the present situation, but
                                are dynamic, flexible and ever-adapting in the
                                search for new solutions for our customers’
                                needs. We constantly research the latest
                                materials and use the most up-to-date
                                technologies in the production of our paints.
                              </p>
                            ) : (
                              <p className="box-content fade-in">
                                نحن نسعى جاهدين لتقديم حلول الدهانات الأكثر
                                تطوراً لعملائنا. نحن نتكيف بمرونة وسرعة، ونتطلع
                                دائماً إلى كيفية تحسين تجربة عملائنا. نحن نبحث
                                بإستمرار عن أحدث المواد ونستخدم أحدث تقنيات
                                الإنتاج.
                              </p>
                            )}

                            <p>
                              {
                                <img
                                  src={require("../../assets/img/homepage/icons/arrow-left.svg")}
                                  alt={t("Left Arrow")}
                                />
                              }
                            </p>
                          </div>
                        </label>

                        <input id="item4" type="checkbox" />
                        <label className="item4" htmlFor="item4">
                          <div>{t("Passion")}</div>
                          <div>
                            <p className="v-title">{t("Passion")}</p>
                            {i18n.language === "en" ? (
                              <p className="box-content fade-in">
                                We are passionate about our brand, our customers
                                and above all, our coatings. We never settle for
                                anything but the best in all areas, constantly
                                pushing boundaries with strong drive, clear
                                vision, and benevolent ambition.
                              </p>
                            ) : (
                              <p className="box-content fade-in">
                                نحن شغوفون بعلامتنا التجارية، وبعلاقتنا مع
                                عملائنا وشغفنا الأكبر هو بمنتجاتنا. نحن لا نرضى
                                بما هو أقل من ممتاز. نحن في تحدي دائم لتوفير
                                أفضل المنتجات وأرقى الخدمات.
                              </p>
                            )}

                            <p>
                              {
                                <img
                                  src={require("../../assets/img/homepage/icons/arrow-left.svg")}
                                  alt={t("Left Arrow")}
                                />
                              }
                            </p>
                          </div>
                        </label>
                      </Row>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div itemScope className="about-video">
              <div className="video-container">
                <div className="iframe-wrapper">
                  {i18n.language === "en" ? (
                    <iframe
                      className="about-us-iframe"
                      src="https://www.youtube.com/embed/TTG7LtsdJAM?rel=0"
                      frameBorder="0"
                      title="about-us-video"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  ) : (
                    <iframe
                      className="about-us-iframe"
                      src="https://www.youtube.com/embed/NxadM3Re1Co?rel=0"
                      frameBorder="0"
                      title="about-us-video"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="work-with-us pt-120 pb-40 about-big-title space blog-tabs">
            <div className="about-title-center pb-40">
              <h2>{t("work with us")}</h2>
              <p className="text-center">
                {t(
                  "We are constantly looking to partner with people who understand and value our culture, if you have what it takes, join the family"
                )}
              </p>
              <h4>{t("Become a Distributor")}</h4>
              <section itemScope name="section4" id="become-distributor">
                <Container className="tab-content">
                  <Row>
                    <Col sm={12}>
                      <UserForm user={FormUser} />
                    </Col>
                  </Row>
                </Container>
              </section>
            </div>
          </div>
          <div
            itemScope
            className="work-with-us pt-120 pb-40 about-big-title space blog-tabs"
          >
            <div className="about-title-center pb-40">
              <h2>{t("Career")}</h2>
              <p className="text-center">
                {t(
                  "We are a diverse work force that is constantly looking to expand, join us if you think you share our values"
                )}
              </p>
              <h4 className="join-us">{t("Join  Us")}</h4>
              <section id="careers">
                <Container className="tab-content">
                  <Row>
                    <Col sm={12}>
                      <CareersForm user={CareerUser} />
                    </Col>
                  </Row>
                </Container>
              </section>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation("common")(AboutUs);
