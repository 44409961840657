import React, { Component } from "react";
import { IMG_URL } from "../../const/const";
import makeCarousel from "react-reveal/makeCarousel";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import $ from "jquery";

$(document).ready(function() {
  $(document).on("contextmenu", ".dynamic-img", function(e) {
    return false;
  });
});
class ColorsData extends Component {
  constructor(props) {
    super(props);
    const { colors } = props.data;
    const slides = this.prepareSlides(colors);
    const selectedItem = slides[0].items[0];
    selectedItem.isSelected = true;
    this.state = {
      isLoaded: false,
      slides,
      selectedSlideIndex: 0,
      selectedItem,
      color: "",
      fullImage: IMG_URL + props.data.coloring_image
    };
  }

  componentDidMount() {
    let that = this;
    if (this.props.color !== undefined) {
      this.filterColorsAfterQuery(this.props.color);
      that.setState({ color: this.props.color });
    }
  }

  prepareSlides = colors => {
    const numOfItemsInSlide = 12; // 6 is number of items in one slide
    const slides = [];
    for (let i = 0; i < colors.length; i += numOfItemsInSlide) {
      slides.push({
        items: colors.slice(i, i + numOfItemsInSlide)
      });
    }
    return slides;
  };

  setSelected = (oldItem, newItem) => {
    oldItem.isSelected = false;
    newItem.isSelected = true;
    this.setState({ selectedItem: newItem });
  };

  selectBrush = brushIndex => {
    let { selectedSlideIndex, slides, selectedItem } = this.state;
    this.setSelected(
      selectedItem,
      slides[selectedSlideIndex].items[brushIndex]
    );
  };

  handlePreviousSlide = () => {
    const { selectedSlideIndex, slides, selectedItem } = this.state;
    let index = selectedSlideIndex - 1;
    if (parseInt(selectedSlideIndex) === 0) {
      index = slides.length - 1;
    }
    this.setSelected(selectedItem, slides[index].items[0]);
    this.setState({
      selectedSlideIndex: index
    });
  };

  handleNextsSlide = () => {
    const { selectedSlideIndex, slides, selectedItem } = this.state;
    let index = selectedSlideIndex + 1;
    if (parseInt(selectedSlideIndex) === slides.length - 1) {
      index = 0;
    }

    this.setSelected(selectedItem, slides[index].items[0]);
    this.setState(
      {
        selectedSlideIndex: index
      },
      () => {}
    );
  };

  CarouselUI = ({ position, handleClick, children }) => {
    const { t } = this.props;
    return (
      <div>
        {children}

        {this.state.slides.length > 1 ? (
          <div
            data-position={position - 1}
            className="arrow-button left-button"
            onClick={e => {
              this.handlePreviousSlide();
              handleClick(e);
            }}
          >
            <img
              src={require("../../assets/img/homepage/icons/arrow-slider-left-grey.svg")}
              alt={t("Left Arrow")}
            />
          </div>
        ) : (
          ""
        )}

        {this.state.slides.length > 1 ? (
          <div
            data-position={position + 1}
            className="arrow-button right-button"
            onClick={e => {
              this.handleNextsSlide();
              handleClick(e);
            }}
          >
            <img
              src={require("../../assets/img/homepage/icons/arrow-slider-right-grey.svg")}
              alt={t("Right Arrow")}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  Carousel = makeCarousel(this.CarouselUI);

  renderSlider() {
    const { slides } = this.state;
    const { Carousel } = this;

    return (
      <Carousel defaultWait={1}>
        {slides.map((slide, i) => (
          <Fade big key={i}>
            <Slide>
              <Row className="brush-row">
                {slide.items.map((item, brushIndex) => (
                  <Col
                    key={brushIndex}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="brush-cell"
                  >
                    <div
                      onClick={() => this.selectBrush(brushIndex)}
                      className={"brush " + (item.isSelected ? "selected" : "")}
                    >
                      <img
                        brushindex={brushIndex}
                        src={IMG_URL + item.brush_image}
                        className="bg blog-thumb"
                        alt={item.hex_code}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Slide>
          </Fade>
        ))}
      </Carousel>
    );
  }

  setAsFullImg = e => {
    const imgSrc = e.target.attributes.src;
    if (imgSrc) {
      this.setState({
        fullImage: imgSrc.value
      });
    } else {
      this.setState({
        fullImage: null
      });
    }
  };

  filterColorsAfterQuery = e => {
    let key = "";
    key = e;
    const { colors } = this.props.data;
    const filterdColors = colors.filter(color => {
      const lowerkey = key.toLowerCase();
      const name = color.name.toLowerCase();
      const code = color.color_code.toLowerCase();
      return name.includes(key) || code.includes(lowerkey);
    });
    const slides = this.prepareSlides(filterdColors);
    this.state.selectedItem.isSelected = false;
    let selectedItem = {};
    if (slides.length > 0) selectedItem = slides[0].items[0];
    selectedItem.isSelected = true;
    this.setState({
      slides,
      selectedSlideIndex: 0,
      selectedItem
    });
  };
  filterColorsQueryOnChange = e => {
    let key = "";
    key = e.target.value;
    const { colors } = this.props.data;
    const filterdColors = colors.filter(color => {
      const lowerkey = key.toLowerCase();
      const name = color.name.toLowerCase();
      const code = color.color_code.toLowerCase();
      return name.includes(key) || code.includes(lowerkey);
    });
    const slides = this.prepareSlides(filterdColors);
    this.state.selectedItem.isSelected = false;
    let selectedItem = {};
    if (slides.length > 0) selectedItem = slides[0].items[0];
    selectedItem.isSelected = true;
    this.setState({
      slides,
      selectedSlideIndex: 0,
      selectedItem,
      color: ""
    });
  };
  filterColors = e => {
    const key = e.target.value;
    const { colors } = this.props.data;
    const filterdColors = colors.filter(color => {
      const lowerkey = key.toLowerCase();
      const name = color.name.toLowerCase();
      const code = color.color_code.toLowerCase();
      return name.includes(key) || code.includes(lowerkey);
    });
    const slides = this.prepareSlides(filterdColors);
    this.state.selectedItem.isSelected = false;
    let selectedItem = {};
    if (slides.length > 0) selectedItem = slides[0].items[0];
    selectedItem.isSelected = true;

    this.setState({
      slides,
      selectedSlideIndex: 0,
      selectedItem
    });
  };
  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    let result = [];
    if (tempDiv.innerHTML.includes("<p")) {
      tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));
    } else {
      result.push(html);
    }
    let result2 = result.splice(0, 1)[0];
    let temp = result2.split("");
    if (temp[0] === ".") {
      temp.splice(0, 1);
      temp.push(".");
      let result3 = temp.join("");
      return result3;
    } else {
      return result2;
    }
  };

  render() {
    const { t } = this.props;
    const { brochure_view_url, pdf_file } = this.props.data;
    const { selectedItem, fullImage, slides, selectedSlideIndex } = this.state;
    return (
      <div>
        <section id="color-data" className="main-bg colors padding-40">
          <Container fluid>
            <Row>
              <Col className="" lg={6} md={12}>
                <div className="color-title">
                  <div className="row">
                    <div className="col-9 color-big-title">
                      <span itemProp="name">{selectedItem.name}</span>
                    </div>
                    <div className="col-3 slide-number">
                      {selectedSlideIndex + 1} / {slides.length}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-8">
                    <div className="color-code">
                      <span itemProp="identifier"></span>
                      {selectedItem.color_code}
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4">
                    {this.props.color !== undefined ? (
                      <div className="search-input">
                        <i className="fa fa-search"></i>
                        <input
                          className=""
                          onChange={this.filterColorsQueryOnChange}
                          type="search"
                          placeholder={t("Color Name/Code")}
                          value={this.state.color}
                        />
                      </div>
                    ) : (
                      <div className="search-input">
                        <i className="fa fa-search"></i>
                        <input
                          className=""
                          onChange={this.filterColors}
                          type="search"
                          placeholder={t("Color Name/Code")}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="slider-container">
                  {slides.length > 0 && this.renderSlider()}
                </div>

                <div className="color-description">
                  <p>
                    {this.extractParagraphTexts(this.props.data.description)}
                  </p>
                </div>
                {/* {brochure_view_url != null && pdf_file != null ? (
                  <div className="pdf-download-wrapper">
                    <div>
                      <h6>{t("Platine Color Card")}</h6>
                      {brochure_view_url != null ? (
                        <a
                          className="pdfdownload"
                          href={brochure_view_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require("../../assets/img/homepage/icons/pdf-view.svg")}
                            alt={t("View PDF Brochure")}
                          />
                          {t("VIEW")}
                        </a>
                      ) : (
                        ""
                      )}
                      {pdf_file != null ? (
                        <a
                          className="pdfdownload"
                          href={IMG_URL + pdf_file}
                          target="_blank"
                          download
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require("../../assets/img/homepage/icons/pdf-download.svg")}
                            alt={t("Download PDF Brochure")}
                          />
                          {t("DOWNLOAD")}
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
              </Col>
              <Col className="" lg={6} md={12}>
                <Row>
                  <Col
                    className="full-width-image"
                    style={{ backgroundColor: selectedItem.hex_code }}
                    md={12}
                  >
                    <img
                      src={IMG_URL + this.props.data.coloring_image}
                      className={
                        "dynamic-img bg blog-thumb full-image " +
                        (fullImage ? "" : "hide")
                      }
                      alt={selectedItem.name}
                    />
                  </Col>
                  <div className="small-images-container">
                    <img
                      onClick={this.setAsFullImg}
                      src={IMG_URL + this.props.data.coloring_image}
                      style={{ backgroundColor: selectedItem.hex_code }}
                      className="dynamic-img small-images blog-thumb"
                      alt={selectedItem.name}
                    />
                    <div
                      onClick={this.setAsFullImg}
                      className="small-images"
                      style={{ backgroundColor: selectedItem.hex_code }}
                    ></div>
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default withTranslation("common")(ColorsData);
