import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import Link from "../../components/common/link/link";
import { Helmet } from "react-helmet";
import "./Ambiance.scss";
import $ from "jquery";

$(document).ready(function() {
  $(document).on("contextmenu", ".dynamic-img", function(e) {
    return false;
  });
});
class Ambiance extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.store = this.props.store;
    this.state = {
      isLoaded: false,
      tableRows: [],
      pageInfo: {}
    };
  }

  extractParagraphTexts = html => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const result = [];
    tempDiv.querySelectorAll("p").forEach(e => result.push(e.textContent));

    return result;
  };
  componentDidMount() {
    const that = this;
    that._isMounted = true;
    AxiosInstance.get("api/ambience").then(function(response) {
      document.getElementById("main-head").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
      if (that._isMounted) {
        that.setState({
          tableRows: response.data
        });
      }

      AxiosInstance.get("api/page/ambiance").then(function(response) {
        if (that._isMounted) {
          that.setState({
            isLoaded: true,
            pageInfo: response.data
          });
        }
      });
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { isLoaded, pageInfo } = this.state;
    if (!isLoaded) {
      return <Loader />;
    } else {
      return (
        <div>
          <Helmet>
            <title>GLC Paints | Ambience</title>
            <meta property="og:title" content={pageInfo.seo_title} />
            <meta
              property="og:image"
              content={IMG_URL + pageInfo.socialmedia_image}
            />
            <meta
              name="description"
              content={pageInfo.title + "-" + pageInfo.subtitle}
            />
            <meta
              name="keywords"
              content={pageInfo.title + "-" + pageInfo.subtitle}
            />
          </Helmet>
          <section className="main-bg">
            <div className="big-title">
              <div className="title-center">
                <h1 className="ambiance-h1"> {this.state.pageInfo.title} </h1>
                <p> {this.state.pageInfo.subtitle} </p>
              </div>
            </div>
            <div className="grid-container-padding small-space">
              <div className="parent">
                {this.state.tableRows.map((item, box) => (
                  <div className="dynamic-img col-12 amb-div" key={box}>
                    <Link to={`/ambiance/${item.slug}`}>
                      <img
                        src={IMG_URL + item.image}
                        className="ambiance-bg"
                        alt={item.slug}
                      />
                      <span className="ambiance-overlay-bg"> </span>
                      <div className="ambiance-scale-content">
                        <h2 className="ambiance-h"> {item.title} </h2>
                        <p> {item.excerpt} </p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
}

export default Ambiance;
