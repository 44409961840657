import './setupTests.js'
import React from "react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { render } from "react-snapshot";
import commonEn from "./assets/locales/en/common";
import commonAr from "./assets/locales/ar/common";

const resources = {
  en: {
    common: commonEn
  },
  ar: {
    common: commonAr
  }
};

i18n.use(initReactI18next).init({

  lng: process.env.lang,
  resources
});

render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
