import React from "react";

import "./Buttons.scss";

function handleClick() {
  // Reusable click events here
  console.log("Reusable button was clicked.");
}

function GlcButton(props) {
  const className = `glc-btn ${props.myStyle}`;
  return (
    <button className={className} onClick={handleClick}>
      {props.text}
    </button>
  );
}
GlcButton.defaultProps = {
  myStyle: "see-all-product"
};

export default GlcButton;
