import { Link } from "react-router-dom";
import i18n from "i18next";
import React from "react";

export default props => {
  const lang = i18n.language || "en";
  return (
    <Link {...props} to={"/" + lang + props.to}>
      {props.children}
    </Link>
  );
};
