import React, { Component } from "react";
import AxiosInstance, { AltAxiosInstance } from "../../../const/const";
import VirtualizedSelect from "react-virtualized-select";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import { withTranslation } from "react-i18next";
import "./CareersForm.scss";
import GlcButton from "../../../components/common/Buttons/Button";
import i18n from "i18next";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

class CareersForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      cities: [],
      positions: [],
      city: "",
      firstName: "",
      lastName: "",
      address: "",
      phoneNumber: "",
      email: "",
      file: "",
      position: "",
      touched: {},
      errorMessageEmail: "",
      errorMessagePhone: "",
      isEmailError: false,
      isPhoneError: false,
      trySubmit: false
    };
  }

  componentDidMount() {
    const that = this;
    that._isMounted = true;
    AxiosInstance.get("api/cities").then(function(response) {
      if (that._isMounted) {
        that.setState({
          cities: response.data.map(c => {
            return { label: c.name, value: c.id };
          })
        });
      }
    });

    AltAxiosInstance.get("GLCWebsiteService/GetAllPosition").then(function(
      response
    ) {
      setTimeout(function() {
        const lang = i18n.language;
        if (that._isMounted) {
          if (lang === "ar") {
            that.setState({
              positions: response.data.map(c => {
                return { label: c.ArbicName, value: c.ID };
              })
            });
          } else {
            that.setState({
              positions: response.data.map(c => {
                return { label: c.EnglishName, value: c.ID };
              })
            });
          }
        }
      }, 2000);
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = e => {
    e.preventDefault();
    if (!!this.recaptchaRef.current.getValue()) {
      const {
        firstName,
        lastName,
        address,
        phoneNumber,
        city,
        email,
        position,
        file,
        isEmailError,
        isPhoneError
      } = this.state;
      if (
        !firstName ||
        !phoneNumber ||
        !city ||
        !email ||
        !lastName ||
        !address ||
        !position ||
        !file ||
        isEmailError ||
        isPhoneError
      ) {
        return this.setState({ trySubmit: true });
      }

      const formData = new FormData();
      formData.append("", file);
      formData.append("FirstName", firstName);
      formData.append("LastName", lastName);
      formData.append("PhotoNumber", phoneNumber);
      formData.append("City", city.label);
      formData.append("Email", email);
      formData.append("Address", address);
      formData.append("Position", position.label);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      AltAxiosInstance.post("GLCWebsiteService/AddCareer", formData, config)
        .then(res => {
          i18n.language === "en"
            ? swal({
                title: "Done!",
                text: "Successfully added",
                icon: "success",
                timer: 3000,
                button: false
              })
            : swal({
                title: "شكرا",
                text: "تم إرسال الطلب بنجاح",
                icon: "success",
                timer: 3000,
                button: false
              });
          this.setState({
            phoneNumber: "",
            firstName: "",
            city: null,
            email: "",
            lastName: "",
            position: null,
            file: null,
            address: "",
            touched: {},
            trySubmit: false
          });
        })
        .catch(error => {});
    } else {
      this.recaptchaRef.current.execute();
    }
  };

  validateForm(input, inputType) {
    const { t } = this.props;

    if (inputType === "email") {
      if (typeof input !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(
          /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i
        );
        if (!pattern.test(input)) {
          this.setState({
            errorMessageEmail: `${t("*Please enter valid email")}`,
            isEmailError: true
          });
        } else {
          this.setState({
            errorMessageEmail: "",
            isEmailError: false
          });
        }
      }
    } else if (inputType === "phone") {
      if (!input.match(/^[0-9]*$/)) {
        this.setState({
          errorMessagePhone: `${t("*Only numbers are allowed")}`,
          isPhoneError: true
        });
      } else {
        this.setState({
          errorMessagePhone: "",
          isPhoneError: false
        });
      }
    }
  }

  handleBlur = e => {
    const { touched } = this.state;
    touched[e.target.name] = true;
    this.setState({
      touched
    });
  };

  render() {
    const { t } = this.props;
    const {
      touched,
      email,
      firstName,
      lastName,
      address,
      phoneNumber,
      city,
      position,
      file,
      trySubmit
    } = this.state;

    return (
      <form className="centered-form" onSubmit={this.handleSubmit}>
        <div className="form-group ">
          <input
            name="firstName"
            type="text"
            placeholder={t("FIRST NAME")}
            className={`form-control ${touched.firstName &&
              !firstName &&
              "is-invalid"} text-style about-input`}
            value={firstName}
            onBlur={this.handleBlur}
            onChange={e => this.setState({ firstName: e.target.value })}
          />
          {touched.firstName && !firstName && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}
        </div>
        <div className="form-group">
          <input
            name="lastName"
            type="text"
            placeholder={t("LAST NAME")}
            className={`form-control ${touched.lastName &&
              !lastName &&
              "is-invalid"} text-style about-input`}
            value={lastName}
            onBlur={this.handleBlur}
            onChange={e => this.setState({ lastName: e.target.value })}
          />
          {touched.lastName && !lastName && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}
        </div>
        <div className="form-group about-input">
          <VirtualizedSelect
            className={`form-control text-style ${trySubmit &&
              !position &&
              "is-invalid"} city-select`}
            name="position"
            placeholder={t("POSITION")}
            value={position}
            options={this.state.positions}
            onChange={e => this.setState({ position: e })}
          />
          {this.state.trySubmit && !position && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}
        </div>
        <div className="form-group ">
          <input
            name="phoneNumber"
            type="text"
            placeholder={t("PHONE NUMBER")}
            className={`form-control ${touched.phoneNumber &&
              !phoneNumber &&
              "is-invalid"} ${this.state.isPhoneError &&
              "is-invalid"}  text-style about-input`}
            value={phoneNumber}
            onBlur={this.handleBlur}
            onChange={e => {
              this.validateForm(e.target.value, "phone");
              this.setState({ phoneNumber: e.target.value });
            }}
          />
          {touched.phoneNumber && !phoneNumber && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}

          <div className="invalid-feedback">{this.state.errorMessagePhone}</div>
        </div>
        <div className="form-group ">
          <input
            name="address"
            type="text"
            placeholder={t("ADDRESS")}
            className={`form-control ${touched.address &&
              !address &&
              "is-invalid"} text-style about-input`}
            value={address}
            onBlur={this.handleBlur}
            onChange={e => this.setState({ address: e.target.value })}
          />
          {touched.address && !address && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}
        </div>

        <div className="form-group">
          <input
            name="email"
            type="text"
            placeholder={t("EMAIL ADDRESS")}
            className={`form-control ${touched.email &&
              !email &&
              "is-invalid"} ${this.state.isEmailError &&
              "is-invalid"} text-style about-input`}
            value={email}
            onBlur={this.handleBlur}
            onChange={e => {
              this.validateForm(e.target.value, "email");
              this.setState({ email: e.target.value });
            }}
          />
          {touched.email && !email && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}
          <div className="invalid-feedback">{this.state.errorMessageEmail}</div>
        </div>

        <div className="form-group about-input">
          <VirtualizedSelect
            className={`form-control text-style ${trySubmit &&
              !city &&
              "is-invalid"} city-select`}
            name="city"
            placeholder={t("CITY")}
            value={city}
            options={this.state.cities}
            onChange={e => this.setState({ city: e })}
          />
          {this.state.trySubmit && !city && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}
        </div>
        <div className="form-group">
          <h5 className="cv-upload">{t("Upload CV")}</h5>
          <input
            className={`form-control ${trySubmit &&
              !file &&
              "is-invalid"} input-style text-style upload-input about-input`}
            type="file"
            onChange={e => {
              this.setState({
                file: e.target.files[0]
              });
            }}
            onClick={e => (e.target.value = null)}
          />
          {this.state.trySubmit && !file && (
            <div className="invalid-feedback">{t("*Required")}</div>
          )}
        </div>
        <div>
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            sitekey="6LfhOeIUAAAAABhBnyHRmRzO_TiKvPuYyxMgh1gu"
          />
        </div>
        <GlcButton
          type="submit"
          myStyle="about-form-send-btn"
          text={t("Send")}
        />
      </form>
    );
  }
}

export default withTranslation("common")(CareersForm);
