import React, { Component } from "react";
import AxiosInstance, { IMG_URL, Loader } from "../../const/const";
import { withTranslation } from "react-i18next";
import "./Compare.scss";
import { Helmet } from "react-helmet";
import Link from "../../components/common/link/link";
import i18n from "i18next";

class Compare extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            product1: parseInt(this.props.match.params.p1),
            product2: parseInt(this.props.match.params.p2),
            product3: parseInt(this.props.match.params.p3),
            toBeCompared: [],
            favList: [],
        };

    }

    toggleFavorite(pId) {
        const favList = JSON.parse(localStorage.getItem("favorite")) || [];
        if (favList.includes(pId))
            favList.splice(favList.indexOf(pId), 1);
        else
            favList.push(pId);
        localStorage.setItem('favorite', JSON.stringify(favList));
        this.setState({ favList });
    }

    componentDidMount() {
        this._isMounted = true;
        const that = this;

        document.getElementById("main-head").scrollIntoView({
            // behavior: "smooth",
            block: "end",
            inline: "nearest"
        });

        this.setState({ favList: JSON.parse(localStorage.getItem("favorite")) || [] });

        AxiosInstance.get("api/products/flat")
            .then((response) => {
                const { product1, product2, product3 } = that.state;

                const products = response.data.filter(product => [product1, product2, product3].includes(product.id));
                that.setState({
                    toBeCompared: [...products, {}, {}, {}],
                    isLoaded: true
                });

            });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getProductHtml(product) {
        const { t } = this.props;

        if (product.title) {
            return (
                <div className="product-box">
                    <div className="product-actions">
                        <button className={`'fav-btn ' ${this.state.favList.includes(product.id) ? 'favorite' : ''}`} onClick={e => this.toggleFavorite(product.id)}>
                            <i className={`fa ${this.state.favList.includes(product.id) ? 'fa-heart': 'fa-heart-o'}`} aria-hidden="true"></i>
                        </button>
                        {/* <button className="remove-btn">
                            <img src="/assets/img/close.svg" alt="remove" />
                        </button> */}
                    </div>
                    <div className="product-img">
                        <img src={IMG_URL + product.image} alt="product" />
                    </div>
                    <div className="product-name d-none d-md-block">
                        <Link to={`/products/detail/${product.slug}`}>{product.title}</Link>
                        <div className="product-number">{product.product_number}</div>
                    </div>
                    {/* <div className="product-desc d-none d-md-block">
                        <div dangerouslySetInnerHTML={{__html: product.features}}></div>
                    </div> */}
                    {/* <div className="product-desc d-none d-md-block" dangerouslySetInnerHTML={{ __html: product.features }}></div> */}
                </div>
            );
        } else {
            return (
                <div className="product-box d-none d-md-block">
                    <div className="product-actions">
                        <button className={`'fav-btn ' ${this.state.favList.includes(product.id) ? 'favorite' : ''}`} onClick={e => this.toggleFavorite(product.id)}>
                            <i className={`fa ${this.state.favList.includes(product.id) ? 'fa-heart': 'fa-heart-o'}`} aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="text-center mt-2">
                        <img style={{height: '120px'}} src={"/assets/img/product.png"} alt="product" />
                    </div>
                    {/* <div className="product-name">
                        <Link to={`/products/detail/${product.slug}`}>{product.title}</Link>
                    </div> */}
                    <div className="empty product-desc">
                        <>
                            <p>
                                {t('A maximum of 3 products can be compared at a time')}
                            </p>
                            <div className="add-product">
                                <Link to="/products/list" className="add-product-btn">
                                    <img src="/assets/img/plus.svg" alt="" /> {t('Add product')}
                                </Link>
                            </div>

                        </>
                    </div>
                </div>
            );
        }


    }

    render() {

        const { t } = this.props;
        const lang = i18n.language;
        const { isLoaded, toBeCompared } = this.state;
        if (!isLoaded) {
            return <Loader />;
        } else {
            return (
                <div>
                    <Helmet>
                        <title>GLC Paints | {t('Compare')}</title>
                    </Helmet>
                    <div className="pt-5 pb-5"></div>
                    <div className="compare-page-container">
                        <section className="main-bg space bg-white">
                            <table className="compare-table">
                                <thead>
                                    <tr className="products-container">
                                        <td className="table-heading">{t('PRODUCT COMPARISON')}</td>
                                        {
                                            toBeCompared.slice(0, 3).map((product, index) => {
                                                return <td key={index}>{this.getProductHtml(product)}</td>
                                            })
                                        }
                                    </tr>
                                    <tr className="specifications">
                                        <td style={{fontWeight: 'bold'}}>{t('Specifications')}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                        <td>{t('Description')}</td>
                                        <td><div dangerouslySetInnerHTML={{__html: toBeCompared[0].summary}}></div></td>
                                        <td><div dangerouslySetInnerHTML={{__html: toBeCompared[1].summary}}></div></td>
                                        <td><div dangerouslySetInnerHTML={{__html: toBeCompared[2].summary}}></div></td>
                                    </tr>
                                    <tr>
                                        <td>{t('Features')}</td>
                                        <td><div dangerouslySetInnerHTML={{__html: toBeCompared[0].features}}></div></td>
                                        <td><div dangerouslySetInnerHTML={{__html: toBeCompared[1].features}}></div></td>
                                        <td><div dangerouslySetInnerHTML={{__html: toBeCompared[2].features}}></div></td>
                                    </tr>
                                    <tr>
                                        <td>{t('Product Use')}</td>
                                        <td>{lang ==='en' ? toBeCompared[0].types?.map(c => c.name_en).join(', '): toBeCompared[0].types?.map(c => c.name_ar).join(', ')}</td>
                                        <td>{lang ==='en' ? toBeCompared[1].types?.map(c => c.name_en).join(', '): toBeCompared[1].types?.map(c => c.name_ar).join(', ')}</td>
                                        <td>{lang ==='en' ? toBeCompared[2].types?.map(c => c.name_en).join(', '): toBeCompared[2].types?.map(c => c.name_ar).join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('Product Type')}</td>
                                        <td>{lang ==='en' ? toBeCompared[0].category?.name_en: toBeCompared[0].category?.name_ar}</td>
                                        <td>{lang ==='en' ? toBeCompared[1].category?.name_en: toBeCompared[1].category?.name_ar}</td>
                                        <td>{lang ==='en' ? toBeCompared[2].category?.name_en: toBeCompared[2].category?.name_ar}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('Color')}</td>
                                        <td>{toBeCompared[0].colors?.map(c => lang === 'en' ? c.name_en: c.name_ar).join(', ')}</td>
                                        <td>{toBeCompared[1].colors?.map(c => lang === 'en' ? c.name_en: c.name_ar).join(', ')}</td>
                                        <td>{toBeCompared[2].colors?.map(c => lang === 'en' ? c.name_en: c.name_ar).join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('Finish')}</td>
                                        <td>{lang ==='en' ? toBeCompared[0].finish?.name_en: toBeCompared[0].finish?.name_ar}</td>
                                        <td>{lang ==='en' ? toBeCompared[1].finish?.name_en: toBeCompared[1].finish?.name_ar}</td>
                                        <td>{lang ==='en' ? toBeCompared[2].finish?.name_en: toBeCompared[2].finish?.name_ar}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('Coverage')}</td>
                                        <td>{toBeCompared[0].spreading_rate_min} - {toBeCompared[0].spreading_rate_max} {t(toBeCompared[0].spreading_rate_unit)}</td>
                                        <td>{toBeCompared[1].spreading_rate_min} - {toBeCompared[1].spreading_rate_max} {t(toBeCompared[1].spreading_rate_unit)}</td>
                                        <td>{toBeCompared[2].spreading_rate_min} - {toBeCompared[2].spreading_rate_max} {t(toBeCompared[2].spreading_rate_unit)}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('Drying Time')}</td>
                                        <td>{toBeCompared[0].drying_time}</td>
                                        <td>{toBeCompared[1].drying_time}</td>
                                        <td>{toBeCompared[2].drying_time}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('Coats')}</td>
                                        <td>{toBeCompared[0].coats}</td>
                                        <td>{toBeCompared[1].coats}</td>
                                        <td>{toBeCompared[2].coats}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-center mt-2">
                                <a href="/products/list" className="back-to-button">
                                    {t('Back to Products')}
                                </a>
                            </div>

                        </section>
                    </div>
                </div >
            );
        }
    }
}

export default withTranslation("common")(Compare);
